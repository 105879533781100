.m2a__topic-teaser {
    margin-bottom: @m2aTopicTeaser__margin-bottom--xs;

    @media (min-width: @breakpoint--md) {
        margin-bottom: @m2aTopicTeaser__margin-bottom--md;
    }
}

.m2a__topic-teaser__headline {
    color: @color__brand-primary-red;
    font-weight: @font-weight__500;
}

.m2a__topic-teaser__items {
    margin-bottom: @m2aTopicTeaser__items__margin-bottom;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    //width: 99%;
    padding: 0.3rem 0;

    //max-height: 14rem;
    transition: all 0.5s ease;
    overflow-y: hidden;
    height: 14.7rem;

    //
    //&[aria-expanded="true"] {
    //    max-height: 100rem;
    //}

    @media (min-width: @breakpoint--md) {
        display: flex;
        flex-wrap: wrap;
        height: auto !important;
    }

    @media (min-width: @breakpoint--lg) {
        margin-bottom: 2rem;
    }
}

.m2a__topic-teaser__link {
    display: block;
    padding: 0 1.5rem;
    margin-bottom: @m2aTopicTeaser__link__margin-bottom--xs;

    @media (min-width: @breakpoint--md) {
        flex-basis: 33%;
        width: 33%;
        margin-bottom: @m2aTopicTeaser__link__margin-bottom--md;
    }

    @media (min-width: @breakpoint--lg) {
        flex-basis: 25%;
        width: 25%;
    }
}

.m2a__topic-teaser__item {
    height: @m2aTopicTeaser__item__height;
    padding: @m2aTopicTeaser__item__padding;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
    box-shadow: 2px 3px 7px rgba(0,0,0,16%);
    overflow-y: hidden;
    flex-basis: 98%;
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transition: all 0.2s ease;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        background-color: @color__brand-primary-red;
        transform: translateX(-101%);
        z-index: 0;
        transition: all 0.5s ease;
    }

    &:hover,
    &:focus {
        color: @color__neutral;

        .as__icon {
            color: @color__neutral;
        }

        &::before {
            transform: translateX(0%);
        }
    }

    .m2a__topic-teaser__text {
        font-size: @m2aTopicTeaser__item__font-size;
        display: inline-block;
        padding: 0 3rem 0 0;
        max-height: 4.6rem;
        overflow: hidden;
        position: relative;
        z-index: 3;
    }

    .as__icon {
        color: @color__brand-primary-red;
        width: 1.6rem;
        height: 3rem;
        flex-shrink: 0;
        position: relative;
        z-index: 3;
        transition: all 0.3s 0.2s ease;
    }
}

.m2a__topic-teaser__action__link {
    color: @color__brand-primary-red;
}

/* Color reactive variants */
.color-brand-primary-yellow {
    .m2a__topic-teaser__headline,
    .m2a__topic-teaser__item .as__icon {
        color: @m2aTopicTeaser__headline__color--yellow;
    }

    .m2a__topic-teaser__item {

        &::before {
            background-color: @m2aTopicTeaser__headline__color--yellow;
        }

        &:hover,
        &:focus {
            .as__icon {
                color: @color__neutral;
            }
        }
    }
}

.color-brand-primary-blue {
    .m2a__topic-teaser__headline,
    .m2a__topic-teaser__item .as__icon {
        color: @m2aTopicTeaser__headline__color--blue;
    }

    .m2a__topic-teaser__item {

        &::before {
            background-color: @m2aTopicTeaser__headline__color--blue;
        }

        &:hover,
        &:focus {
            .as__icon {
                color: @color__neutral;
            }
        }
    }
}

.m2a__topic-teaser__wrapper:last-child {
    .m2a__topic-teaser__items {
        margin-bottom: 0;
    }
}

.m2a__topics-teaser.as__module {
    margin-bottom: @module__margin-bottom--xs - @m2aTopicTeaser__link__margin-bottom--xs;

    @media @media__lg--min {
        margin-bottom: @module__margin-bottom--lg - @m2aTopicTeaser__link__margin-bottom--md;
    }

    @media @media__xl--min {
        margin-bottom: @module__margin-bottom--xl - @m2aTopicTeaser__link__margin-bottom--md;
    }
}

//@module__margin-bottom--xs: 10rem;
//@module__margin-bottom--sm: 10rem;
//@module__margin-bottom--md: 10rem;
//@module__margin-bottom--lg: 12rem;
//@module__margin-bottom--xl: 16rem;