.as__slider-nav__wrapper {
    margin-top: @slider-nav__wrapper__margin-top;

    @media @media__sm--min {
        margin-top: @slider-nav__wrapper__margin-top--sm;
    }

    @media @media__md--min {
        margin-top: @slider-nav__wrapper__margin-top--md;
    }

    @media @media__lg--min {
        margin-top: @slider-nav__wrapper__margin-top--lg;
    }

    @media @media__xl--min {
        margin-top: @slider-nav__wrapper__margin-top--xl;
    }
}

.as__slider-nav {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    justify-content: center;

    margin: 0;
    padding: 0;
}

.as__slider-nav__item {
    margin: 0 @slider-nav__item__margin-left-right;
    padding: @slider-nav__item__padding-top-bottom 0;        // Added 40px height for usability on mobile

    cursor: pointer;

    &::before {
        content: '';
        display: block;
        width: 2.5rem;
        height: .3rem;

        background: @slider-nav__item__background;

        transition: background @transition__duration @transition__timing-function;
    }

    &--active::before {
        background: @slider-nav__item__background--active;
    }
}

.as__slider-nav__item--hidden {
    display: none;
}
