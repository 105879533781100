.dws__faq__content {
    margin-left: -@outer-margin--xs;
    margin-right: -@outer-margin--xs;

    @media @media__sm--min {
        margin-left: -@outer-margin--sm;
        margin-right: -@outer-margin--sm;
    }

    @media @media__md--min {
        margin-left: -@outer-margin--md;
        margin-right: -@outer-margin--md;
    }

    @media @media__lg--min {
        margin-left: 0;
        margin-right: 0;
    }
}

.dws__m37-accordion__toggle:first-child + .dws__m37-accordion__item {
    border-top: 1px solid @accordion__item__border-top--first-child;

    @media @media__lg--min {
        border: 0;
    }
}

.dws__m37-accordion__item {
    @media @media__lg--min {
        display: none;
        border: 0;
    }
}

.dws__m37-accordion__toggle:checked + .dws__m37-accordion__item {
    @media @media__lg--min {
        display: block;
    }
}

.dws__m37-accordion__toggle {
    .as__accordion__toggle();
}

.dws__m37-accordion__label {
    font-family: @font-family__primary;
    font-size: 1.8rem;
    font-weight: @font-weight__700;
    line-height: 2.4rem;
    padding: 1.5rem @outer-margin--xs + 1.1rem;
    display: flex;

    @media @media__sm--min {
        padding-left: @outer-margin--sm + 1.1rem;
        padding-right: @outer-margin--sm + 1.1rem;
    }

    @media @media__md--min {
        padding-left: @outer-margin--md + 1.1rem;
        padding-right: @outer-margin--md + 1.1rem;
    }

    @media @media__lg--min {
        padding: 1.5rem;
        border-top: 1px solid @color__secondary--light;
        cursor: pointer;
    }

    &:last-child {
        border-bottom: 1px solid @color__secondary--light;
    }
}

.dws__m37-accordion__label:hover,
.dws__m37-accordion__label:focus,
.dws__m37-accordion__label--active {
    color: @color__brand-primary-red;

    .color-brand-primary-yellow & {
        color: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue & {
        color: @color__brand-primary-blue;
    }
}

.dws__m37-accordion__label--active {
    pointer-events: none;
}

.dws__m37-accordion__label--aside {
    font-family: @font-family__primary;
}

.dws__faq .as__accordion__label .as__icon {
    flex: 0 0 auto;
    
    width: 2rem;
    height: 2.1rem;
    position: static;
}

.dws__m37-accordion__toggle:checked + .as__accordion__item > .dws__m37-accordion__label {
    color: @accordion__label__color--active;

    .color-brand-primary-yellow & {
        color: @link__color--yellow-active;
    }

    .color-brand-primary-blue & {
        color: @link__color--blue-active;
    }
}

.dws__m37-accordion__toggle:checked + .as__accordion__item > .dws__m37-accordion__label .as__icon {
    transform: rotate(180deg);

    &:first-child {
        transform: none;
    }
}

.dws__m37-accordion__toggle:checked + .as__accordion__item .as__accordion__content-wrapper {
    color: @accordion__content-wrapper__color--active;
    border: @accordion__content-wrapper__border--active;
    background-color: @accordion__content-wrapper__background-color--active;
}

.dws__m37-accordion__content {
    .as__accordion__content();
    background-color: #f8f8f8;

    @media (min-width: @breakpoint--lg) {
        background-color: transparent;
        transition: none;
    }
}

.dws__m37-accordion__content .as__accordion__label.as__h4 {
    .as__h4();

    @media @media__sm--min {
        font-size: @h4__font-size--sm;
        line-height: @h4__line-height--sm;
        margin-bottom: @h4__margin-bottom--sm;
    }

    @media @media__md--min {
        font-size: @h4__font-size--md;
        line-height: @h4__line-height--md;
        margin-bottom: @h4__margin-bottom--md;
    }

    @media @media__lg--min {
        font-size: @h4__font-size--lg;
        line-height: @h4__line-height--lg;
        margin-bottom: @h4__margin-bottom--lg;
    }
}

.dws__m37-accordion__content .as__accordion__label {
    color: @color__secondary--dark;
}

.dws__m37-accordion__content .as__accordion__label .as__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.dws__m37-accordion__content .as__accordion__toggle:checked + .as__accordion__item > .as__accordion__label .as__icon {
    transform: translateY(-50%) rotate(180deg);
}

.dws__m37-accordion__content > .dws__m37-accordion__content-wrapper {
    padding-left: @outer-margin--xs  + .1rem;
    padding-right: @outer-margin--xs + .1rem;

    @media @media__sm--min {
        padding-left: @outer-margin--sm + .1rem;
        padding-right: @outer-margin--sm + .1rem;
    }

    @media @media__md--min {
        padding-left: @outer-margin--md + .1rem;
        padding-right: @outer-margin--md + .1rem;
    }
}

.dws__m37-accordion__content > .dws__m37-accordion__content-wrapper,
.dws__m37-accordion__toggle:checked + .as__accordion__item .dws__m37-accordion__content-wrapper {
    border-top: 1px solid @color__secondary--light;
}

.dws__m37-accordion__content .as__accordion__toggle:first-child + .as__accordion__item {
    border-top: 0;
}

.dws__m37-accordion__content .as__accordion__item:last-child {
    border-bottom: 0;

    @media @media__lg--min {
        border-bottom: 1px solid @color__secondary--light;
    }
}

.dws__faq__content .as__accordion__toggle:checked + .as__accordion__item .as__accordion__content {
    background-color: #f8f8f8;

    @media @media__lg--min {
        background-color: transparent;
    }
}

.dws__faq .as__rte p {
    color: @color__gray--dark;
}
