// REGION flip-card
.dws__target-group-teaser__flip-card {
    background-color: transparent;
    width: 100%;
    height: 100%;
    /* Remove this if you don't want the 3D effect */
    perspective: 1000px;
    display: block;
    outline-width: 0;

    @media @media__lg--min {
        &:hover .dws__target-group-teaser__flip-card-inner,
        &:focus .dws__target-group-teaser__flip-card-inner {
            transform: rotateY(180deg);
        }
    }
}

.dws--edit-mode .dws__m2b-target-group-teaser{
    .pimcore_block_entry {
        height: 100%;
    }

    .dws__target-group-teaser__flip-card {
        @media @media__lg--min {
            &:hover .dws__target-group-teaser__flip-card-inner,
            &:focus .dws__target-group-teaser__flip-card-inner {
                transform: none;
            }
        }
    }
}

.dws__target-group-teaser__flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.dws__target-group-teaser__flip-card-front,
.dws__target-group-teaser__flip-card-back {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: .5rem;
    box-shadow: 5px 5px 5px #00000029;

    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;

    padding-top: 6.5rem;
    padding-bottom: 3.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.dws__target-group-teaser__flip-card-front {
    background-color: @color__neutral;
    color: @color__secondary--dark;
    z-index: 1;
    position: relative;
}

.dws__target-group-teaser__flip-card-img {
    width: 6.7rem;
    height: 4.1rem;
    fill: @color__secondary--dark;
}

.dws__target-group-teaser__flip-card-heading {
    color: @color__brand-primary-red;
    font-weight: 500;
    text-align: center;

    &::after {
        content: '';
        display: block;
        margin: auto;
        width: 14.5rem;
        border-bottom: 2px solid #F4F3F2;
    }
}

.dws__target-group-teaser__flip-card-text {
    position: relative;
    min-height: 6rem;
}

.dws__target-group-teaser__flip-card-back {
    border-radius: .5rem;
    background-size: cover;
    background-image: url("/static/img/m7-newsletter.png");
    transform: rotateY(180deg);
    z-index: 2;
    position: absolute;
    top: 0;
}

.dws__target-group-teaser__flip-card-back--gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent linear-gradient(180deg, #B7354E 0%, #581421 100%) 0 0 no-repeat padding-box;
    opacity: 0.9;
    border-radius: .5rem;
}

.dws__target-group-teaser__flip-card .as__btn {
    z-index: 1;
    border-color: @color__neutral;
    background-color: @color__neutral;
    color: @color__brand-primary-red;

    &:hover,
    &:focus {
        background-color: transparent;
        color: @color__neutral;
    }
}
// END REGION
