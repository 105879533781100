.dws__m54-accordion .dws__m54-accordion__inner {
    .as__accordion__item {
        border: none!important;
    }

    .as__accordion__label {
        color: @m54accordion__label__color;
        padding: .5rem 1rem 1rem 1rem;
        box-shadow: 3px 3px 3px #00000029;
        border-radius: 5px;
        border-top: .5rem solid transparent;
        background-color: @color__neutral;

        @media @media__md--min {
            padding: 2rem 2rem 2.5rem 2rem;
        }
    }

    .as__accordion__content {
        margin-bottom: 2rem;
        background-color: @color__neutral;

        transition: max-height @accordion__duration @accordion__timing-function, box-shadow @accordion__duration @accordion__timing-function;
    }

    .as__accordion__toggle:checked + .as__accordion__item .as__accordion__content {
        box-shadow: 0 3px 6px #00000029;
        border-radius: 0 0 5px 5px;
    }

    .as__accordion__content-wrapper {
        padding: .5rem 0 0 0;
        overflow: hidden;
    }

    .as__text-small {
        margin: 0;
    }

    .as__accordion__content-item {
        padding: 2rem 1rem;

        @media @media__md--min {
            padding: 2rem;
        }
    }

    .as__accordion__content-item--head {
        color: @color__secondary--regular;
    }

    .as__accordion__action {
        color: @accordion__label__color--active;
    }
}

.dws__m54-accordion--courses .dws__m54-accordion__inner .as__accordion__label {
    padding-top: 2rem;
    border-color: @accordion__label__color--active;

    .dws__m54-accordion__info {
        color: @color__gray--dark;
    }

    .as__accordion__type {
        color: @accordion__label__color--active;
    }

    &.color-brand-primary-yellow {
        border-color: @color__brand-primary-yellow--dark;

        .as__accordion__type {
            color: @color__brand-primary-yellow--dark;
        }
    }

    &.color-brand-primary-blue {
        border-color: @color__brand-primary-blue--dark;

        .as__accordion__type {
            color: @color__brand-primary-blue--dark;
        }
    }
}

.dws__m54-accordion--courses .dws__m54-accordion__inner .as__accordion__label:hover {
    &.color-brand-primary-yellow {
        .as__accordion__reactive-item {
            color: @color__brand-primary-yellow--dark;
        }
    }

    &.color-brand-primary-blue {
        .as__accordion__reactive-item {
            color: @color__brand-primary-blue--dark;
        }
    }
}

.dws__m54-accordion--courses {
    &.color-brand-primary-yellow .dws__m54-accordion__inner {
        .as__accordion__label {
            border-color: @link__color--yellow-active;
        }

        .as__accordion__type {
            color: @link__color--yellow-active;
        }
    }

    &.color-brand-primary-blue .dws__m54-accordion__inner {
        .as__accordion__label {
            border-color: @link__color--blue-active;
        }

        .as__accordion__type {
            color: @link__color--blue-active;
        }
    }
}
