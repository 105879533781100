.dws__m18-category-nav__headline {
    color: @color__secondary--dark;
}

.dws__m18-category-nav__items {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dws__m18-category-nav-item--active > .dws__m18-category-nav-item__link,
.dws__m18-category-nav-item__link:hover,
.dws__m18-category-nav-item__link:focus,
.as__accordion__toggle:hover + .as__accordion__item > .dws__m18-category-nav-item__label,
.as__accordion__toggle:focus + .as__accordion__item > .dws__m18-category-nav-item__label {
    color: @color__brand-primary-red;
}

.dws__m18-category-nav-item.dws__m18-category-nav-item--sub > .dws__m18-category-nav-item__link {
    color: @color__secondary--dark;
}

.dws__m18-category-nav-item--sub > .dws__m18-category-nav-item__link {
    display: inline-block;
    padding: 0;
}

.dws__m18-category-nav-item__label {
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: @font-weight__400;

    padding-left: 1.5rem;
}

.dws__m18-category-nav-item__label .as__icon {
    width: 1rem;
    height: .5rem;
}

.dws__m18-category-nav-item.dws__m18-category-nav-item--sub > .dws__m18-category-nav-item__link:hover,
.dws__m18-category-nav-item.dws__m18-category-nav-item--sub > .dws__m18-category-nav-item__link:focus,
.as__accordion__toggle:checked + .as__accordion__item > .dws__m18-category-nav-item__label,
.dws__m18-category-nav-item--active > .dws__m18-category-nav-item__link {
    font-weight: @font-weight__700;
}

.dws__m18-category-nav-item--sub {
    margin-bottom: 1.5rem;
}

.dws__m18-category-nav-item--sub:last-child {
    margin-bottom: 0;
}
