.dws__m84b-learnpath-statistics__group,
.dws__m84b-learnpath-statistics__item {
    margin-bottom: 5rem;
    border-bottom: 1px solid @color__secondary--light;
    padding-bottom: 5rem;

    &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
        padding-bottom: 0;
    }
}

.dws__m84b-learnpath-statistics__status-icon {
    width: 2.2rem;
    height: 2.2rem;
    flex-shrink: 0;
}
