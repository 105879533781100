.dws__tag {
    color: @color__brand-primary-red;
    font-size: @text-smaller__font-size--xs;
    display: inline-flex;
    align-items: center;
    max-height: 2.6rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    padding: 0.4rem 0.8rem;
    border: 1px solid @color__secondary--light;
    border-radius: 2px;

    &:last-child {
        margin-right: 0;
    }

    &.dws__tag--eyecatcher {
        font-weight: @font-weight__500;
        text-transform: uppercase;
    }

    &.dws__tag--highlighted {
        padding: .3rem .7rem;

        font-family: @font-family__primary;
        font-size: 1.4rem;
        font-weight: @font-weight__600;
        text-transform: uppercase;

        border: 1px solid @color__brand-primary-red;
        border-radius: .2rem;

        background-color: #89233726;
        color: @color__brand-primary-red;

        &.dws__tag-left {
            position: absolute;
            top: 1rem;
            left: 1rem;
        }

        &.dws__tag-right {
            position: absolute;
            top: 1rem;
            right: 1rem;
        }
    }

    &.dws__tag--level {
        //background-color: ;
    }

    &.dws__tag--target {
        color: @color__secondary--dark;
        background-color: @color__secondary--light;
    }

    &.dws__tag--placeholder {
        color: transparent;
        background-color: transparent;
        border: transparent;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
    }
}
