// region dropdown / select
.as__select-wrapper-inner {
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        display: inline-block;
        height: @select__arrow__height;
        width: @select__arrow__width;
        border-left: @select__arrow__border-width solid @select__arrow__border-color;
        border-bottom: @select__arrow__border-width solid @select__arrow__border-color;
        position: absolute;
        top: @select__arrow__top;
        right: @select__arrow__right;
        transform: rotate(-45deg);
        pointer-events: none;
        z-index: 1;
    }
}

.as__select {
    font-size: @select__font-size;
    line-height: @select__line-height;
    color: @select__color;
    width: @select__width;
    padding: @select__padding;
    margin-bottom: @select__margin-bottom;
    appearance: none;
    background-color: @color__neutral;
    border: @input__border-width solid @select__border-color;
    border-radius: @select__border-radius;
    .fieldState(@select__border-color--active, @select__border-color--focus);
}

.dws__select--filter {
    .as__select {
        padding-left: 3.8rem;
        padding-right: 1.9rem;
        border-left: transparent;
        border-right: transparent;
        border-radius: 0;
        margin-bottom: 0;
    }

    .as__select-wrapper-inner::before {
        right: 1rem;
    }
}

// endregion
