@shariff__icon__color: '%2358585A';
@shariff__icon-active__color: '%23892337';

.as__shariff .orientation-horizontal {
    list-style: none;
    padding: 0;
    margin: 0;

    .shariff-button {
        display: inline-block;
        margin-right: 4.5rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .fab {
        color: @color__secondary--dark;
        display: block;
        width: 2.1rem;
        height: 2.1rem;
        position: relative;

        &:hover,
        &:focus {
            color: @color__brand-primary-red;
        }

        &:before,
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &:after {
            opacity: 0;
        }

        &:hover:before {
            opacity: 0;
        }

        &:hover:after {
            opacity: 1;
        }
    }

    .fa-twitter {
        &:before {
            padding: .2rem 0;
            content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 17'><g transform='translate(-240.099 -241.203)'><path fill='@{shariff__icon__color}' d='M246.617,258.558c-2.71,0-5.205-.547-6.33-1.675l-.188-.189.051-.259c.062-.326.309-.38.621-.449a30.167,30.167,0,0,0,4.875-1.38,7.256,7.256,0,0,1-3.012-2.658c-.981-1.487-2.33-4.673-.348-9.3l.342-.8.519.7a9.8,9.8,0,0,0,7.065,3.946,5.008,5.008,0,0,1,.047-1.258,4.827,4.827,0,0,1,4.21-4.025l.055,0h.013a5.44,5.44,0,0,1,3.482,1.254l2.7-1.258.245.209c.282.24.282.24-.54,2.268-.354.872-.877,2.162-.986,2.465a17.348,17.348,0,0,1-.271,3.062,9.073,9.073,0,0,1-4.155,7.268C253.074,257.847,249.706,258.558,246.617,258.558Zm-4.734-1.8c2.821,1.3,9.51,1.053,12.568-1.114a8.132,8.132,0,0,0,3.724-6.544,17.06,17.06,0,0,0,.263-3.03A14.677,14.677,0,0,1,259.5,243.3c.074-.183.147-.362.214-.529l-1.874.873-.247-.232a4.439,4.439,0,0,0-3.035-1.21,3.826,3.826,0,0,0-3.309,3.188,4.017,4.017,0,0,0,.042,1.522l.139.625-.639-.016a10.791,10.791,0,0,1-7.892-3.7c-1.276,3.566-.419,6.067.572,7.569a5.2,5.2,0,0,0,3.489,2.527h1.208l-.855.853C246.683,255.4,244.9,256.054,241.883,256.76Z'/></g></svg>");
        }

        &:after {
            padding: .2rem 0;
            content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 17'><g transform='translate(-240.099 -241.203)'><path fill='@{shariff__icon-active__color}' d='M246.617,258.558c-2.71,0-5.205-.547-6.33-1.675l-.188-.189.051-.259c.062-.326.309-.38.621-.449a30.167,30.167,0,0,0,4.875-1.38,7.256,7.256,0,0,1-3.012-2.658c-.981-1.487-2.33-4.673-.348-9.3l.342-.8.519.7a9.8,9.8,0,0,0,7.065,3.946,5.008,5.008,0,0,1,.047-1.258,4.827,4.827,0,0,1,4.21-4.025l.055,0h.013a5.44,5.44,0,0,1,3.482,1.254l2.7-1.258.245.209c.282.24.282.24-.54,2.268-.354.872-.877,2.162-.986,2.465a17.348,17.348,0,0,1-.271,3.062,9.073,9.073,0,0,1-4.155,7.268C253.074,257.847,249.706,258.558,246.617,258.558Zm-4.734-1.8c2.821,1.3,9.51,1.053,12.568-1.114a8.132,8.132,0,0,0,3.724-6.544,17.06,17.06,0,0,0,.263-3.03A14.677,14.677,0,0,1,259.5,243.3c.074-.183.147-.362.214-.529l-1.874.873-.247-.232a4.439,4.439,0,0,0-3.035-1.21,3.826,3.826,0,0,0-3.309,3.188,4.017,4.017,0,0,0,.042,1.522l.139.625-.639-.016a10.791,10.791,0,0,1-7.892-3.7c-1.276,3.566-.419,6.067.572,7.569a5.2,5.2,0,0,0,3.489,2.527h1.208l-.855.853C246.683,255.4,244.9,256.054,241.883,256.76Z'/></g></svg>");
        }
    }

    .fa-facebook-f {
        &:before {
            padding: 0 .45rem;
            content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 21'><g transform='translate(-241.753 -244.072)'><path fill='@{shariff__icon__color}' d='M250.253,265.077h-5.167v-7.5h-3.333V252.41h3.334v-2.833c0-3.6,1.9-5.5,5.5-5.5,1.011.016,2.493,0,2.495,0l.505,0v5.171H251.42a1.272,1.272,0,0,0-.347,0,.974.974,0,0,0-.825,1.1l0,2.071H253.7l-1.032,5.167h-2.41Zm-4.167-1h3.167v-7.5h2.59l.634-3.167h-3.224v-3a1.966,1.966,0,0,1,2.238-2.161l1.1,0v-3.164c-.527,0-1.365.005-2.007,0-3.063,0-4.493,1.43-4.493,4.5v3.833h-3.334v3.167h3.333Z'/></g></svg>");
        }

        &:after {
            padding: 0 .45rem;
            content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 21'><g transform='translate(-241.753 -244.072)'><path fill='@{shariff__icon-active__color}' d='M250.253,265.077h-5.167v-7.5h-3.333V252.41h3.334v-2.833c0-3.6,1.9-5.5,5.5-5.5,1.011.016,2.493,0,2.495,0l.505,0v5.171H251.42a1.272,1.272,0,0,0-.347,0,.974.974,0,0,0-.825,1.1l0,2.071H253.7l-1.032,5.167h-2.41Zm-4.167-1h3.167v-7.5h2.59l.634-3.167h-3.224v-3a1.966,1.966,0,0,1,2.238-2.161l1.1,0v-3.164c-.527,0-1.365.005-2.007,0-3.063,0-4.493,1.43-4.493,4.5v3.833h-3.334v3.167h3.333Z'/></g></svg>");
        }
    }

    .fa-xing {
        &:before {
            padding: .1rem .1rem;
            content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 19'><g transform='translate(-288.188 -411.125)'><path fill='@{shariff__icon__color}' d='M304.592,429.875h-5.24l-.206-.287-4.079-7.167.2-.33,6.273-10.966h5.272l-6.453,11.3Zm-4.7-1h2.982l-3.615-6.471.109-.254,5.723-10.025h-2.97l-5.9,10.309Z'/><path fill='@{shariff__icon__color}' d='M293.228,424.811l-.392-.012-4.648,0,3.527-5.867-2.623-4.642h4.954l.221.3,2.41,4.323-.088.306Zm-3.273-1.007h2.716l2.925-4.866-2.084-3.647-2.708,0,2.036,3.607-.1.259Z'/></g></svg>");
        }

        &:after {
            padding: .1rem .1rem;
            content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 19'><g transform='translate(-288.188 -411.125)'><path fill='@{shariff__icon-active__color}' d='M304.592,429.875h-5.24l-.206-.287-4.079-7.167.2-.33,6.273-10.966h5.272l-6.453,11.3Zm-4.7-1h2.982l-3.615-6.471.109-.254,5.723-10.025h-2.97l-5.9,10.309Z'/><path fill='@{shariff__icon-active__color}' d='M293.228,424.811l-.392-.012-4.648,0,3.527-5.867-2.623-4.642h4.954l.221.3,2.41,4.323-.088.306Zm-3.273-1.007h2.716l2.925-4.866-2.084-3.647-2.708,0,2.036,3.607-.1.259Z'/></g></svg>");
        }
    }
}