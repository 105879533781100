.dws__m63b-detail-factsheet__fav-btn .as__btn--icon__icon {
    width: 2.6rem;
    height: 2.6rem;
}

.dws__m63b-detail-factsheet__fav-btn {
    display: flex;

    width: 4.6rem;
    height: 4.6rem;
    margin-top: -1rem;
    margin-left: -1rem;
}

.dws__m63b-detail-factsheet__rte {
    ._idFootnoteLink {
        vertical-align: super;
        font-size: 80%;
        font-weight: @font-weight__700;
        text-decoration: none;
    }

    .fett {
        font-weight: @font-weight__700;
    }

    h2:not(.dws__m63b-detail-factsheet__chapter-headline) {
        &:extend(.as__h3);
    }

    .Hinweis-Beispiel {
        border-radius: .5rem;
        background-color: #E1E1E2;
        padding: 1rem;

        @media @media__md--min {
            padding: 2rem;
        }
    }

    .Hinweis-Versalien {
        font-weight: @font-weight__700;
    }
}

.dws__m63b-detail-factsheet__table-wrapper {
    display: block;
    width: 100%;

    overflow: auto;
}

.dws__m63b-detail-factsheet__footnote {
    word-break: break-word;
}

.dws__m63b-detail-factsheet__rte [id^=footnote-],
.dws__m63b-detail-factsheet__footnote {
    scroll-margin-top: 2rem;
}
