.dws__m40-speakers-overview__headline {
    padding-bottom: @m40-speakers-overview__headline__padding-bottom;
    margin-bottom: @m40-speakers-overview__headline__margin-bottom;
    border-bottom: @m40-speakers-overview__headline__border-bottom;
}

.dws__m40-speakers-overview__card {
    box-shadow: @m40-speakers-overview__card__box-shadow;
    border-radius: @m40-speakers-overview__card__border-radius;
    margin-bottom: @m40-speakers-overview__card__margin-bottom;
    padding-top: @m40-speakers-overview__card__padding-bottom;
    background: @color__white linear-gradient(to left, @color__white 50%, @color__brand-primary-red 50%) right;
    background-size: 200%;
    transition: .2s ease-out;

    &:hover {
        background-position: left;
    }
}

.dws__m40-speakers-overview__card-wrapper {
    display: flex;
    padding: @m40-speakers-overview__card-wrapper__padding-sm;
    background-color: @color__white;

    @media @media__lg--min {
    padding: @m40-speakers-overview__card-wrapper__padding-lg;
    }
}

.dws__m40-speakers-overview__card-speaker-image {
    height: @m40-speakers-overview__card-speaker-image__height-sm;
    margin-right: @m40-speakers-overview__card-speaker-image__margin-right-sm;

    border-radius: 50%;
    border: 1px solid #eee;

    @media @media__xl--min {
        height: @m40-speakers-overview__card-speaker-image__height-xl;
        margin-right: @m40-speakers-overview__card-speaker-image__margin-right-xl;
    }
}

.dws__m40-speakers-overview__card-speaker-image--placeholder {
    border-radius: 0;
    border-color: transparent;
}

.dws__m40-speakers-overview__card-speaker-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dws__m40-speakers-overview__card-speaker-headline {
    font-size: @m40-speakers-overview__card-speaker-headline__font-size-sm;
    font-weight: bold;
    color: @color__brand-primary-red;

    @media @media__lg--min {
        font-size: @m40-speakers-overview__card-speaker-headline__font-size-lg;
    }
}

.dws__m40-speakers-overview__card-speaker-subtitle {
    font-size: @m40-speakers-overview__card-speaker-subtitle__font-size-sm;
    color: @color__secondary--dark;

    @media @media__lg--min {
        font-size: @m40-speakers-overview__card-speaker-subtitle__font-size-lg;
    }
}
