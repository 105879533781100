.dws__m65-order-detail {
    .as__link--icon {
        padding-left: 2.1rem;
    }

    .as__icon {
        width: 1.6rem;
        height: 1.6rem;
    }
}

.dws__m65-order-detail__wrapper {
    height: 100%;
    background-color: @color__neutral;
    border-radius: .5rem;
    padding-top: 2rem;
    padding-left: @outer-margin--xs;
    padding-bottom: 2.5rem;
    box-shadow: 3px 3px 6px #00000029, -3px -3px 6px #CFCFCF29;

    @media @media__md--min {
        padding-left: @outer-margin--md;
    }

    @media @media__lg--min {
        padding-left: @outer-margin--lg;
    }
}

.dws__m65-order-detail__heading {
    display: flex;
    align-items: flex-end;
    margin-bottom: 2rem;
}

.dws__m65-order-detail__content {
    word-break: break-word;
}

.dws__m65-order-detail__spacer {
    display: block;
    width: 100%;
    height: .2rem;
    background-color: @color__secondary--light;
}
