.dws__m87-flash-message__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    margin-bottom: 1rem;

    color: @color__secondary--dark;
    background-color: #e1e1e2;          // Equals to @color__secondary--regular with 25% opacity on white background

    &:last-child {
        margin-bottom: 0;
    }
}

.dws__m87-flash-message__icon-wrapper {
    display: flex;
    align-self: stretch;

    background-color: @color__secondary--regular;
}

.dws__m87-flash-message__icon {
    width: 2.5rem;
    height: 2.5rem;
    color: @color__neutral;
}

// Styles
.dws__m87-flash-message__item--alert-success {
    color: @color__success;
    background-color: #c5dbc5;          // Equals to @color__success with 25% opacity on white background
}

.dws__m87-flash-message__item--alert-success .dws__m87-flash-message__icon-wrapper {
    background-color: currentColor;
}

.dws__m87-flash-message__item--alert-warning,
.dws__m87-flash-message__item--alert-error {
    color: @color__error;
    background-color: #f8c3cd;          // Equals to @color__error with 25% opacity on white background
}

.dws__m87-flash-message__item--alert-warning .dws__m87-flash-message__icon-wrapper,
.dws__m87-flash-message__item--alert-error .dws__m87-flash-message__icon-wrapper {
    background-color: currentColor;
}
