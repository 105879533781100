.dws__law-tree__text {
    font-size: 1.8rem;
    font-weight: @font-weight__600;
    color: @color__gray--dark;
    text-decoration: none;

    &:hover, &:focus {
        color: @color__brand-primary-red;
        cursor: pointer;
    }
}

.dws__law-tree__text--normal {
    font-size: 1.6rem;
    font-weight: @font-weight__400;
    color: @color__gray--dark;
    text-decoration: none;

    &:hover, &:focus {
        color: @color__brand-primary-red;
        cursor: pointer;
    }
}

.dws__law-tree__group--hidden {
    display: none;
}