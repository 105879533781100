@import "config.less";

.as__icon__template {
    display: none;
}

.as__icon {
    display: inline-block;
    fill: currentColor;
    width: @icon__width;
    height: @icon__height;
}

.as__icon__fill {
    fill: inherit;
}

/* fix Edge 14/15 suppressing click events */
.as__icon use,
object.as__icon {
    pointer-events: none;
}

.as__icon--reverse {
    transform: scaleX(-1);
}

/* pimcore icon classes - used in admin panel  */
.pimcore__icon__date {
    background: url("/bundles/pimcoreadmin/img/flat-color-icons/calendar.svg") center center no-repeat !important;
}