.dws__progress__topline {
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: right;
    margin-bottom: 0.5rem;
}

.dws__progress__bar {
    height: 1.3rem;
    line-height: 1.8rem;
    background-color: rgba(137,35,55, 0.25);
    position: relative;
    z-index: 1;
    border-radius: 8px;
    flex: 1 1 auto;

    .color-brand-primary-yellow & {
        background-color: rgba(249,176,0, 0.25);
    }

    .color-brand-primary-blue & {
        background-color: rgba(153,207,229, 0.25);
    }

}

.dws__progress-bar__inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 100%;
    background-color: @color__brand-primary-red;
    z-index: 2;
    border-radius: 8px;

    .color-brand-primary-yellow & {
        background-color: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue & {
        background-color: @color__brand-primary-blue;
    }
}

.dws__progress-bar__text {
    color: @color__brand-primary-red;
    font-size: 1.3rem;

    .color-brand-primary-yellow & {
        color: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue & {
        color: @color__brand-primary-blue;
    }
}
