@import "config.less";
@import "mixins.less";

.as__btn {
    color: @btn__color;
    font-family: @btn__font-family;
    font-size: @btn__font-size;
    font-weight: @btn__font-weight;
    line-height: @btn__line-height;
    display: inline-block;
    min-width: @btn__min-width;
    margin-bottom: @btn__margin-bottom;
    padding: @btn__padding;
    background: @btn__background-color;
    border: @btn__border-width solid @btn__border-color;
    border-radius: @btn__border-radius;
    text-decoration: none;
    text-transform: none;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 3px 6px 0 #00000029;

    .buttonAnimation(@btn__color--active, @btn__background-color--active, @btn__border-color--active);
    transition: background-color @btn__transition-duration @btn__transition-timing-function,
    border-color @btn__transition-duration @btn__transition-timing-function;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.as__btn + .as__btn {
    margin-left: @btn__margin-left--preceeding-btn;
}

.as__btn--secondary {
    color: @btn--secondary__color;
    background-color: @btn--secondary__background-color;
    border-width: @btn--secondary__border-width;
    border-color: @btn--secondary__border-color;
    .buttonAnimation(@btn--secondary__color--active, @btn--secondary__background-color--active, @btn--secondary__border-color--active);
}

.as__btn--disabled{
    opacity: .5;
    pointer-events: none;
    background-color: @btn__background-color--disabled;
    border-color: @btn__border-color--disabled;
    cursor: default;
    .buttonAnimation(@btn__color, @btn__background-color--disabled , @btn__border-color--disabled);
}

.as__btn--secondary.as__btn--disabled{
    background-color: @btn--secondary__background-color--disabled;
    color: @btn--secondary__color--disabled;
    border-color: @btn--secondary__color--disabled;
    cursor: default;
    .buttonAnimation(@btn--secondary__color--disabled, @btn--secondary__background-color--disabled, @btn--secondary__border-color--disabled);
}

.as__btn.as__btn--reactive {
    padding: 0;
    .buttonAnimation(@btn__color, @btn__background-color, @btn__border-color);
}

.as__btn__container {
    display: flex;
    position: relative;

    margin: -@btn__border-width;
    border-radius: inherit;

    overflow: hidden;
}

.as__btn--reactive .as__btn__wrapper {
    min-width: @btn--reactive__wrapper__min-width;

    margin: -@btn__border-width 0 -@btn__border-width -@btn__border-width;
    border-top-left-radius: @btn__border-radius;
    border-bottom-left-radius: @btn__border-radius;

    background: @btn--reactive__wrapper__background-color;

    &::before {
        content: '';

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;

        width: 100%;

        background: inherit;

        transform: translateX(-100%);
        transition: 300ms ease transform;

        z-index: 1;
    }

    .color-brand-primary-yellow & {
        background: @btn--reactive__wrapper__background-color--yellow;
    }

    .color-brand-primary-blue & {
        background: @btn--reactive__wrapper__background-color--blue;
    }
}

.as__btn__container .as__btn__content {
    position: relative;
    padding: @btn__padding;

    z-index: 2;
}

.as__btn--reactive:hover .as__btn__wrapper,
.as__btn--reactive:focus .as__btn__wrapper {
    &::before {
        transform: translateX(0);
    }
}

.as__btn--icon {
    padding: @btn--icon__padding;
}

.as__btn--icon__icon {
    position: relative;

    width: @btn--icon__icon__width;
    height: @btn--icon__icon__height;

    z-index: 2;
}

.as__btn--icon .as__btn__wrapper {
    display: flex;
    align-items: center;
    flex: 0 0 auto;

    padding: @btn--icon__wrapper__padding;
}

.as__btn--icon-fullwidth .as__btn__content {
    flex: 1;
}

.dws__btn--video {
    color: @color__secondary--dark;
    border-color: @color__brand-primary-blue;
    background-color: #f8f8f8;

    box-shadow: 0px 3px 6px #00000029;

    .buttonAnimation(@color__neutral, @color__brand-primary-blue, @color__brand-primary-blue);
}

.dws__btn--video .as__btn__content {
    color: inherit;
}

.as__btn--notice {
    color: @color__secondary--dark;
    display: inline-flex;
    align-items: center;
    width: 4.2rem;
    height: 4.2rem;
    position: relative;
    background-color: @color__neutral;
    border: 2px solid @color__secondary--light;
    border-radius: .5rem;
    z-index: 0;

    .as__btn--notice__icon-inactive,
    .as__btn--notice__icon-active {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        transition: opacity @transition__duration @transition__timing-function,visibility @transition__duration @transition__timing-function;
    }

    .as__btn--notice__icon-inactive {
        opacity: 1;
    }

    .as__btn--notice__icon-active {
        opacity: 0;
        visibility: hidden;
    }

    &:hover,
    &:focus,
    &.as__btn--notice-active {
        color: @color__secondary--dark;
        background-color: @color__neutral;
        border-color: @color__secondary--light;

        .as__btn--notice__icon-active {
            color: @color__brand-primary-red;
            opacity: 1;
            visibility: visible;
        }

        .as__btn--notice__icon-inactive {
            opacity: 0;
            visibility: hidden;
        }
    }
}

.as__btn--player {
    color: @color__secondary--dark;
    min-width: 20.2rem;
    display: inline-flex;
    align-items: center;
    padding: 0.7rem 2rem 0.7rem;
    justify-content: center;
    background-color: @color__secondary--lighter;
    border: 2px solid @color__brand-primary-blue;
    border-radius: 5px;

    &:hover,
    &:focus {
        color: @color__neutral;
        background-color: @color__brand-primary-blue;
        border-color: @color__brand-primary-blue;
    }

    .as__icon {
        width: 1.5rem;
        height: 1.6rem;
        margin-right: 1rem;
    }
}

.as__btn--player-disabled {
    pointer-events: none;

    &,
    &:hover,
    &:focus {
        color: #E1E1E2;
        background-color: @color__secondary--lighter;
        border-color: #C9DDE6;
    }
}

@media (max-width: @breakpoint--sm) {
    .as__btn + .as__btn {
        margin-left: 0;
    }
}

.as__btn--no-style {
    border: 0;
    padding: 0;
    box-shadow: unset;
    background: transparent;
}
