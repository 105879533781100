.dws__m63b-list-factsheet__factsheet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: .5rem;
    box-shadow: 3px 3px 3px #00000029,-3px -3px 3px #E1E1E129;
    border-top: .5rem solid @color__brand-primary-yellow;
    padding: 2rem 2rem 2rem 1.5rem;
    height: 100%;

    .as__btn {
        border: none;
        box-shadow: none;
    }

    .as__btn--icon {
        padding: unset;
    }

    .as__btn--notice {
        border: none;
        box-shadow: none;
        width: 2.5rem;
        height: 2.5rem;
        color: @color__brand-primary-red;
    }

    .as__btn--notice-active{
        border: none;
        box-shadow: none;
    }
}

.dws__m63b-list-factsheet__factsheet-rte.as__text-small {
    p {
        .as__text-small();
    }
}

.dws__m63b-list-factsheet__factsheet-description {
    p:first-child {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    p:not(:first-child) {
        display: none;
    }
}

.dws__m63b-list-factsheet__download-btn {
    word-wrap: anywhere;

    .as__icon {
        width: 1rem;
        height: 1.7rem;
    }
}
