.dws__m43-speaker-detail-text__icon {
    width: 2.3rem;
    height: 2.3rem;
}

.dws__m43-speaker-detail-text:not(.dws__m43-speaker-detail-text--ignore-custom-rte-styles) .as__rte :first-child {
    margin-top: 0;
}

.dws__m43-speaker-detail-text:not(.dws__m43-speaker-detail-text--ignore-custom-rte-styles) .as__rte p {
    margin-bottom: 4rem;
}

.dws__m43-speaker-detail-text:not(.dws__m43-speaker-detail-text--ignore-custom-rte-styles) .as__rte ul {
    margin-top: 0;
    margin-bottom: 4rem;
}

.dws__m43-speaker-detail-text:not(.dws__m43-speaker-detail-text--ignore-custom-rte-styles) .as__rte ul li {
    margin-bottom: 1rem;
    padding-left: 2rem;

    &::before {
        position: absolute;
        top: (@list__line-height--xs - 0.5rem) / 2;
        left: 0;
        width: 0.5rem;
        height: 0.5rem;
        content: '';
        background-color: #3E3E3E;
        border-radius: 50%;

        @media @media__sm--min {
            top: (@list__line-height--sm - 0.5rem) / 2;
        }

        @media @media__md--min {
            top: (@list__line-height--md - 0.5rem) / 2;
        }

        @media @media__lg--min {
            top: (@list__line-height--lg - 0.5rem) / 2;
        }
    }
}

.dws__m43-speaker-detail-text__buttons {
    margin-bottom: -2rem;
}

.dws__m43-speaker-detail-text__button-wrapper {
    margin-bottom: 2rem;
}
