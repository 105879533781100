.dws__form-popover-wrapper {
    padding-right: @popover__icon__width + 1rem;
}

.dws__form-popover-wrapper--has-popover {
    padding-right: 0;
}

.dws__form-popover-wrapper input[type=checkbox][data-popover-trigger] + label {
    display: flex;
}


.dws__form-popover-wrapper .as__popover-wrapper {
    margin-bottom: @input__margin-bottom;
}
