// region label
.as__label {
    color: @label__color;
    font-family: @label__font-family;
    font-weight: @label__font-weight;
    font-size: @label__font-size;
    line-height: @label__line-height;
    display: inline-block;
    margin-bottom: @label__margin-bottom;

    &.as__label--highlight {
        color: @color__secondary--dark;
    }
}

// endregion