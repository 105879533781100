.dws__m1-hero__table {
    .as__table,
    thead, tbody, th, td {
        border: thin solid;
        border-collapse: collapse;
    }

    tbody tr th {
        text-align: left;
    }
}