.dws__m21__accordion {
    .as__accordion__label {
        color: @m21accordion__label__color;
    }

    &.color-brand-primary-yellow {
        color: @color__brand-primary-yellow--dark;
    }

    &.color-brand-primary-blue {
        color: @color__brand-primary-blue--dark;
    }
}

.dws__m21__accordion .as__accordion__label[aria-expanded="true"] + .as__accordion__content {
    max-height: @m21accordion__content__max-height;
}



