.dws__m20-buybox-container {
    @media @media__lg--min {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        z-index: 3;
    }
}

.dws__m20-buybox-container,
.dws__m20-buybox-row {
    // HACK: In order to allow clicks through elements without breaking flex width calc on older browsers
    @media @media__lg--min {
        height: 1px;
        overflow: visible;
    }
}

.dws__m20-buybox {
    min-height: auto;
    margin-left: -@outer-margin--xs + 1rem;
    margin-right: -@outer-margin--xs + 1rem;
    border-radius: 5px;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.16),
                -3px -3px 3px rgba(225,225,225,0.16);
    background-color: #fff;
    margin-bottom: 3rem;
    overflow-x: hidden;

    @media @media__sm--min {
        margin-left: -@outer-margin--sm + 1rem;
        margin-right: -@outer-margin--sm + 1rem;
    }

    @media @media__md--min {
        margin-left: -@outer-margin--md + 1rem;
        margin-right: -@outer-margin--md + 1rem;
    }

    @media @media__lg--min {
        margin-left: 0;
        margin-right: 0;
        min-height: 62rem;
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
}

.dws__m20-buybox__calculation-fix {
    height: 0;
    line-height: 0;
    overflow: hidden;
}

.dws__m20-buybox__head {
    padding: 0 1.6rem 1.6rem;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: 1px solid #e8e8e8;
        box-shadow: 0 1px 3px rgba(0,0,0,0.16);
    }
}

.dws__m20-buybox__head__top {
    padding: 0.3rem 1rem;
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    margin-bottom: 2rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .color-brand-primary-yellow & {
        color: @color__neutral;
        background-color: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue & {
        color: @color__neutral;
        background-color: @color__brand-primary-blue;
    }
}

.dws__m20-buybox__head__price {
    font-size: 2.4rem;
    line-height: 3.4rem;
    font-family: @font-family__secondary;
    font-weight: @font-weight__700;
    display: inline-block;

    @media (min-width: 375px) {
        font-size: 2.8rem;
    }
}

.dws__m20-buybox__head__unitprice {
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding-bottom: 0.2rem;
    display: inline-block;
}

.dws__m20-buybox__body {
    padding: 2rem 1.6rem 1.6rem;
    min-height: auto;

    @media @media__lg--min {
        min-height: 28.7rem;
    }
}

.dws__m20-buybox__form {
    .as__label {
        font-size: @text-smallest__font-size--xs;
    }

    .as__select {
        font-weight: 700;
    }

    .as__checkbox__label:before,
    .as__radio__label:before {
        border-color: @color__secondary--light;
    }

    .as__btn__content {
        @media @media__xl--min {
            min-width: 25.8rem;
        }
    }
}

.dws__m20-buybox__icon-text {
    display: flex;
    margin-bottom: 1.3rem;

    .as__icon {
        flex: 0 0 1.7rem;
        margin-right: 2rem;
    }
}

.dws__m20-buybox__avatar-text {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    img {
        display: block;
        width: 6rem;
        height: 6rem;
        margin-right: 2rem;
        flex: 0 0 6rem;
        border-radius: 100%;
    }
}

.dws__m20-buybox__footer {
    padding: 2rem 0;
    margin: 0 1.6rem;
    border-top: 1px solid rgba(198, 199, 200, 0.5);
}

.dws__m20-buybox__details {
    .as__icon {
        width: 1.7rem;
        height: 1.7rem;
    }
}

.dws__m20-buybox__social {
    color: @color__secondary--regular;
    font-size: 1.4rem;
    line-height: 2.4rem;
}

.dws__m20-buybox__social-link {
    color: @color__secondary--dark;
    display: inline-block;
    margin-right: 4.5rem;

    &:last-child {
        margin-right: 0;
    }

    .as__icon {
        width: 1.9rem;
        height: 1.9rem;
    }

    &:hover,
    &:focus {
        color: @color__brand-primary-red;
    }
}

.dws__m20-buybox__social-icons {
    padding: 1rem 1rem 0 1rem;
}

.dws__m20-buybox__separator {
    width: 100%;
    border-bottom: 1px solid @color__secondary--light;
}

.dws__m20-buybox__inner-container {
    @media @media__lg--min {
        width: 73.333%;
    }
}

/* Modal */
.dws__m20-buybox__modal {

    .as__modal__wrapper {
        padding-top: 2rem;
    }

    .dws__m20-buybox__modal-separator {
        width: 100%;
        border-bottom: 1px solid @color__gray--regular;
    }
}

.dws__m20-buybox {
    &.color-brand-primary-yellow {
        .as__select {
            &:hover ,
            &:focus {
                border-color: @color__brand-primary-yellow;
            }
        }
    }

    &.color-brand-primary-blue {
        .as__select {
            &:hover ,
            &:focus {
                border-color: @color__brand-primary-blue;
            }
        }
    }

    .as__btn--reactive .as__btn__wrapper {
        background-color: @color__brand-primary-red--dark;
    }
}
