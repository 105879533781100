.as__icon.as__icon__formularpilot {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 25px;
}

.dws__account-docassemble__interview {
   .as__loading {
       background: transparent;
   }
}
.dws__account-docassemble__session-search-form,
.dws__account-docassemble__interview-search-form {
    display: flex;
    margin-bottom: 2rem;

    border-color: @color__secondary--light;
    border-radius: 5px;
    background: @color__neutral;

    box-shadow: 3px 3px 6px #85858529;
    overflow: hidden;
}

.dws__account-docassemble__session {
    .as__icon.as__icon__trash {
        width: 1.6rem;
        height: 2rem;
    }
    .as__icon.as__icon__pen {
        width: 2rem;
        height: 2.4rem;
    }
}

.dws__account-docassemble__session-sort-wrapper {
    flex: 1 0 100%;

    @media @media__lg--min {
        flex: 0 0 auto;
    }
}

.dws__account-docassemble__session-sort-wrapper {
    margin-left: -@outer-margin--sm;
    margin-right: -@outer-margin--sm;
    padding-left: @outer-margin--sm;
    padding-right: @outer-margin--sm;

    @media @media__md--min {
        margin-left: -@outer-margin--md;
        margin-right: -@outer-margin--md;
        padding-left: @outer-margin--md;
        padding-right: @outer-margin--md;
    }

    @media @media__lg--min {
        margin-left: 0;
        margin-right: 0;
        padding: 0;
        background-color: transparent;
        flex-basis: 23rem;
        order: 1;
    }

    .dws__select--filter__icon {
        width: 1.8rem;
        height: 1.8rem;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);
    }
}

.dws__m17-filter__form .dws__account-docassemble__filter {
    @media @media__lg--min {
        position: absolute;
        right: 0;
        bottom: -7rem;
    }
}

.dws__docassemble__interview__iframe {
    width: 100%;
    margin-top:121px;
    height: calc(100vh - 130px);
    border: none;

    @media @media__lg--min {
        margin-top:91px;
        height: calc(100vh - 100px);
    }
}

.dws__m35-forwarding-tile-account__item-link {
    display: flex;
    flex-direction: column;
    color: @color__white;
    background-color: @color__brand-primary-red;

    & .as__icon {
        width: 6.7rem;
        height: 4.1rem;
    }
}

.dws__account-docassemble.as__top-button__container {
    .dws__top-button__icon--rotate-0 {
        transform: rotate(0deg);
    }
}

.dws__docassemble-link-icon--clock {
    position: relative;
    top: .2rem;
    right: .2rem;
    width: 1.5rem;
    height: 1.5rem;
}

.dws__account-docassemble .dws__m63a-favourite-list-factsheet {
    &::before {
        margin-bottom: 0;
    }
}

.dws__account-docassemble .dws__m63a-favourite-list-factsheet.as__background__position-bottom {
    &::before {
        @media @media__lg--min {
            background-position: bottom;
        }
    }
}

.dws__account-docassemble .dws__product-carousel-tile__slide-bottom {
    flex: 0;
}

.as__rotate {
    padding-top: 6rem;

    &::before {
        transform: rotate(180deg);
    }
}

.dws__account-docassemble__interview {
    .as__accordion__toggle:first-child + .as__accordion__item,
    .as__accordion__toggle:first-child:checked + .as__accordion__item {
        border-top: none;
    }

    .as__accordion__item {
        border: none;
        margin-bottom: 2rem;
    }

    .as__accordion__label {
        background-color: @color__white;
        border-top-right-radius: 9999px;
        border-bottom-right-radius: 9999px;
        box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.16);

        .as__icon {
            top: auto;
            right: 3rem;
        }
    }

    .as__accordion__content-wrapper {
        margin-top: 2rem;
        padding-bottom: 2.5rem;
    }

    .dws__m21__accordion.has-expired-accordion {
        .as__accordion__toggle:checked + .as__accordion__item:last-child {
            .as__accordion__label {
                color: @color__brand-primary-red !important;
            }
        }

        .as__accordion__item:last-child {
            .as__accordion__label {
                background-color: @color__gray--light;
                color: @color__neutral;
            }
        }
    }
}

.dws__account-docassemble__session-content {
    display: flex;
    min-height: 15.3rem;
    flex-direction: column;
    justify-content: space-between;
    margin-top: -10rem;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
}

.dws__account-docassemble__topic-icon {
    width: 6.7rem;
    height: 4.1rem;
}

.dws__account-docassemble-interview__slider {
    &:extend(.dws__m63a-favourite-list-factsheet__slider);
}

.dws__account-docassemble-interview__slider-container {
    &:extend(.dws__m63a-favourite-list-factsheet__slider-container);
}

.dws__account-docassemble-interview__slide {
    &:extend(.dws__m63a-favourite-list-factsheet__slide);
}