.fieldState(@border_color, @border_color_focus: @border_color) {
    &:focus,
    &:hover {
        outline: none;
    }

    &:not(.as__form__error-wrapper):hover {
        border-color: @border_color;

    }

    &:focus {
        border-color: @border_color_focus;
    }
}
