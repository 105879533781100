.as__top-button__container {
    position: relative;
    margin-left: @top-button__container__margin-left-right;
    margin-right: @top-button__container__margin-left-right;
}

.as__top-button {
    position: fixed;
    bottom: @top-button__bottom;
    right: @top-button__right;

    display: block;
    width: @top-button__width;
    height: @top-button__height;

    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    cursor: pointer;

    transition: opacity @top-button__transition-duration @top-button__transition-timing-function,
                visibility @top-button__transition-duration @top-button__transition-timing-function,
                background @top-button__transition-duration @top-button__transition-timing-function;

    color: @top-button__color;
    background: @top-button__background;

    border-radius: @top-button__border-radius;

    overflow: hidden;

    z-index: @top-button__z-index;

    &:hover,
    &:focus {
        background: @top-button__background--hover;
    }
}

.as__top-button--visible {
    opacity: 1;
    visibility: visible;

    pointer-events: auto;
}

.as__top-button--frozen {
    position: absolute;

    && {
        bottom: 0;
        right: 0;
    }
}

@media @media__sm--min {
    .as__top-button__container {
        margin-left: @top-button__container__margin-left-right--sm;
        margin-right: @top-button__container__margin-left-right--sm;
    }

    .as__top-button {
        bottom: @top-button__bottom--sm;
        right: @top-button__right--sm;
    }
}

@media @media__md--min {
    .as__top-button__container {
        margin-left: @top-button__container__margin-left-right--md;
        margin-right: @top-button__container__margin-left-right--md;
    }

    .as__top-button {
        bottom: @top-button__bottom--md;
        right: @top-button__right--md;
    }
}

@media @media__lg--min {
    .as__top-button__container {
        margin-left: @top-button__container__margin-left-right--lg;
        margin-right: @top-button__container__margin-left-right--lg;
    }

    .as__top-button {
        bottom: @top-button__bottom--lg;
        right: @top-button__right--lg;
    }
}

@media @media__xl--min {
    .as__top-button__container {
        margin-left: @top-button__container__margin-left-right--xl;
        margin-right: @top-button__container__margin-left-right--xl;
    }

    .as__top-button {
        bottom: @top-button__bottom--xl;
        right: @top-button__right--xl;
    }
}

// region no js
.as__no-js .as__top-button {
    .as__top-button--visible();
}
// endregion no js

.dws__top-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dws__top-button__icon {
    width: 2rem;
    height: 2rem;

    transform: rotate(180deg);
}
