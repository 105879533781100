.dws__m24-search {
    margin-top: 3rem;

    @media @media__lg--min {
        margin-top: 7rem;
    }
}

.dws__m24-search__form {
    display: flex;
    align-items: center;
    position: relative;

    button[type="submit"] {
        appearance: none;
        -webkit-appearance: none;
        background-color: transparent;
        border: none;
    }
}

.dws__m24-search__text {
    color: @color__secondary--darker;
    font-size: 1.8rem;
    font-family: @font-family__secondary;
    font-weight: @font-weight__600;
    line-height: 2.6rem;
    padding: 1.1rem 6.2rem 1.1rem 2.2rem;
    box-shadow: 3px 3px 6px rgba(133,133,133,0.16);

    &:focus {
        .color-brand-primary-blue & {
            border-color: @color__brand-primary-blue;
        }

        .color-brand-primary-yellow & {
            border-color: @color__brand-primary-yellow;
        }
    }

    &::placeholder {
        color: @color__secondary--dark;
        font-weight: @font-weight__400;

        @media @media__lg--min {
            color: @color__gray--darker;
        }
    }

    &:-ms-input-placeholder { /* IE 10+ */
        color: @color__secondary--dark;
        font-weight: @font-weight__400;

        @media @media__lg--min {
            color: @color__gray--darker;
        }
    }
}

.dws__m24-search__submit-btn {
    position: absolute;
    right: 1.4rem;
    top: 1.2rem;
    z-index: 1;
    cursor: pointer;

    .dws__m24-search__submit__icon-thick {
        display: none;
    }

    .as__icon {
        color: @color__brand-primary-red;
        width: 2.5rem;
        height: 2.5rem;
    }

    &:hover,
    &:focus {
        .dws__m24-search__submit__icon-thick {
            display: inline-block;
        }

        .dws__m24-search__submit__icon-slim {
            display: none;
        }
    }
}

.dws__m24-search__header {
    margin-bottom: 0.5rem;

    @media @media__lg--min {
        margin-bottom: 7rem;
    }
}

.dws__m24-search__results {
    position: relative;
    overflow: hidden;

    .dws__filter-tabs {
        margin-bottom: 1.5rem;

        @media @media__lg--min {
            margin-bottom: 0;
            position: relative;
        }
    }

    .dws__filter-tab__content-inner {
        margin-top: 11.5rem;
    }

    .dws__m24-search__filter-wrapper {
        width: 100%;
        position: absolute;
        top: 6.2rem;
        left: 0;
        right: 0;
    }

    .dws__m15__product-list {
        margin-top: -3.7rem;

        @media @media__lg--min {
            margin-top: 0;
        }
    }
}

.dws__m24-search__no-results {
    .as__icon {
        width: 9.2rem;
        height: 9.2rem;
    }
}

/* Overwrite values */
.dws__m24-search {

    .dws__filter-tab__content {
        @media @media__lg--min {
            top: 100%;
        }
    }

    .dws__filter-tabs .dws__tab {
        @media @media__xl--min {
            flex: 1 1 auto;
            min-width: 21.3rem;
        }

        @media @media__xl--min {
            min-width: 20.3rem;
        }
    }
}
