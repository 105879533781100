.dws__video {
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        padding-bottom: 56.25%;
        display: block;
    }
}

.dws__video__media-container, .dws__video__media-container iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    z-index: 1;
}

.dws__video__media-container img {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
}

.dws__video__text-container {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.dws__video__preview-playBtn {
    color: @color__brand-primary-red;

    appearance: none;
    border: none;
    background-color: transparent;
    cursor: pointer;

    transition: @btn__transition-duration @btn__transition-timing-function color;

    .as__icon {
        width: 5.8rem;
        height: 5.8rem;

        &.dws__video__preview-playBtn--hover {
            display: none;
        }

        @media @media__lg--min {
            width: 13rem;
            height: 13rem;

            &.dws__video__preview-playBtn--hover {
                display: none;
            }

            .as__col-4 &,
            .as__col-md-4 &,
            .as__col-lg-4 &,
            .as__col-xl-4 & {
                width: 8rem;
                height: 8rem;
            }
        }
    }

    .color-brand-primary-blue & {
        color: @color__brand-primary-blue;
    }

    .color-brand-primary-yellow & {
        color: @color__brand-primary-yellow;
    }

    &:hover,
    &:focus {
        .as__icon:not(.dws__video__preview-playBtn--hover) {
            display: none;
        }

        .dws__video__preview-playBtn--hover {
            display: initial;
        }
    }
}

.dws__video .dws__btn--video {
    position: absolute;
    bottom: 3rem;
    z-index: 1;

    @media @media__lg--min {
        bottom: 5rem;
    }
}
