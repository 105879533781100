@import "fontface.less";
@import "mixin.less";
@import "mixins/imageContainer.less";
@import "util/util.less";

//region base
html {
    font-size: 62.5%; /* Sets up the Base 10 relative to the browser font size (default 16px).*/
    box-sizing: border-box;
    scroll-behavior: smooth;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

a {
    color: inherit;
    text-decoration: none;
}

.as__sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

hr {
    border-top: .1rem solid #A6ADB4;
}
//endregion

//region modules
.as__module {
    margin-bottom: @module__margin-bottom--xs;
}

@media @media__sm--min {
    .as__module {
        margin-bottom: @module__margin-bottom--sm;
    }
}

@media @media__md--min {
    .as__module {
        margin-bottom: @module__margin-bottom--md;
    }
}

@media @media__lg--min {
    .as__module {
        margin-bottom: @module__margin-bottom--lg;
    }
}

@media @media__xl--min {
    .as__module {
        margin-bottom: @module__margin-bottom--xl;
    }
}
//endregion

.as__bg-white {
    background: @color__neutral;
}

.as__bg-grey--lighter {
    background: @color__gray--lighter;
}

@media @media__lg--min {
    .as__bg-grey-lg-min--lighter {
        background: @color__gray--lighter;
    }
}

.as__bg-greige {
    background: @color__background__gray-light;
}

@import "border/border.less";
@import "grid/grid.less";
@import "text/text.less";
@import "headline/headline.less";
@import "icon/icon.less";
@import "link/link.less";
@import "list/list.less";
@import "button/button.less";
@import "form/form.less";
@import "image/image.less";
@import "table/table.less";
