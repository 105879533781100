.dws__m35-forwarding-tile {
    background-color: @m35-forwarding-tile__background-color;
    padding-bottom: @m35-forwarding-tile__margin-bottom;

    @media @media__md--min {
        padding-bottom: @m35-forwarding-tile__padding-bottom;
    }
}

.dws__m35-forwarding-tile__item-link {
    min-height: @m35-forwarding-tile__item-link__min-heigt;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: @m35-forwarding-tile__item-link__padding;
    margin-bottom: @m35-forwarding-tile__item-link__margin-bottom;

    background-color: @color__neutral;
    border-radius: @m35-forwarding-tile__item-link__border-radius;
    box-shadow: @m35-forwarding-tile__item-link__box-shadow;

    text-decoration: none;
    text-align: center;
    cursor: pointer;
    font-size: @m35-forwarding-tile__item-link__font-size;
    color: @color__brand-primary-red;

    &:hover {
        background-color: @color__brand-primary-red;
        color: @color__neutral;
    }

    @media @media__md--min {
        height: 100%;
        margin-bottom: 0;
    }

    @media @media__lg--min {
        width: 100%;
    }
}