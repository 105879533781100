.dws__search-header {
    display: none;
    transition: opacity @transition__duration @transition__timing-function;
    margin: 0 auto;

    @media @media__lg--min {
        display: block;
        z-index: 20;
        flex-grow: 1;
        flex-basis: 0;
    }

    @media @media__xl--min {
        flex-grow: 0;
    }

    &.dws__search-header--open {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left:0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: @color__neutral;
        z-index: @zindex--overlay;

        @media @media__lg--min {
            position: static;

            display: block;
            width: auto;
            height: auto;

            background: transparent;
        }
    }

    &.dws__search-header--disabled {
        opacity: 0;
        z-index: -1;
    }
}

.dws__search-header__top {
    background-color: @color__brand-primary-red;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    padding: 1.8rem 2.6rem;
    box-shadow: 0 3px 6px rgba(83,83,83,0.16);

    .as__icon {
        color: @color__neutral;
        width: 2.4rem;
        height: 2.4rem;
    }
}

.dws__search-header__icon {
    .dws__search-header__form-search:hover &,
    .dws__search-header__form-search:focus &  {
        display: none;
    }
}

.dws__search-header__icon--hover {
    display: none;

    .dws__search-header__form-search:hover &,
    .dws__search-header__form-search:focus &  {
        display: inline-block;
    }
}

.dws__search-header__toggleBtn {
    cursor: pointer;
}

.dws__search-header__form {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2.6rem;
    align-items: center;

    @media @media__lg--min {
        flex-wrap: nowrap;
        padding: 0;
        //width: 55.3rem;
    }
}

.dws__search-header__form-label {
    display: flex;
    max-height: 4.6rem;
    height: 100%;
    align-items: center;

    font-size: 1.3rem;
    margin-right: 0.8rem;

    @media @media__lg--min {
        margin-right: 0;
    }
}

.dws__search-header__form-text {
    position: relative;
    order: 2;

    flex-basis: 100%;
    width: 100%;

    @media @media__lg--min {
        order: 0;
        flex-grow: 1;
    }

    @media @media__xl--min {  ;
        flex-basis: 39.2rem;
        width: 39.2rem;
        flex-grow: 0;
    }

    .as__input {
        height: 4.6rem;
        border-radius: 3px;
        padding-right: 4.5rem;

        &:hover,
        &:focus {
            border-color: @color__brand-primary-red;

            [data-search-header-theme="blue"] & {
                border-color: @color__brand-primary-blue;
            }

            [data-search-header-theme="yellow"] & {
                border-color: @color__brand-primary-yellow;
            }
        }
    }

    .as__icon {
        width: 2rem;
        height: 2rem;
        position: absolute;
        right: 1.5rem;
        top: 1.3rem;
    }
}

.dws__search-header[data-search-header-theme="red"] .dws__search-header__form-text {
    .as__icon {
        color: @color__brand-primary-red;

        &.dws__search-header__icon--hover {
            color: @color__brand-primary-red--dark;
        }
    }
}

.dws__search-header[data-search-header-theme="blue"] .dws__search-header__form-text {
    .as__icon {
        color: @color__brand-primary-blue;

        &.dws__search-header__icon--hover {
            color: @color__brand-primary-blue--dark;
        }
    }
}

.dws__search-header[data-search-header-theme="yellow"] .dws__search-header__form-text {
    .as__icon {
        color: @color__brand-primary-yellow;

        &.dws__search-header__icon--hover {
            color: @color__brand-primary-yellow--dark;
        }
    }
}

.dws__search-header .dws__dropdown {
    flex-basis: 28.209%;
    width: 28.209%;
    order: 1;
    flex-grow: 1;
    max-height: 4.6rem;

    @media @media__lg--min {
        flex-basis: 15.6rem;
        flex-shrink: 0;
        width: 15.6rem;
    }

    .dws__dropdown__btn {
        color: @color__brand-primary-red;

        .as__icon {
            color: @color__secondary--dark;
        }
    }
}

.dws__search-header[data-search-header-theme] .dws__dropdown__btn {
    color: @color__brand-primary-red--dark;
}

.dws__search-header[data-search-header-theme="blue"] .dws__dropdown__btn {
    color: @color__brand-primary-blue--dark;
}

.dws__search-header[data-search-header-theme="yellow"] .dws__dropdown__btn {
    color: @color__brand-primary-yellow--dark;
}

.dws__search-header[data-search-header-theme="red"] .dws__dropdown__btn,
.dws__search-header[data-search-header-theme="yellow"] .dws__dropdown__btn,
.dws__search-header[data-search-header-theme="blue"] .dws__dropdown__btn {
    .as__icon {
        color: @color__secondary--dark;
    }
}


.dws__search-header__form-search {
    color: @color__brand-primary-red;
    cursor: pointer;
}

.dws__search-header__filter {
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
    list-style: none;
}

.dws__search-header__filter-item {
    line-height: 2rem;
    padding: 1rem 0;
    cursor: pointer;

    &:hover,
    &:focus {
        color: @color__brand-primary-red--dark;
    }

    &[data-filter-item-theme="blue"] {
        &:hover,
        &:focus {
            color: @color__brand-primary-blue--dark;
        }
    }

    &[data-filter-item-theme="yellow"] {
        &:hover,
        &:focus {
            color: @color__brand-primary-yellow--dark;
        }
    }

}

.dws__search-header__filter-item:first-child {
    padding-top: 0;
}

.dws__search-header-visible .dws__header.as__header--fixed {
    z-index: @zindex--layer200 + 1;
}
