.dws__m1a__hero {

    max-width: @m1a-hero__max-width;

    @media @media__md--min {
        height: 30rem;
    }

    @media @media__lg--min {
        height: 45rem;
    }
}

.dws__m1a__hero__text,
.dws__m1a__hero__media {
    flex: 1 0 100%;
}

.dws__m1a__hero__media {
    order: 0;

    @media @media__md--min {
        height: 30rem;
        order: 1;
    }

    @media @media__lg--min {
        height: 45rem;
    }

    .dws__m1a__hero--invert & {
        order: 0;
    }
}

.dws__m1a__hero__text {
    position: relative;
    box-shadow: 3px 0px 6px rgba(0,0,0,0.16);
    z-index: 3;
    order: 1;

    @media @media__md--min {
        order: 0;
    }

    .dws__m1a__hero--invert & {
        order: 1;
        box-shadow: -3px 0px 6px rgba(0,0,0,0.16);
    }

    .dws__m1a__hero--red & {
        color: @color__neutral;
        background-color: @color__brand-primary-red;
    }

    .dws__m1a__hero--blue & {
        color: @color__neutral;
        background-color: @color__brand-primary-blue;
    }

    .dws__m1a__hero--yellow & {
        color: @color__neutral;
        background-color: @color__brand-primary-yellow;
    }
}

.dws__m1a__hero__text__content {
    padding: 2.5rem @outer-margin--xs 4rem;
    max-width: @m1a-hero__text__content__max-width;
    opacity: 0;
    animation: fadeInText 2s ease forwards;

    @media @media__sm--min {
        padding-left: @outer-margin--sm;
        padding-right: @outer-margin--sm;
    }

    @media @media__md--min {
        padding-left: @outer-margin--md;
        padding-right: @outer-margin--md;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    @media @media__lg--min {
        padding: 2.5rem @outer-margin--lg 6.6rem;
        max-width: @m1a-hero__text__content__max-width;
    }

    @media @media__xl--min {
        padding-left: @outer-margin--xl;
        padding-right: @outer-margin--xl;

        .dws__m1a__hero--invert & {
            padding-left: @outer-margin--xl + 1.55rem;
        }
    }
}

.dws__m1a__hero__heading  {
    font-family: @font-family__secondary;
    font-weight: @font-weight__700;
    font-size: 2.4rem;
    line-height: 2.8rem;
    transform: translateY(20px);
    animation: slideUpText 1.3s 0.3s ease forwards;

    @media @media__lg--min {
        font-size: 4.5rem;
        line-height: 5.3rem;
    }
}

.dws__m1a__hero__heading p {
    margin: 0;
}

.dws__m1a__hero__heading-highlight  {
    color: @color__brand-primary-blue;
    font-family: @font-family__secondary;
    font-size: 3.2rem;
    font-weight: @font-weight__700;
    line-height: 3.4rem;
    text-transform: uppercase;

    @media @media__lg--min {
        font-size: 6rem;
        line-height: 7.2rem;
    }

    .dws__m1a__hero--red &,
    .dws__m1a__hero--blue &,
    .dws__m1a__hero--yellow & {
        color: @color__neutral;
    }
}

.dws__m1a__hero__media__content {
    display: flex;
    height: 100%;

    picture {
        display: inline-flex;
        width: 100%;
    }

    img {
        object-fit: cover;
        object-position: center right;
        width: 100%;
        height: auto;
    }
}

@keyframes slideUpText {
    100% {
        transform: translateY(0);
    }
}

@keyframes fadeInText {
    100% {
        opacity: 1;
    }
}