@import "config.less";

.as__accordion__toggle {
    display: none;
}

.as__accordion__item {
    margin-bottom: @accordion__item__margin-bottom;
    border-top: @accordion__item__border-top;
    border-bottom: @accordion__item__border-bottom;
}

.as__accordion__toggle:first-child + .as__accordion__item {
    border-top: @accordion__item__border-top--first-child;
}

.as__accordion__label {
    position: relative;
    display: block;

    font-family: @accordion__label__font-family;
    font-weight: @accordion__label__font-weight;
    font-size: @accordion__label__font-size;
    line-height: @accordion__label__line-height;
    color: @accordion__label__color;

    padding: @accordion__label__padding;
    margin-bottom: 0;

    cursor: pointer;

    background-color: @accordion__label__background-color;
    transition: color @accordion__duration @accordion__timing-function;

    .as__icon {
        position: absolute;
        top: @accordion__label-icon__top;
        right: @accordion__label-icon__right;
        transform: rotate(0deg);

        width: @accordion__label-icon__width;
        height: @accordion__label-icon__height;

        fill: @accordion__label-icon__fill;

        transition: transform @accordion__duration @accordion__timing-function;
    }

    .as__accordion__reactive-item:not(.as__accordion__open-text) {
        color: @accordion__label__color;
        transition: color @accordion__duration @accordion__timing-function;
    }
}

.as__accordion__content {
    max-height: 0;

    transition: max-height @accordion__content__duration @accordion__timing-function;
    overflow: hidden;
}

.as__accordion__open-content {
    position: relative;
    display: flex;
    justify-content: end;
    align-items: center;
    transition: color @accordion__duration @accordion__timing-function;
    height: 2.2rem;

    @media @media__md--min {
        position: absolute;
        top: 50%;
        margin-top: -1.1rem;
        right: 2rem;
    }

    .as__accordion__open-text {
        color: @accordion__label__color--active;

        @media @media__md--min {
            display: none;
        }
    }

    .as__icon {
        position: relative;
        right: auto;
        top: auto;
        margin-left: 1rem;
        height: .9rem;
        width: 1.8rem;

        transition: color @accordion__duration @accordion__timing-function;

        @media @media__md--min {
            height: 1.2rem;
            width: 2.4rem;
            margin-left: 2rem;
        }
    }
}

// Used to make accordion usable without Javascript enabled
.as__no-js .as__accordion__toggle:checked + .as__accordion__item .as__accordion__content {
    max-height: none;
}

.as__accordion__content-wrapper {
    max-height: none;

    color: @accordion__content-wrapper__color;

    padding: @accordion__content-wrapper__padding;
    border: @accordion__content-wrapper__border;

    background-color: @accordion__content-wrapper__background-color;
    overflow: auto;

    // Remove margin-top and margin-bottom from first and last child elements
    & > * {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.as__accordion__toggle:first-child:checked + .as__accordion__item {
    border-top: @accordion__item__border-top--first-child-active;
}

.as__accordion__toggle + .as__accordion__item > .as__accordion__label:not(.as__accordion__label--not-reactive):hover,
.as__accordion__toggle + .as__accordion__item > .as__accordion__label:not(.as__accordion__label--not-reactive):focus,
.as__accordion__toggle:checked + .as__accordion__item > .as__accordion__label:not(.as__accordion__label--not-reactive) {
    color: @accordion__label__color--active;

    .color-brand-primary-yellow & {
        color: @link__color--yellow-active;
    }

    .color-brand-primary-blue & {
        color: @link__color--blue-active;
    }
}

.as__accordion__toggle + .as__accordion__item > .as__accordion__label:hover .as__accordion__reactive-item,
.as__accordion__toggle + .as__accordion__item > .as__accordion__label:focus .as__accordion__reactive-item,
.as__accordion__toggle:checked + .as__accordion__item > .as__accordion__label .as__accordion__reactive-item,
.as__accordion__toggle:checked + .as__accordion__item > .as__accordion__content .as__accordion__reactive-item {
    color: @accordion__label__color--active;

    .color-brand-primary-yellow & {
        color: @link__color--yellow-active;
    }

    .color-brand-primary-blue & {
        color: @link__color--blue-active;
    }
}

.as__accordion__toggle:checked + .as__accordion__item > .as__accordion__label .as__icon {
    transform: rotate(180deg);
}

.as__accordion__toggle:checked + .as__accordion__item .as__accordion__content-wrapper {
    color: @accordion__content-wrapper__color--active;
    border: @accordion__content-wrapper__border--active;
    background-color: @accordion__content-wrapper__background-color--active;
}

//for max-height variant
.as__accordion--max-height .as__accordion__content-wrapper {
    max-height: @accordion__content-wrapper__max-height;
}

//for radio variant
.as__accordion--radio {
    .as__accordion__toggle:first-child + .as__accordion__item {
        border-top: none;
    }

    .as__accordion__toggle + .as__accordion__item > .as__accordion__label:focus,
    .as__accordion__toggle + .as__accordion__item > .as__accordion__label:hover,
    .as__accordion__toggle:checked + .as__accordion__item > .as__accordion__label {
        color: @color__secondary--dark;
    }

    .as__accordion__label {
        color: @color__secondary--dark;
        padding: 3rem 2rem 3rem 3rem;
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-weight: @font-weight__700;
    }

    .as__accordion__label:before {
        content: "";
        display: inline-block;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        border: 1px solid @color__secondary--dark;
        top: 3rem;
        height: 2rem;
        width: 2rem;
        padding: .2rem;
        border-radius: 100%;
        background-clip: content-box;
    }

    .as__accordion__toggle:checked + .as__accordion__item {

        & .as__accordion__label:before {
            background-color: @color__brand-primary-red;
            border-color: @color__brand-primary-red;
        }
    }

    .as__accordion__content-wrapper {
        padding-left: 3rem;
    }
}

.as__accordion[data-enable-scrollto] .as__accordion__label {
    scroll-margin-top: 10rem;
}
