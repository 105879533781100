.as__header__nav-menu-wrapper {
    position: absolute;
    top: ~'calc(100% + @{header__bottom__height})';
    left: 0;

    display: flex;
    flex-direction: column;
    width: ~"calc(100% - @{header-nav__aisde-bar__width})";
    height: ~'calc(100vh - @{header__height})';
    text-align: left;

    background: transparent;

    transform: translateX(-100%);
    pointer-events: none;

    overflow: hidden;
    z-index: 2;

    @media @media__lg--min {
        overflow: auto;
        transform: translateX(0%);
    }

    .as__header__nav--allow-transitions & {
        transition: transform @header-nav__menu-wrapper__transition-duration @header-nav__menu-wrapper__transition-timing-function;
    }
}

.as__header__nav-icon-toggle:checked ~ .as__header__nav-menu-wrapper {
    transform: translateX(0%);
    pointer-events: auto;
}

.dws__header__nav__aside-bar {
    position: absolute;
    height: ~'calc(100vh - @{header__height})';
    background-color: fade(#3e3e3e, 80%);
    top: ~'calc(100% + @{header__bottom__height})';
    left: 0;
    width: 100%;

    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    z-index: 1;

    .as__icon {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;

        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
    }

    .as__header__nav--allow-transitions & {
        transition: opacity @header-nav__menu-wrapper__transition-duration @header-nav__menu-wrapper__transition-timing-function,
                    visibility @header-nav__menu-wrapper__transition-duration @header-nav__menu-wrapper__transition-timing-function;
    }
}

.as__header__nav-icon-toggle:checked ~ .dws__header__nav__aside-bar {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.as__header__nav-menu__items {
    padding: @header-nav__items__padding;
    overflow-x: hidden;
    position: relative;
    height: 100%;
    background-color: @color__neutral;

    z-index: 2;

    @media @media__lg--min {
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: space-between;
        overflow: visible;

        background: transparent;
    }
}

.dws__nav-menu__main,
.dws__nav-menu__aside {
    & > #as__header__nav-menu,
    & > #as__header__misc-menu {
        display: block;

        @media @media__lg--min {
            display: flex;
        }
    }
}

.as__header__nav-menu {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;

    list-style: none;

    @media @media__lg--min {

        &.as__header__nav-menu--level2,
        &.as__header__nav-menu--level3 {

            visibility: hidden;
            opacity: 0;
            transition: opacity 0.3s ease;

            input[type="checkbox"].as__header__nav-item-toggle:checked ~ & {
                visibility: visible;
                opacity: 1;
            }

        }

        &.as__header__nav-menu--level2 {
            top: ~"calc(100% + 1.5rem)";
        }

        &.as__header__nav-menu--level3 {
            height: 100.5%;
            left: 100%;
        }
    }
}

.as__header__nav-menu.as__header__nav-menu--level2,
.as__header__nav-menu.as__header__nav-menu--level3 {
    padding: @header-nav__items__padding;
    box-sizing: border-box;

    @media @media__lg--min {
        padding: @header-nav__items__padding--lg;
        box-shadow: -2px 3px 6px rgba(0,0,0,0.16);
        white-space: nowrap;

        &::before,
        &::after {
            content: '';

            display: block;
            width: 100%;
            height: 100%;

            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;

            box-shadow: 0 -10px 0 @color__neutral,
                        3px 0 6px rgba(0,0,0,0.16);
            pointer-events: none;
        }

        &::after {
            height: 0;

            top: 0;
            bottom: auto;

            box-shadow: 0 -6px 0 6px @color__neutral,
                        6px 0 0 @color__neutral,
                        -6px 0 0 @color__neutral,
                        0 3px 6px rgba(0,0,0,0.16);

            .dws__header--minified & {
                display: none;
            }
        }
    }
}
.as__header__nav-menu.as__header__nav-menu--level2 {
    @media @media__lg--min {
        border-bottom: @header-nav__close-bar__border-bottom;

        .color-brand-primary-yellow & {
            border-bottom-color: @color__brand-primary-yellow;
        }

        .color-brand-primary-blue & {
            border-bottom-color: @color__brand-primary-blue;
        }
    }
}

.as__header__nav-menu.as__header__nav-menu--level3 {
    @media @media__lg--min {
        border: none;
        height: auto;
        min-height: 0;

        box-shadow: 2px 3px 6px rgba(0,0,0,0.16);

        &::before {
            display: none;
        }
    }
}

//.as__header__nav-menu.as__row {
//    @media @media__lg--min {
//        margin-left: -1rem;
//        margin-right: -1rem;
//    }
//
//    @media @media__xl--min {
//        margin-left: -3rem;
//        margin-right: -3rem;
//    }
//}

//.as__header__nav-menu-item.as__col,
//.as__header__nav-menu-item[class*="as__col-"] {
//    @media @media__lg--min {
//        padding-left: 1rem;
//        padding-right: 1rem;
//    }
//
//    @media @media__xl--min {
//        padding-right: 3rem;
//        padding-left: 3rem;
//    }
//}

.as__header__nav-menu-item {

    @media @media__lg--min {
        height: auto;
        margin-right: 2rem;

        #as__header__nav-menu > &,
        #as__header__misc-menu > & {
            position: relative;
        }

        &:last-child {
            margin-right: 0;
        }

        &.as__header__nav-menu-item--submenu {
            margin-right: 0;

            @media @media__lg--min {
                padding: 0 2rem;

                &:hover,
                &:focus {
                    background-color: @color__brand-primary-red;
                    box-shadow: inset 0 3px 6px rgba(0,0,0,0.16);

                    & > .as__header__nav-item-link,
                    & > label .as__header__nav-item-link {
                        color: @color__neutral;
                        border-bottom-color: transparent;
                    }

                    .color-brand-primary-yellow & {
                        background-color: @color__brand-primary-yellow;
                    }

                    .color-brand-primary-blue & {
                        background-color: @color__brand-primary-blue;
                    }
                }
            }
        }
    }

    &.as__header__nav-item--highlight {
        text-transform: uppercase;
        background-color: #f8f8f8;
        text-align: right;
        margin-bottom: 2.5rem;
        margin-left: -@header-nav__item-label__padding-left;
        margin-right: -2rem;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16);

        .as__header__nav-item-link {
            padding-right: 2.5rem;
        }

        .as__header__nav-item-text {
            font-size: 2.1rem;
            position: relative;
            padding-right: 2.5rem;

            @media @media__lg--min {
                position: static;
                padding-right: 0;
            }

            .as__icon {
                width: @header-nav__item-icon__width;
                height: @header-nav__item-icon__height;
                top: -3px;
            }
        }

        @media @media__lg--min {
            margin-left: auto;
            margin-right: auto;
            position: static;
        }
    }


    @media @media__xl--min {
        margin-right: 5.5rem;
    }
}

.as__header__nav-item-label {
    color: @color__gray--dark;
    cursor: pointer;
}

.as__header__nav-item-link {
    color: @color__gray--dark;
    padding: 1.5rem 0;
    display: block;
    line-height: 1;
    border-bottom: @header-nav__link__border-bottom;

    &.as__header__nav-item-link--main {
        .as__header__nav-item-text {
            color: @color__brand-primary-red;
            font-size: 2.1rem;
            font-family: @font-family__secondary;
            font-weight: @font-weight__500;
            text-transform: uppercase;

            @media @media__lg--min {
                color: @color__secondary--dark !important;
                font-size: 2.4rem;
            }

            .color-brand-primary-yellow & {
                color: @color__brand-primary-yellow;
            }

            .color-brand-primary-blue & {
                color: @color__brand-primary-blue;
            }

            .as__icon {
                width: @header-nav__item-icon__width--small;
                height: @header-nav__item-icon__height--small;
                transform: rotate(-90deg);
                position: relative;
                top: -3px;
                display: inline-block;
            }

            .dws__nav-menu__aside & {
                color: @color__gray--dark;
                text-transform: none;

                @media @media__lg--min {
                    text-transform: uppercase;
                }

                .as__icon {
                    fill: @color__brand-primary-red;
                }
            }

            &:last-child {
                border-bottom: none;
            }
        }

        &::before {
            @media @media__lg--min {
                content: '';

                position: absolute;
                bottom: 0;
                left: -2px;
                width: 100%;

                border-bottom: 3px solid @color__brand-primary-red;

                z-index: 1;
                pointer-events: none;

                transform: translateX(-100%);
                transition: transform 300ms ease;

                .color-brand-primary-yellow & {
                    border-color: @color__brand-primary-yellow;
                }

                .color-brand-primary-blue & {
                    border-color: @color__brand-primary-blue;
                }
            }
        }


        @media @media__lg--min {
            font-size: 2.4rem;
            line-height: 3rem;
        }
    }

    @media @media__lg--min {
        display: block;
        position: relative;

        padding-bottom: 1rem;
        overflow: hidden;
        border-bottom: none;

        .as__header__nav-menu--submenu & {
            border-bottom: @header-nav__link__border-bottom;
            padding: 1.5rem 4rem 1.5rem 0;
        }

        .as__header__nav-menu--submenu .as__header__nav-menu-item--submenu:last-child & {
            border-bottom: none !important;
        }
    }
}

.as__header__nav-item-text {
    font-size: 1.8rem;
    line-height: 1.3;
    position: relative;

    &.as__header__nav-item-text--main {
        color: @color__brand-primary-red;
        font-size: 2.1rem;
        text-transform: uppercase;

        .color-brand-primary-yellow & {
            color: @color__brand-primary-yellow;
        }

        .color-brand-primary-blue & {
            color: @color__brand-primary-blue;
        }

        .dws__nav-menu__aside &,
        .dws__nav-menu__account & {
            color: @color__secondary--dark;

            .as__icon {
                fill: @color__brand-primary-red;
            }
        }
    }
}

.as__header__nav-item-link:hover,
.as__header__nav-item-link:focus,
.as__header__nav-item-label[aria-expanded="true"] .as__header__nav-item-link,
.as__header__nav-item-link.as__header__nav-item-link--active {
    &::before {
        @media @media__lg--min {
            left: 0;
            transform: translateX(0);
        }
    }
}

.as__header__nav-item-toggle {
    display: none;
}

@media @media__lg--min {
    .as__header__nav-menu-wrapper {
        height: ~'calc(100vh - @{header__height--md})';
    }
}

@media @media__lg--min {
    .as__header__nav-menu-wrapper {
        position: static;
        width: auto;
        height: auto;

        pointer-events: auto;

        background: transparent;
        overflow: visible;

        transform: none;
        transition: none;
    }

    .as__header__nav-item-text {
        padding: 0;
        border: 0;
    }
}

// Region no-js
.as__no-js .as__header__nav-item-toggle:checked ~ .as__header__nav-menu {
    max-height: none;
}
// Endregion no-js

.dws__header-nav__container {
    color: @color__neutral;
    background: @color__brand-primary-red;
    position: relative;

    @media @media__lg--min {
        color: @color__secondary--dark;
        background: transparent;
        opacity: 1;
        visibility: visible;
        transition: 300ms ease transform,
                    300ms ease opacity,
                    300ms ease visibility;
    }
}

.dws__header--minified .dws__header-nav__container {
    @media @media__lg--min {
        height: 0;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        opacity: 0;
        visibility: hidden;
        transform: translateY(-100%);
    }
}

.dws__header__nav__close-bar {
    position: absolute;
    bottom: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 3rem;

    color: @color__secondary--dark;
    font-size: 1.3rem;
    text-align: center;

    border-bottom: @header-nav__close-bar__border-bottom;
    background-color: @header-nav__close-bar__background-color;
    cursor: pointer;

    z-index: 3;
}


.as__header__nav-menu--submenu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: @color__neutral;

    min-height: 100%;

    transform: translateX(100%);
    transition: transform 0.5s ease;

    @media @media__lg--min {
        transform: none;
        bottom: auto;
        right: auto;
        overflow: visible;

        &.as__header__nav-menu--right {
            left: auto;
            right: 0;
        }
    }
}

.as__header__nav-menu > .as__header__nav-menu-item > input:checked ~ .as__header__nav-menu--submenu {
    transform: translateX(0%);

    @media @media__lg--min {
        transform: none;
    }
}

/* Items icons (arrows) */

.as__header__nav-item-icon--forward {
    position: absolute;
    right: 0;

    .as__icon {
        width: @header-nav__item-icon__width;
        height: @header-nav__item-icon__height;
        transform: rotate(-90deg);
        position: relative;
        top: -2px;

        @media @media__lg--min {
            width: @header-nav__item-icon__width--desktop;
            height: @header-nav__item-icon__height--desktop;
        }
    }
}

.as__header__nav-item-icon--back {
    .as__icon {
        width: @header-nav__item-icon__width--small;
        height: @header-nav__item-icon__height--small;
        transform: rotate(90deg);
        position: relative;
        top: -4px;
    }
}

/* Navigation levels */

.as__header__nav-menu--level2 {
    z-index: 2;

    @media @media__lg--min {
        z-index: 3;
    }

}

.as__header__nav-menu--level3 {
    z-index: 3;

    @media @media__lg--min {
        background-color: #f8f8f8;
        z-index: -1;
    }
}


.dws__nav-menu__kontakt {
    margin-bottom: 7rem;
}

.dws__nav-menu__kontakt__item {
    color: @color__brand-primary-red;
    font-size: 1.4rem;
    border-bottom: @header-nav__link__border-bottom;
}

.dws__nav-menu__kontakt__item-link {
    padding: 1.5rem 2rem 1rem;
    display: block;

    .as__icon {
        width: 1.7rem;
        height: 1.7rem;
        position: relative;
        top: 1px;
    }
}

.dws__nav-menu__account {
    border-top: @header-nav__link__border-bottom;
    border-bottom: @header-nav__link__border-bottom;
    margin-left: -@header-nav__items__padding-left;
    margin-right: -2rem;

    &.as__header__nav-menu {
        border-bottom: 0;
    }
}

.dws__nav-menu__account__link {
    color: @color__secondary--dark;
    font-size: 1.8rem;
    font-family: @font-family__secondary;
    font-weight: @font-weight__500;
    display: block;
    padding: 1.5rem 0 1.5rem 4.7rem;

    .as__icon {
        width: 1.7rem;
        height: 1.7rem;
    }
}

.dws__nav-menu__metanavi {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 1rem 0;
    margin-left: -@header-nav__metanavi__link__padding-leftRight;
    margin-right: -@header-nav__metanavi__link__padding-leftRight;
}

.dws__nav-menu__metanavi .dws__metanavigation__link {
    color: @color__secondary--dark;
    font-size: 1.3rem;
    padding: 1.5rem @header-nav__metanavi__link__padding-leftRight;

    &.dws__metanavigation__link--highlight {
        font-weight: @font-weight__500;
    }
}
