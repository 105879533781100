.dws__filter-tabs {
    position: relative;
    input[type="radio"] {
        visibility: hidden;
        height: 1px;
        width: 1px;
        position: absolute;
        left: -9999px;
    }
}

.dws__filter-tabs .dws__tab {
    flex: 0 0 auto;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);

    @media @media__lg--min {
        box-shadow: none;
        flex: 1 0 auto;
        min-width: unset;
        max-width: 32rem;
        border-width: 2px;

        &::before {
            //border-top-width: 2px!important;
            border-left-width: 2px!important;
        }

        &::after {
            //border-top-width: 2px!important;
            border-right-width: 2px!important;
        }
    }

    &:first-of-type {
        margin-left: @outer-margin--xs;

        @media @media__sm--min {
            margin-left: @outer-margin--sm;
        }

        @media @media__md--min {
            margin-left: @outer-margin--md;
        }

        @media @media__lg--min {
            margin-left: 0;
        }
    }

    &:last-of-type {
        margin-right: @outer-margin--xs;

        @media @media__sm--min {
            margin-right: @outer-margin--sm;
        }

        @media @media__md--min {
            margin-right: @outer-margin--md;
        }

        @media @media__lg--min {
            margin-right: 0;
        }
    }

    & ~ .dws__tab {
        margin-left: 1rem;

        @media @media__lg--min {
            margin-left: 0;
        }
    }
}

.dws__filter-tabs__items {
    display: flex;
    padding-bottom: 2rem;
    overflow-x: auto;
    margin-left: -@outer-margin--xs;
    margin-right: -@outer-margin--xs;
    align-items: flex-end;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    @media @media__lg--min {
        padding: 0 0.5rem;
        overflow-x: hidden;
        margin-left: 0;
        margin-right: 0;
    }

    .dws__tab {
        @media @media__lg--min {
            border-bottom: none;
        }
    }
}

.dws__filter-tab__border {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    border-top: 2px solid @color__brand-primary-red;
}

.dws__filter-tab__content-wrapper {
    margin-top: -2px;
}

.dws__filter-tab__content {
    display: none;
    padding: 2rem 0;
    width: 100%;

    //border-top: 2px solid @color__brand-primary-red;

    transition: border-top @transition__duration @transition__timing-function;

    @media @media__lg--min {
        padding: 3rem 0;
    }
}

/* Active states */
/* Tab label */
input[type="radio"].dws__tab__toggle:checked + .dws__tab {
    @media @media__lg--min {
        z-index: 2;

        &::after {
            box-shadow: 3px 0px 3px rgba(0,0,0,0.16);
        }
    }
}

/* Tab content */

input[type="radio"].dws__tab__toggle {
    &[data-tab-radio-publishing]:checked ~ .dws__filter-tab__content-wrapper {
        .dws__filter-tab__border {
            border-top-color: @color__brand-primary-yellow;
        }
    }

    &[data-tab-radio-onlinetraining]:checked ~ .dws__filter-tab__content-wrapper {
        .dws__filter-tab__border {
            border-top-color: @color__brand-primary-blue;
        }
    }

    &[data-tab-radio-topic]:checked ~ .dws__filter-tab__content-wrapper {
        .dws__filter-tab__border {
            border-top-color: @color__secondary--regular;
        }
    }
}

input[type="radio"].dws__tab__toggle {
    &[data-tab-radio-allmedia]:checked ~ .dws__filter-tab__content-wrapper > [data-tab-content-allmedia],
    &[data-tab-radio-topic]:checked ~ .dws__filter-tab__content-wrapper > [data-tab-content-topic],
    &[data-tab-radio-publishing]:checked ~ .dws__filter-tab__content-wrapper > [data-tab-content-publishing],
    &[data-tab-radio-onlinetraining]:checked ~ .dws__filter-tab__content-wrapper > [data-tab-content-onlinetraining],
    &[data-tab-radio-content]:checked ~ .dws__filter-tab__content-wrapper > [data-tab-content-content] {
        display: block;
    }
}

[data-tab-radio-allmedia]:checked ~ .dws__filter-tabs__wrapper .dws__filter-tabs__items > .dws__tab[data-tab-allmedia],
[data-tab-radio-topic]:checked ~ .dws__filter-tabs__wrapper .dws__filter-tabs__items > .dws__tab[data-tab-topic],
[data-tab-radio-publishing]:checked ~ .dws__filter-tabs__wrapper .dws__filter-tabs__items > .dws__tab[data-tab-publishing],
[data-tab-radio-onlinetraining]:checked ~ .dws__filter-tabs__wrapper .dws__filter-tabs__items > .dws__tab[data-tab-onlinetraining],
[data-tab-radio-content]:checked ~ .dws__filter-tabs__wrapper .dws__filter-tabs__items > .dws__tab[data-tab-content],
[data-tab-radio-allmedia]:checked ~ .dws__filter-tabs__items > .dws__tab[data-tab-allmedia],        // Legacy support
[data-tab-radio-topic]:checked ~ .dws__filter-tabs__items > .dws__tab[data-tab-topic],
[data-tab-radio-publishing]:checked ~ .dws__filter-tabs__items > .dws__tab[data-tab-publishing],
[data-tab-radio-onlinetraining]:checked ~ .dws__filter-tabs__items > .dws__tab[data-tab-onlinetraining],
[data-tab-radio-content]:checked ~ .dws__filter-tabs__items > .dws__tab[data-tab-content]  {
    &:extend(input[type="radio"].dws__tab__toggle:checked + .dws__tab);

    @media @media__lg--min {
        border-radius: 0;

        &::after,
        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            width: @dws__tab__beforeAfter-width;
            top: -1px;
            height: ~"calc(100% + 1px)";
            /* bottom: 0; */
            z-index: 1;
            border-top: 1px solid @color__brand-primary-red;
            background-color: white;

        }

        &::after {
            left: 100%;
            border-right: 2px solid @color__brand-primary-red;
            box-shadow: 3px 3px 3px rgba(0,0,0,0.16);
            border-top-right-radius: @dws__tab__border-radius;
        }

        &::before {
            left: -@dws__tab__beforeAfter-width;
            border-top-left-radius: @dws__tab__border-radius;
            border-left: 2px solid @color__brand-primary-red;
        }

        &.dws__tab--blue {
            color: @color__brand-primary-blue;

            &::after {
                left: 100%;
                border-right-color: @color__brand-primary-blue;
                border-top-color: @color__brand-primary-blue;
            }

            &::before {
                border-left-color: @color__brand-primary-blue;
                border-top-color: @color__brand-primary-blue;
            }
        }

        &.dws__tab--yellow {
            color: @color__brand-primary-yellow;

            &::after {
                left: 100%;
                border-right-color: @color__brand-primary-yellow;
                border-top-color: @color__brand-primary-yellow;
            }

            &::before {
                border-left-color: @color__brand-primary-yellow;
                border-top-color: @color__brand-primary-yellow;
            }
        }

        &.dws__tab--gray {
            color: @color__secondary--regular;

            &::after {
                left: 100%;
                border-right-color: @color__secondary--regular;
                border-top-color: @color__secondary--regular;
            }

            &::before {
                border-left-color: @color__secondary--regular;
                border-top-color: @color__secondary--regular;
            }
        }
    }


    &.dws__tab--blue {
        background-color: @color__brand-primary-blue;

        @media @media__lg--min {
            background-color: @color__neutral;
        }
    }

    &.dws__tab--yellow {
        background-color: @color__brand-primary-yellow;

        @media @media__lg--min {
            background-color: @color__neutral;
        }
    }

    &.dws__tab--gray {
        background-color: @color__secondary--regular;

        @media @media__lg--min {
            background-color: @color__neutral;
        }
    }
}
