.dws__pds-container {
    position: relative;
}

.dws__pds-page {
    background-image: ~'url(@{image__path}/pds__wave--red.svg)';
    background-repeat: no-repeat;
    background-position: left top;

    &.dws__pds-page--publishing {
        @media @media__lg--min {
            min-height: 90rem;
        }

        @media @media__xl--min {
            min-height: 80rem;
        }
    }

    &.dws__pds-page--online {
        @media @media__lg--min {
            min-height: 110rem;
        }

        @media @media__xl--min {
            min-height: 100rem;
        }
    }

    @media (min-width: 1920px) {
        background-size: 100%;
    }

    .color-brand-primary-yellow & {
        background-image: ~'url(@{image__path}/pds__wave--yellow.svg)';
        background-position: 40% top;

        @media @media__sm--min {
            background-position: 50% top;
        }

        @media @media__md--min {
            background-position: 80% top;
        }

        @media @media__lg--min {
            background-position: right top;
        }
    }

    .color-brand-primary-blue & {
        background-image: ~'url(@{image__path}/pds__wave--blue.svg)';
        background-position: 40% top;

        @media @media__sm--min {
            background-position: 50% top;
        }

        @media @media__md--min {
            background-position: 80% top;
        }

        @media @media__lg--min {
            background-position: right top;
        }
    }
}
