.m4__text-image__item {
    margin-bottom: 5rem;

    @media @media__lg--min {
        margin-bottom: 6rem;
    }
}

.m4__text-image__media {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    border-radius: 5px;

    img {
        width: 100%;
        height: auto;
    }
}

.m4__text-image__media iframe,
.m4__text-image__media picture,
.m4__text-image__media embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.m4__text-image__topline {
    color: @color__brand-primary-red;
    font-family:  @font-family__secondary;
    line-height: 3.2rem;
    text-transform: uppercase;

    .color-brand-primary-yellow & {
        color: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue & {
        color: @color__brand-primary-blue;
    }
}

.m4__text-image__action {
    border-bottom: 1px solid @color__brand-primary-red;

    .color-brand-primary-yellow & {
        border-bottom-color: @color__brand-secondary-orange;
    }

    .color-brand-primary-blue & {
        border-bottom-color: @color__brand-primary-blue;
    }
}

.m4__text-image__content .as__rte {
    margin: 1rem 0 2.1rem 0;

    @media @media__lg--min {
        margin: 2rem 0 1.7rem 0;
    }
}

.m4__text-image .dws__list {
    margin-bottom: 0;
    @media @media__lg--min {
        display: flex;
        flex-wrap: wrap;
    }
}

.m4__text-image .dws__list-item {
    margin-bottom: 2rem;
    padding-right: 2rem;

    @media @media__lg--min {
        flex-basis: 50%;
        width: 50%;
    }

    a:hover,
    a:focus {
        text-decoration: underline;
    }
}

.m4__text-image__item--invert {
    .m4__text-image__media-wrapper {
        @media @media__lg--min {
            order: 2;
        }
    }

    .m4__text-image__text-wrapper {
        @media @media__lg--min {
            order: 1;
        }
    }
}

// region editmode
.m4__text-image .pimcore_editable_block .dws__list.dws__list--icon {
    .pimcore_block_entry {
        flex-basis: 50%;
        width: 50%;
    }

    .dws__list-item {
        @media @media__lg--min {
            flex-basis: 100%;
            width: 100%;
        }
    }
}

.m4__text-image .pimcore_editable_video {
    height: 100%;
}
// endregion