.dws__m49-order-list-account__card {
    background-color: @color__neutral;
    box-shadow: 3px 3px 6px #00000029, -3px -3px 6px #CFCFCF29;
    border-radius: 5px;
    padding: 1rem 1rem 2rem 1rem;
}

.dws__m49-order-list-account__card-head {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
    padding: 2rem 1rem;

    @media @media__lg--min {
        padding: 1rem 2rem 2rem 2rem;
    }
}

.dws__m49-order-list-account__card-item {
    border-bottom: 1px solid @color__secondary--light;
}