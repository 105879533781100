.dws__suc6__autosuggest {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    z-index: 10;

    @media @media__lg--min {
        display: none;

        &.dws__suc6__autosuggest--active {
            display: block;
        }
    }

    @media @media__lg--min {
        position: absolute;
        height: auto;
        max-height: none;

        margin-top: 0.3rem;
        background-color: @color__neutral;
        box-shadow: 3px 3px 3px rgba(0,0,0,0.16),
            -3px -3px 3px rgba(225,225,225,0.16);
    }


    strong {
        color: @color__secondary--dark;
        font-weight: @font-weight__700;
    }

    // Preload svg background imgs
    &::before {
        content: ~'url(@{icon__path}/suc6-autosuggest-item.svg)' ~'url(@{icon__path}/suc6-autosuggest-item-active.svg)' ~'url(@{icon__path}/suc6-autosuggest-item-blue.svg)' ~'url(@{icon__path}/suc6-autosuggest-item-yellow.svg)';

        position: fixed;
        top: 0;
        left: -9999px;

        display: block;
        width: 0;
        height: 0;

        opacity: 0;
        pointer-events: none;
        overflow: hidden;
        z-index: -1;
    }
}

.dws__search-header__form {
    flex-grow: 1;
    align-items: flex-start;
    flex-direction: row;

    height: calc(100% - 7rem);

    @media @media__lg--min {
        height: auto;
    }
}

.dws__search-header__form-text {
    flex-grow: 1;
    flex-basis: auto;

    height: calc(100% - 4.6rem);

    @media @media__lg--min {
        height: auto;
    }
}

.dws__search-header__result {
    max-height: calc(100% - 5.1rem);
    height: 100%;

    @media @media__lg--min {
        max-height: none;
        height: auto;
    }
}

.dws__suc6__autosuggest__results {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 6.7rem);

    margin: 0 -2.6rem;
    padding: 1.5rem 2.6rem 1.2rem;

    overflow: auto;
    overflow-x: hidden;

    @media @media__lg--min {
        max-height: 20rem;

        margin: 0;
        padding: 0;
    }
}

.dws__suc6__autosuggest__item,
.dws__suc6__autosuggest__item-image {
    color: @color__secondary--regular;
    font-size: 1.4rem;
    line-height: 1.8rem;
    display: block;
    padding: 1.2rem 4.6rem 1.2rem 1rem;
    position: relative;

    background-image: ~'url(@{icon__path}/suc6-autosuggest-item.svg)';
    background-repeat: no-repeat;
    background-position: calc(100% - 1.5rem) center;

    .color-brand-primary-blue &,
    .dws__search-header[data-search-header-theme="blue"] & {
        background-image: ~'url(@{icon__path}/suc6-autosuggest-item-blue.svg)';
    }

    .color-brand-primary-yellow &,
    .dws__search-header[data-search-header-theme="yellow"] & {
        background-image: ~'url(@{icon__path}/suc6-autosuggest-item-yellow.svg)';
    }

    &:hover,
    &:focus {
        color: @color__neutral;
        background-color: @color__brand-primary-red;

        .color-brand-primary-blue &,
        .dws__search-header[data-search-header-theme="blue"] & {
            background-color: @color__brand-primary-blue;
        }

        .color-brand-primary-yellow &,
        .dws__search-header[data-search-header-theme="yellow"] & {
            background-color: @color__brand-primary-yellow;
        }

        &,
        .color-brand-primary-blue &,
        .dws__search-header[data-search-header-theme="blue"] &,
        .color-brand-primary-yellow &,
        .dws__search-header[data-search-header-theme="yellow"] & {
            background-image: ~'url(@{icon__path}/suc6-autosuggest-item-active.svg)';
        }

        strong {
            color: @color__neutral;
            font-weight: normal;
        }
    }
}



.dws__suc6__autosuggest__item {
    @media @media__lg--min {
        padding: 0.9rem 4.6rem 0.5rem 1rem;
    }
}

.dws__suc6__autosuggest__item-image {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .as__icon {
        width: 1.6rem;
        height: 0.8rem;
        top: 2.5rem;
        transform: translateY(-50%) rotate(-90deg);
    }
}

.dws__suc6__autosuggest__image-wrapper {
    display: inline-block;
    flex: 0 0 5rem;
    margin-right: 1rem;

    img {
        display: inline-block;
        width: 100%;
        height: auto;
        border-radius: 100%;
    }
}

.dws__suc6__autosuggest__separator {
    border-top: 1px solid @color__secondary--light;
    margin: 1.2rem 0;
}

.dws__suc6__autosuggest__no-items-found {
    padding-left: 1rem;
    padding-right: 1rem;
}

.dws__suc6__autosuggest__action {
    border-top: 1px solid @color__secondary--light;
    padding: 2rem 0;

    @media @media__lg--min {
        padding: 1rem 0;
        margin: 0 1rem;
    }

    .dws__suc6__autosuggest__action-link {
        color: @color__brand-primary-red;
        cursor: pointer;
    }

    .dws__suc6__autosuggest__action-link:hover,
    .dws__suc6__autosuggest__action-link:focus {
      color: @color__brand-primary-red--dark;
      text-decoration: underline;
    }
}
