@import "config.less";
@import "mixins.less";

@import "checkbox.less";
@import "input.less";
@import "labels.less";
@import "radio.less";
@import "select.less";
@import "textarea.less";
@import "file.less";


.as__fields-group {
    margin-bottom: @group__margin-bottom;
}

.as__form__info {
    color: @info__color;
    font-family: @info__font-family;
    font-weight: @info__font-weight;
    font-size: @info__font-size;
    line-height: @info__line-height;
    display: inline-block;
}

// region error fields definition
.as__field-error {
    border-color: @input--error__border-color;
    margin-bottom: @input--error__margin-bottom;
    .fieldState(@input--error__border-color--active);
}

.as__info-error {
    display: block;
	color: @info-error__color;
    font-family: @font-family__primary;
    font-weight: @font-weight__700;
	font-size: @info-error__font-size;
	line-height: @info-error__line-height;
	margin-bottom: @info-error__margin-bottom;
}

.as__field-error ~ .as__checkbox__label,
.as__field-error ~ .as__radio__label {
    margin-bottom: @checkbox--error__margin-bottom;

    &::before {
        border-color: @checkbox--error__label__border-color;
    }
}

.as__form__error-wrapper {
    display: none;
    color: @color__error;
    background-color: #f8c3cd;          // Equals to @color__error with 25% opacity on white background
    border-left: 1rem solid @color__error;
    padding: 1.2rem 1rem .8rem 1rem;
}

.as__form__error-wrapper.as__field-error {
    display: block;
}

.as__form__error-wrapper .as__info-error {
    font-size: 1.6rem;
    line-height: 2.1rem;
    font-weight: @font-weight__400;
    margin: 0;
}

.as__form__error-info {
  color: @info-error__color;
  position: relative;
  padding-left: 2.5rem;

  .as__icon--error {
    position: absolute;
    left: 0;
    top: 0;
  }
}

// endregion

// region valid fields definition
.as__field-valid {
    border-color: @input--valid__border-color;
    margin-bottom: @input--valid__margin-bottom;
    .fieldState(@input--valid__border-color--active);
}

.as__info-valid {
    display: block;
	color: @info-valid__color;
    font-family: @font-family__primary;
    font-weight: @font-weight__700;
	font-size: @info-valid__font-size;
	line-height: @info-valid__line-height;
	margin-bottom: @info-valid__margin-bottom;
}

.as__field-valid ~ .as__checkbox__label,
.as__field-valid ~ .as__radio__label {
    margin-bottom: @checkbox--valid__margin-bottom;

    &::before {
        border-color: @checkbox--valid__label__border-color;
    }
}

.as__form__valid-wrapper {
  display: none;
}

.as__form__valid-wrapper.as__field-valid {
  display: block;
}

.as__form__valid-info {
  color: @info-valid__color;
  position: relative;
  padding-left: 2.5rem;

  .as__icon--valid {
    position: absolute;
    left: 0;
    top: 0;
  }
}

// endregion

// region error/valid icons
.dws__input-icon-wrapper {
    position: relative;
}

.dws__input__icon {
    display: none;

    position: absolute;
    top: 0;
    bottom: @input__margin-bottom;
    right: ~'calc(@{input__padding} + @{input__border-width})';

    width: 1.6rem;
    height: 1.6rem;

    margin-top: auto;
    margin-bottom: auto;

    pointer-events: none;
    z-index: 1;
}

.as__select ~ .dws__input__icon {
    right: ~'calc(@{select__padding} + @{input__border-width} + @{select__arrow__right} + @{select__arrow__width} + @{select__padding})';
}

.as__field-error ~ .dws__input__icon--error,
.as__field-valid ~ .dws__input__icon--valid {
    display: block;
}

.dws__input__icon--error {
    fill: @info-error__color;
}

.dws__input__icon--valid {
    fill: @info-valid__color;
}
// endregion
