@import "config.less";

.as__modal {
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: @modal__overlay__background-color;
    opacity: 0;
    z-index: -1;
    pointer-events: none;

    &.as__modal--opened {
        pointer-events: initial;
        z-index: @modal--opened__z-index;
        opacity: 1;
    }

    &.as__modal--no-background {
        background: transparent;
    }
}

.as__modal__height {
    width: 100%;
    height: 100vh;
}

.as__modal__wrapper {
    cursor:default;
    width: @modal__wrapper__max-width;
    padding: @modal__wrapper__padding;
    position: relative;
    background-color: @modal__wrapper__background;
    max-height: @modal__wrapper__max-height;
    overflow-y: @modal__wrapper__overflow-y;
    top: @modal__wrapper__top;

    box-shadow: 0 .3rem .6rem #00000029;
    border: .1rem solid #707070;
    border-radius: .5rem;

    @media @media__lg--min {
        max-height: @modal__wrapper__max-height--lg;
        padding: @modal__wrapper__padding--lg;
        top: @modal__wrapper__top--lg
    }
}

.as__modal--small .as__modal__wrapper {
    width: @modal__wrapper__max-width--small;

    @media @media__lg--min {
        padding: @modal__wrapper__padding--small-lg;
    }
}

.as__modal--max-height .as__modal__wrapper {
    height: @modal__wrapper__max-height;

    @media @media__lg--min {
        height: @modal__wrapper__max-height--lg;
    }
}

.as__modal__close-btn {
    position: absolute;
    top: @modal__closeBtn__top;
    right: @modal__closeBtn__right;
    width: @modal__closeBtn__width;
    height: @modal__closeBtn__height;
    cursor: pointer;

    .as__icon {
        height: inherit;
        width:  inherit;
    }

    @media @media__lg--min {
        right: @modal__closeBtn__right--lg;

        .as__modal--small & {
            right: @modal__closeBtn__right--small-lg;
        }
    }
}
[data-enable-close="false"] .as__modal__close-btn{
    display:none;
}


[data-enable-outside-click="false"] {
    cursor:default;
}

.as__modal__headline {
    font-weight: @font-weight__600;

    &:not(.as__modal__headline--no-border):after {
        display: block;
        content: "";
        height: 0;
        border-top: .1rem solid #A6ADB4;
        margin-top: 1.5rem;
    }
}

.as__modal .as__btn {
    @media (max-width: @breakpoint--sm) {
        width: 100%;
        margin-bottom: 2.5rem;
    }
}

.as__modal .as__btn:last-child {
    @media (max-width: @breakpoint--sm) {
        margin-bottom: 0;
    }
}
