.dws__m15__product-list {

}

.dws__m15__product-list__header {
    display: flex;
    flex-direction: column;

    @media @media__lg--min {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
    }

    .as__select {
        font-size: 1.6rem;
        margin-bottom: 0;
    }
}

.dws__m15__product-list__resultInfo,
.dws__m15__product-list__filter {
    flex: 1 0 100%;

    @media @media__lg--min {
        flex: 0 0 auto;
    }
}

.dws__m15__product-list__resultInfo {
    order: 1;

    @media @media__lg--min {
        order: 0;
    }
}

.dws__m15__product-list__filter {
    margin-left: -@outer-margin--xs;
    margin-right: -@outer-margin--xs;
    padding: 2rem @outer-margin--xs;
    background-color: @color__secondary--lighter;
    order: 0;

    @media @media__sm--min {
        margin-left: -@outer-margin--sm;
        margin-right: -@outer-margin--sm;
        padding-left: @outer-margin--sm;
        padding-right: @outer-margin--sm;
    }
    @media @media__md--min {
        margin-left: -@outer-margin--md;
        margin-right: -@outer-margin--md;
        padding-left: @outer-margin--md;
        padding-right: @outer-margin--md;
    }

    @media @media__lg--min {
        margin-left: 0;
        margin-right: 0;
        padding: 0;
        background-color: transparent;
        flex-basis: 20rem;
        order: 1;
    }

    .dws__select--filter__icon {
        width: 1.8rem;
        height: 1.8rem;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);
    }
}

.dws__m17-filter__form .dws__m15__product-list__filter {
    @media @media__lg--min {
        position: absolute;
        right: 0;
        bottom: -7rem;

    }
}

