.dws__m88-factsheet-toolbar {
    position: relative;
}

.dws__m88-factsheet-toolbar__container {
    @media @media__md--min {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1;
    }
}

.dws__m88-factsheet-toolbar__items {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dws__m88-factsheet-toolbar__item-wrapper {
    display: block;
}

.dws__m88-factsheet-toolbar__item {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 7rem;
    height: 6rem;

    color: @color__brand-primary-red;
    font-size: 3rem;

    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;

    cursor: pointer;
    background: @color__neutral;
}

.dws__m88-factsheet-toolbar__item::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    box-shadow: inset 2px 2px 6px 0 #00000029;
    border-radius: inherit;

    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transition: 150ms opacity ease-in;
}

.dws__m88-factsheet-toolbar__item:hover,
.dws__m88-factsheet-toolbar__item:focus,
.dws__m88-factsheet-toolbar__item[aria-expanded=true] {
    &::before {
        opacity: 1;
    }
}

.dws__m88-factsheet-toolbar .dws__product-carousel-tile__popup-container {
    position: static;
}

.dws__m88-factsheet-toolbar .dws__product-carousel-tile__popup-wrapper {
    width: auto;
    left: @outer-margin--xs;
    right: @outer-margin--xs;

    @media @media__sm--min {
        left: @outer-margin--sm;
        right: @outer-margin--sm;
    }

    @media @media__md--min {
        left: auto;
        right: @outer-margin--md;
    }

    @media @media__lg--min {
        right: @outer-margin--lg;
    }

    @media @media__xl--min {
        right: @outer-margin--xl;
    }
}


// Textsearch
.dws__m88-factsheet-toolbar__textsearch-toggle {
    display: none;
}

.dws__m88-factsheet-toolbar__textsearch-wrapper {
    display: none;
}

.dws__m88-factsheet-toolbar__textsearch-toggle:checked + .dws__m88-factsheet-toolbar__container .dws__m88-factsheet-toolbar__item-wrapper {
    display: none !important;
}

.dws__m88-factsheet-toolbar__textsearch-toggle:checked + .dws__m88-factsheet-toolbar__container .dws__m88-factsheet-toolbar__item {
    &::before {
        opacity: 1;
    }
}

.dws__m88-factsheet-toolbar__textsearch-toggle:checked + .dws__m88-factsheet-toolbar__container .dws__m88-factsheet-toolbar__textsearch-wrapper {
    display: block !important;
}

.dws__m88-factsheet-toolbar__textsearch-input-wrapper {
    width: auto;
    padding: 1rem;
    padding-left: 2.5rem
}

.dws__m88-factsheet-toolbar__textsearch-input {
    margin: 0;
    margin-left: 1rem;

    &:not(.as__form__error-wrapper) {
        &:hover,
        &:focus {
            border-color: @color__brand-primary-red;
        }
    }
}

.dws__m88-factsheet-toolbar__textsearch-content mark {
    scroll-margin-top: 2rem;
    
    &.active {
        outline: 2px dotted #FFD625;
    }
}
