// region shopping-cart
.dws__m29-article-overview {
    background: @color__neutral;
    border-radius: 0.5rem;
    box-shadow: 3px 3px 3px #00000029,-3px -3px 3px #E1E1E129;
    margin-left: -@outer-margin--xs;
    margin-right: -@outer-margin--xs;

    @media @media__sm--min {
        margin-left: -@outer-margin--sm;
        margin-right: -@outer-margin--sm;
    }

    @media @media__md--min {
        margin-left: 0;
        margin-right: 0;
    }
}

.dws__m29-article-overview__head,
.dws__m29-article-overview__body {
    padding-right: @outer-margin--xs;
    padding-left: @outer-margin--xs;

    @media @media__sm--min {
        padding-left: @outer-margin--sm;
        padding-right: @outer-margin--sm;
    }

    @media @media__md--min {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.dws__m29-article-overview__head {
    border-bottom: 1px solid @color__secondary--light;
}

.dws__m29-article-overview__refresh-icon {
    width: 2.2rem;
    height: 1.8rem;

    @media @media__lg--min {
        width: 1.8rem;
        height: 1.5rem;
    }
}

.dws__m29-article-overview__labels {
    border-top: 1px solid @color__secondary--light;
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 1.5rem 2rem;
}
// endregion

// region cart-item
.dws__m29-article-overview__item {
    border-bottom: 3px solid @color__secondary--light;
    margin-left: -@outer-margin--xs;
    margin-right: -@outer-margin--xs;
    padding-left: @outer-margin--xs;
    padding-right: @outer-margin--xs;

    @media @media__sm--min {
        margin-left: -@outer-margin--sm;
        margin-right: -@outer-margin--sm;
        padding-left: @outer-margin--sm;
        padding-right: @outer-margin--sm;
    }

    @media @media__md--min {
        margin-left: -1rem;
        margin-right: -1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    @media @media__xl--min {
        border-bottom-width: 1px;
    }
}

.dws__m29-article-overview__item:last-child {
    border-bottom: none;
}

.dws__m29-article-overview__item-heading-category {
    color: @color__secondary--regular;

    .color-brand-primary-red {
        color: @color__brand-primary-red;
    }

    .color-brand-primary-yellow {
        color: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue {
        color: @color__brand-primary-blue;
    }
}

.dws__m29-article-overview__item-delete {
    color: @color__gray--dark;

    &:hover,
    &:focus {
        color: @color__brand-primary-red;
    }
}

.dws__m29-article-overview__item-delete-icon {
    width: 1.7rem;
    height: 1.9rem;
    fill: currentColor;
    vertical-align: middle;
}

.dws__m29-article-overview__item-heading-subtitle {
    color: #676767;
}

.dws__m29-article-overview__item-heading-description {
    color: #747474;
}

.dws__m29-article-overview__item-select {
    @media @media__xl--min {
        max-width: 7.2rem;
    }
}

.dws__m29-article-overview__item-select .as__select {
    font-weight: @font-weight__700;
    background-color: @color__neutral;
}

.dws__m29-article-overview__item-select .as__select-wrapper-inner::before {
    top: 1.6rem;
    z-index: 0;
}

.dws__m29-article-overview__item-single-price {
    font-size: 1.3rem;
    line-height: 1.2;
}

.dws__m29-article-overview__item-discount-label {
    font-size: .9rem;
}

.dws__m29-article-overview__item-total-price {
    font-family: @font-family__secondary;
    font-size: 2.1rem;
    line-height: 2.6rem;

    font-weight: @font-weight__600;
}

.dws__m29-article-overview__item-amount {
    color: @color__secondary--darker;
    line-height: 1.6rem;
}
// endregion

.dws__m29-article-overview--m29b {
    .dws__m29-article-overview__item-amount-label,
    .dws__m29-article-overview__item-total-price-label {
        font-weight: @font-weight__700;
    }

    .dws__m29-article-overview__item-single-price-label {
        font-size: 1.3rem;
    }

    .dws__m29-article-overview__item-total-price {
        color: @color__brand-primary-red;
    }
}

.dws__m29-article-overview__table-mobile {
    border: 0;
    border-spacing: 0;

    td {
        vertical-align: top;
    }
}

.dws__m29-article-overview__license-assignment {
    .dws__dropdown__btn,
    .dws__dropdown__label {
        height: 44px;

        &.error {
            border: 1px solid @input--error__border-color;
        }
    }

    .dws__m29-license-assignment__results input[type=checkbox] {
        clip-path: none;
        height: auto;
        width: auto;
        position: relative;
    }

    .as__select-wrapper {
        position: relative;
    }

    .dws__m29-license-assignment__selected-customer {
        height: 4rem;
    }

    .dws__m29-license-assignment__selected-customer,
    .dws__m29-license-assignment__results input {
        cursor: pointer;
    }

    .dws__m29-license-assignment__box {
        width: 100%;
        z-index: @zindex--layer300;

        background-color: #fff;
        display: grid;
        border: 1px solid #C6C7C8;
        border-radius: .5rem;
        padding: 1rem;

        .dws__m29-license-assignment__results {
            overflow-y: scroll;
            max-height: 200px;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    fieldset {
        border: none;
        padding: 0;
        max-height: 30rem;
        overflow: auto;
    }

    .dws__dropdown > input[type="checkbox"]:checked ~ .dws__dropdown__menu {
        z-index: 10;
    }
}

.dws__m29-article-overview__license-assignment__warning {
    margin-left: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: @dws__tab__font-size--xs;
    line-height: @dws__tab__line-height--xs;
    font-weight: @font-weight__500;
    color: @color__brand-primary-red;

    &> div {
        max-width: 200px;
    }
}

.dws__m29-article-overview__license-assignment__info {
    margin-left: 0;
    color: @color__secondary--darker;
    font-weight: @font-weight__500;
    font-size: @dws__tab__font-size--xs;
    line-height: @dws__tab__line-height--xs;
    max-width: 400px;
}

.dws__m29-license-assignment__search-wrapper {
    position: relative;

    .icon {
        position: absolute;
        right: 1.3rem;
        width: 2rem;
        top: .6rem;
        color: @color__brand-primary-red;
    }
}

.dws__m29-license-assignment__box-cancel {
    background-color: #707070;
    border-color: #707070;
    margin-left: 0 !important;

    &:hover,
    &:focus {
        background-color: #C6C7C8;
        border-color: #707070;
    }
}
