.dws__m44-quote {
    background: transparent linear-gradient(88deg, #F8F8F8 0%, #E4E2DE 100%) 0% 0% no-repeat padding-box;
}

.dws__m44-quote-wrapper {
    border-top: 1px solid @color__secondary--light;
    border-bottom: 1px solid @color__secondary--light;

    @media @media__lg--min {
        padding-left: @m44-quote__padding__leftRight--lg;
        padding-right: @m44-quote__padding__leftRight--lg;
    }
}

.dws__m44-quote-text {
    font-weight: 500;
    margin-left: 0;
    margin-right: 0;
}

.dws__m44-quote .as__link {
    text-decoration: none;
}