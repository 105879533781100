@import "config.less";

.as__link {
    color: @link__color;
    font-family: @link__font-family;
    font-weight: @link__font-weight;
    text-decoration: underline;
    cursor: pointer;

    &:hover,
    &:focus {
        color: @link__color--active;
        text-decoration: underline;
    }
}

.as__link--text-decoration-inverse {
    text-decoration: none !important;

    &:hover,
    &:focus {
        text-decoration: underline !important;
    }
}

.as__link--grey {
    color: @color__gray--regular;
    text-decoration: underline;

    &:hover,
    &:focus {
        color: @color__gray--dark;
    }
}

.as__link--secondary--dark {
    color: @color__secondary--dark;
    text-decoration: underline;

    &:hover,
    &:focus {
        color: @link__color--active;
    }
}

.as__link--secondary-regular {
    color: @color__secondary--regular;
    text-decoration: underline;

    &:hover,
    &:focus {
        color: @link__color--active;
    }
}

.as__link--inactive {
    color: @link__color--inactive!important;
    pointer-events: none;
}

.as__link--icon {
    position: relative;
    display: block;
    text-decoration: none;

    padding-left: @icon-link__padding-left + @icon-link__arrow__width;
    margin-bottom: @icon-link__margin-bottom--xs;

    &:visited {
        color: @link__color;
        text-decoration: none;
    }

    &:hover,
    &:focus {
        color: @link__color--active;
        text-decoration: underline;
    }
}

.as__link--icon.as__link--disabled {
    &:hover,
    &:focus,
    & {
        color: @color__secondary--regular;
        text-decoration: none;
        cursor: auto;
    }
}

.as__text-small {
    .as__link--icon {
        padding-left: 1.3rem;
    }
}

.as__link--icon__icon {
    position: absolute;
    top: (@text__line-height--xs - @icon-link__arrow__height) / 2;
    left: 0;
    width: @icon-link__arrow__width;
    height: @icon-link__arrow__height;
    fill: currentColor;

    @media @media__sm--min {
        top: (@text__line-height--sm - @icon-link__arrow__height) / 2;
    }

    @media @media__md--min {
        top: (@text__line-height--md - @icon-link__arrow__height) / 2;
    }

    @media @media__lg--min {
        top: (@text__line-height--lg - @icon-link__arrow__height) / 2;
    }
}

.as__link--icon-last {
    padding-left: 0;
    padding-right: @icon-link__padding-left + @icon-link__arrow__width;
}

.as__link--icon-last .as__link--icon__icon {
    left: auto;
    right: 0;
}

.as__text-small {
    .as__link--icon__icon {
        width: .5rem;
    }
}

.as__link--no-style {
    text-decoration: none;
}
