.dws__m48-tile-listing {
    margin-bottom: 10rem;

    @media @media__md--min {
        margin-bottom: 16.5rem;
    }
}

.dws__m48-tile-listing.dws__m48-tile-listing--bordered {
    margin-bottom: 10rem;
}

.dws__m48-tile-listing__item {
    background: @color__neutral;
    border-radius: 5px;
    box-shadow: 0 3px 6px #00000029;
}

.dws__m48-tile-listing__item--bordered {
    border-top: 5px solid @color__brand-primary-red;

    &.dws__m48-tile-listing__item--bordered-yellow {
        border-color: @color__brand-primary-yellow;
    }

    &.dws__m48-tile-listing__item--bordered-blue {
        border-color: @color__brand-primary-blue;
    }
}

.dws__m48-tile-listing__item-link--big-icon .as__link--icon__icon {
    width: 1rem;
    height: 1.6rem;
}
