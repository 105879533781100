//styling inside checkbox.less

.as__radio__label {
    &::before {
        top: @radio__icon__top;
        height: @radio__icon__height;
        width: @radio__icon__width;
        padding: @radio__icon__padding;
        border-radius: 100%;
        background-clip: content-box;
    }
}

.as__radio__control:checked + .as__radio__label::before {
    background-color: @radio__label__background-color--checked;
    border-color: @radio__label__border-color--checked;
}

.as__radio__control[disabled] + .as__radio__label::before {
    background-color: transparent;
}
