.as__header__nav-icon-toggle {
    display: none;
}

.as__header__nav-icon-wrapper {
    display: flex;
    align-items: center;

    width: @header-nav__icon__width;
    height: @header-nav__icon__height;

    cursor: pointer;
}

.as__header__nav-search-wrapper {
    position: absolute;
    right: 2rem;
    top: 0;
    bottom: 0;

    width: 2.4rem;
    height: 2.4rem;

    margin-top: auto;
    margin-bottom: auto;
    
    cursor: pointer;
}

.as__header__nav-icon {
    width: inherit;
    height: inherit;
}

.dws__header__shop-nav__icon-wrapper {
    position: relative;
}

.dws__header__shop-nav__badge {
    color: @color__neutral;
    font-size: 1.2rem;
    width: 2rem;
    height: 2rem;
    padding-right: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @color__brand-primary-red;
    position: absolute;
    border-radius: 15px;
    top: 5px;
    right: -4px;
    transform: translate(50%, -50%);

    @media (min-width: @breakpoint--md) {
        padding-right: 0.2rem;
        padding-bottom: 0.1rem;
    }
}

.as__header__nav-icon-home:hover,
.as__header__nav-item-link--active .as__header__nav-icon-home {
    color: @color__brand-primary-red;
}
