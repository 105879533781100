.dws__pw-toggle__toggle-wrapper {
    position: relative;
    top: -4rem;
    font-size: 1rem;
    color: @color__secondary--regular;
    text-align: right;
    pointer-events: none;
}

.dws__pw-toggle__toggle {
    pointer-events: initial;
    padding-right: 1.5rem;
    cursor: pointer;
}

.dws__pw-toggle__input[type="password"] ~ .dws__pw-toggle__toggle-wrapper .dws__pw-toggle__toggle-label--hide,
.dws__pw-toggle__input[type="text"] ~ .dws__pw-toggle__toggle-wrapper .dws__pw-toggle__toggle-label--show {
    display: none;
}

.dws__pw-toggle__input[type="password"] ~ .dws__pw-toggle__toggle-wrapper .dws__pw-toggle__toggle-label--show,
.dws__pw-toggle__input[type="text"] ~ .dws__pw-toggle__toggle-wrapper .dws__pw-toggle__toggle-label--hide {
    display: inline;
}
