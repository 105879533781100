.dws__m39-alphabet-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.dws__m39-alphabet-character {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 0.5rem;
    margin-bottom: 1rem;
    padding-top: 0.3rem;

    font-family: Barlow, sans-serif;
    font-size: 2.4rem;
    font-weight: 600;
    text-decoration: none;
    color: @color__secondary--dark;
    cursor: pointer;

    border-radius: 50%;

    &:hover,
    &:focus,
    &.dws__m39-alphabet-character--selected {
        background-color: @color__brand-primary-red;
        color: @color__neutral;
    }
}

.dws__m39-alphabet-character__inactive {
    color: @color__secondary--light;
    pointer-events: none;
}

.dws__m39-alphabet-link {
    height: 4rem;
    display: flex;
    align-items: flex-end;
    padding-bottom: 1rem;
}

.dws__m39-alphabet .as__link {
    text-decoration: none;
}