.dws__m8a-text {
    background-color: @m8a-text-modul__background-color--gray--light;

    .color-brand-primary-yellow &,
    &.color-brand-primary-yellow {
        background-color: @m8a-text-modul__background-color--yellow;
    }

    .color-brand-primary-blue &,
    &.color-brand-primary-blue {
        background-color: @m8a-text-modul__background-color--blue;
    }
}

.dws__m8a-text__wrapper {
    padding: 2.5rem;
    margin: 3rem -@outer-margin--xs;

    background-color: @color__neutral;
    box-shadow: 3px 3px 4px #0000001A;
    border: 1px solid #F2F2F2;
    border-radius: 5px;
    opacity: 0.8;

    @media @media__sm--min {
        margin-left: -@outer-margin--sm;
        margin-right: -@outer-margin--sm;
    }

    @media @media__md--min {
        margin-left: 0;
        margin-right: 0;
    }
}

.dws__m8a-text__text--col-count-3 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;

    @media @media__md--min {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }

    @media @media__lg--min {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }
}

.dws__m8a-text__text--col-count-2 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;

    @media @media__md--min {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

.dws__m8a-text__rte ul li {
    &::before {
        background-image: ~'url(@{icon__path}/list-item-arrow-red.svg)';
    }
}

.dws__m8a-text__rte ul li a {
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
