.dws__m28-info-box__card {
    background-color: #fcfcfc;
    box-shadow: 3px 3px 6px #00000029, -3px -3px 6px #CFCFCF29;
    border-radius: 5px;
    padding: 2.5rem 2.5rem 3rem 2.5rem;
    margin: 0 -@outer-margin--xs;

    @media @media__sm--min {
        margin-left: -@outer-margin--sm;
        margin-right: -@outer-margin--sm;
    }

    @media @media__md--min {
        margin: 0;
        padding: 2.5rem 2rem 3rem 2rem;
    }
}

.dws__m28-info-box__card-image-container {
    display: flex;
    justify-content: space-between;

    @media @media__sm--min {
        justify-content: flex-start;
    }

    @media @media__xl--min {
        max-width: none;
    }
}

.dws__m28-info-box__card-image-wrapper {
    margin-right: 1.5rem;

    &:last-child {
        margin-right: 0;
    }
}

.dws__m28-info-box__card-image {
    display: block;
    width: 5.5rem;
    height: 3rem;
}
