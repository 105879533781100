.dws__m27-sum-box__wrapper {
    background-color: @color__neutral;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 3px 6px #00000029, -3px -3px 6px #CFCFCF29;
    border-radius: 5px;
    padding: 2.5rem 2.5rem 3rem 2.5rem;
    margin: 0 -@outer-margin--xs;

    @media @media__sm--min {
        margin-left: -@outer-margin--sm;
        margin-right: -@outer-margin--sm;
    }

    @media @media__md--min {
        margin: 0;
    }

    @media @media__lg--min {
        padding: 2.5rem 2rem 3rem 2rem;
    }
}

.dws__m27-sum-box-sticky {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    background-color: @color__neutral;
    border-top: 3px solid @color__brand-primary-red;
    box-shadow: -3px -3px 6px #75757529;
    opacity: 1;
    visibility: visible;
    transition: 300ms opacity ease-in, 300ms visibility ease-in;
    z-index: @zindex--sum-box-sticky;
}

.dws__m27-sum-box-sticky--hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.dws__m27-sum-box__text {
    color: @color__secondary--dark;
    font-size: 1.3rem;

    &--light {
        color: @color__secondary--regular;
        font-size: 1.3rem;
    }
}

.dws__m27-sum-box__border-top {
    &--light {
        padding: 1rem 0;
        border-top: 1px solid @color__gray--light;
    }

    &--dark {
        padding: 2rem 0;
        border-top: 1px solid @color__gray--dark;
    }
}

.dws__m27-sum-box__total {
    padding: 2rem 0;
}

.dws__m27-sum-box__total {
    &--red {
        color: @color__brand-primary-red;
    }

    &--font-primary {
        font-family: @font-family__primary;
    }

    &--small {
        color: @color__gray--regular;
        font-size: 1.2rem;
        font-weight: normal;
    }
}

.dws__m27-sum-box__checkout-info-icon {
    width: 1.45rem;
    height: 1.45rem;
    margin-right: 1rem;
}

.dws__m27-sum-box__coupon-input {
    padding: 1rem 0.4rem 1rem 1rem;
    width: 100%;
    font-size: 1.3rem;
    border: 1px solid @color__secondary--regular;
    border-radius: 5px 0 0 5px;
    border-right: none;
    color: @color__secondary--regular;
}

.dws__m27-sum-box__coupon-btn {
    padding: 1rem;
    background-color: @color__neutral;
    border: 1px solid @color__brand-primary-red;
    border-radius: 0 5px 5px 0;
    font-size: 1.6rem;
    color: @color__brand-primary-red;
    cursor: pointer;
    .buttonAnimation(@color__neutral, @color__brand-primary-red, @color__brand-primary-red)
}
