.dws__product-card {
    position: relative;
}

.dws__product-card__content {
    position: relative;

    background: @color__neutral;
    border-radius: 0.5rem;
    box-shadow: 3px 3px 3px #00000029,-3px -3px 3px #E1E1E129;

    padding: 2.5rem 1.5rem;
    z-index: 2;

    @media @media__lg--min {
        padding: 2rem 2rem 2.5rem 2rem;
    }

    .color-brand-primary-red & {
        border-top: 5px solid @color__brand-primary-red;
    }

    .color-brand-primary-blue & {
        border-top: 5px solid @color__brand-primary-blue;
    }

    .color-brand-primary-yellow & {
        border-top: 5px solid @color__brand-primary-yellow;
    }
}

.dws__product__media-wrapper {
    max-width: 18.2rem;
    margin: 0 auto;

    @media @media__lg--min {
        max-width: unset;
    }

}

.dws__product-card__content img {
    max-width: 100%;

    border-radius: 5px;
    box-shadow: 3px 3px 6px #00000029;
}

.dws__product-card__collage {
    display: flex;
    flex-wrap: wrap;
    width: 18.2rem;
    margin: 0 auto;

    @media @media__lg--min {
        width: 100%;
    }
}

.dws__product-card__collage picture {
    display: flex;
    flex: 1 1 50%;
    padding: 0 0.5rem 1rem;
}

.dws__product-card__speaker img {
    height: auto;

    border-radius: 9999px;
}

.dws__m14-product-card__icon-text {
    display: flex;
    align-items: baseline;
    margin-bottom: 0.2rem;

    .as__icon {
        flex-shrink: 0;
        width: 1rem;
        height: 1.1rem;
    }
}

.dws__product-card__btn-wrapper {
    margin-bottom: -1.5rem;

    @media (max-width: 374px) {
        justify-content: flex-start !important;
    }

    .as__btn {
        margin-bottom: 1.5rem;
    }

    .as__btn__wrapper {
        background: @color__brand-primary-red--dark !important;
    }
}

.dws__product-card__progress {
    @media @media__lg--min {
        max-width: 13rem;
    }
}

.dws__product-card__annex {
    padding: 0.9rem 1rem 0;
    background-color: #f8f8f8;
    border: 1px solid #E6E5E1;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    @media @media__lg--min {
        margin: 0 2rem;
    }
}

.dws__product-card__functions {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (min-width: 375px) {
        justify-content: flex-start;
    }

    @media @media__lg--min {
    }
}

.dws__product-card__functions__text {
    flex: 1 0 100%;

    @media @media__lg--min {
        flex: 0 0 auto;
    }
}

.dws__product-card__function {
    color: @color__brand-primary-red;
    width: 3.8rem;
    height: 3.8rem;
    margin-bottom: 1rem;
    position: relative;
    border: 2px solid @color__secondary--light;
    border-radius: 5px;
    background-color: @color__neutral;

    @media @media__lg--min {
        width: 4rem;
        height: 4rem;
    }

    .as__icon {
        width: 2.3rem;
        height: 2.3rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        transition: color @transition__duration @transition__timing-function;
    }

    &:hover,
    &:focus {
        color: @color__neutral;
        background-color: @color__brand-primary-red;
        border-color: @color__brand-primary-red;
    }

    & + .dws__product-card__function {
        @media (min-width: 375px) {
            margin-left: 1rem;
        }
    }

}

.dws__product-card__anex__icon {
    width: 1.7rem;
    height: 0.8rem;
}

.dws__product-card__status-- {
    &notStarted {
        font-weight: @font-weight__700;
        color: @color__secondary--regular;
    }

    &inProgress {
        font-weight: @font-weight__700;
        color: @color__brand-secondary-orange;
    }

    &completed {
        font-weight: @font-weight__700;
        color: @color__brand-secondary-green;
    }
}

/* overwrites */
.dws__product-card {

    .as__btn--player {
        min-width: auto;

        @media @media__lg--min {
            flex: 1 1 auto;
        }

        @media @media__xl--min {
            flex: 0 1 20.2rem;
        }
    }

    .as__h3 sup {
        top: -.15em;
    }
}

.dws__m14-product-card__cart-btn {
    @media (max-width: 374px) {
        justify-content: flex-start;
        min-width: 24.5rem;
    }
}

/* Overwrites */
.dws__product-card__content .dws__tag {

    &.dws__tag--highlighted {
        margin-right: 0;
        top: 0.5rem;
    }

    @media @media__lg--min {

        &.dws__tag--highlighted {
            margin-right: 1rem;
            top: 1rem;
        }
    }
}
