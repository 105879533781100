.dws__header--reduced__rte {
    p {
        font-size: 1.4rem;
        line-height: 2rem;
    }

    strong {
        font-weight: @font-weight__500;
    }
}

.as__header-spacer.dws__header-spacer--reduced {
    height: @header--reduced__spacer__height;

    @media @media__sm--min {
        height: @header--reduced__spacer__height--sm;
    }

    @media @media__lg--min {
        height: @header--reduced__spacer__height--lg;
    }
}

.as__header-spacer.dws__header-spacer--reduced.as__header-spacer--no-margin {
    height: @header--reduced__height;

    @media @media__sm--min {
        height: @header--reduced__height--sm;
    }

    @media @media__lg--min {
        height: @header--reduced__height--lg;
    }
}
