.dws__m85-learn-control-quiz__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    transition: 300ms ease-in-out opacity;
    pointer-events: none;

    background-color: rgba(0,0,0,.5);

    z-index: 1;

    .dws__m85-learn-control-quiz--is-loading & {
        opacity: 1;
        pointer-events: auto;
    }
}

.dws__m85-learn-control-quiz__loader-ripple {
    display: inline-block;
    position: relative;
    width: 8rem;
    height: 8rem;
}

.dws__m85-learn-control-quiz__loader-ripple div {
    position: absolute;
    border: 4px solid @color__neutral;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.dws__m85-learn-control-quiz__loader-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 3.6rem;
        left: 3.6rem;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 3.6rem;
        left: 3.6rem;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 3.6rem;
        left: 3.6rem;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 7.2rem;
        height: 7.2rem;
        opacity: 0;
    }
}
