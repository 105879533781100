.dws__m17-filter .as__accordion__label {
    display: flex;
    align-items: center;

    font-family: @font-family__primary;
    font-size: @text__font-size--xs;
    line-height: @text__line-height--xs;
    font-weight: @font-weight__400;

    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;

    .as__icon {
        top: ~'calc((@{text__line-height--xs} + 1rem + 1rem + @{accordion__item__border-width} + @{accordion__item__border-width}) / 2 - 1rem / 2)';
        width: 1rem;
        height: 0.5rem;
        color: @color__secondary--dark;
    }
}

.dws__m17-filter--fullwidth {
    margin-left: -@outer-margin--xs;
    margin-right: -@outer-margin--xs;
    padding: 2rem @outer-margin--xs;
    background-color: @color__secondary--lighter;

    @media @media__sm--min {
        margin-left: -@outer-margin--sm;
        margin-right: -@outer-margin--sm;
        padding-left: @outer-margin--sm;
        padding-right: @outer-margin--sm;
    }
    @media @media__md--min {
        margin-left: -@outer-margin--md;
        margin-right: -@outer-margin--md;
        padding-left: @outer-margin--md;
        padding-right: @outer-margin--md;
    }

    @media @media__lg--min {
        margin-left: 0;
        margin-right: 0;
        padding: 0;
    }
}

.dws__m17-filter .as__accordion__content-wrapper {
    padding: 1rem 1rem 3rem;
    max-height: 500px;

    @media @media__lg--min {
        padding-top: 3rem;
    }
}

.dws__m17-filter .as__label.as__checkbox__label {
    margin-bottom: 3rem;
}

.dws__m17-filter .as__accordion__label .dws__m17-filter__offcanvas-toggle-icon {
    position: static;
    width: 1.8rem;
    height: 1.8rem;
}

.dws__m17-filter .as__accordion__item {
    @media @media__lg--min {
        position: relative;
    }
}

.dws__m17-filter__accordion-item-wrapper .as__accordion__content {
    @media @media__lg--min {
        position: absolute;
        top: 100%;
        left: 0%;

        width: 100%;
        min-width: 20rem;

        margin-top: @accordion__item__border-width;

        background: @color__neutral;
        box-shadow: 0 3px 6px #00000029;
        z-index: 10;

        inline-size: max-content;
    }
}

.dws__m17-filter__active-filters {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 2.2rem;
    height: 2.2rem;

    border-radius: 9999px;

    font-weight: @font-weight__400;
    font-size: 1.4rem;
    line-height: 1;
    color: @color__neutral;

    background: @color__brand-primary-red;
}

.dws__m17-filter__active-filters--no-filters {
    display: none;
}

.dws__m17-filter__offcanvas {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;

    width: 100%;

    background: @color__neutral;

    transition: 300ms ease transform;
    transform: translateX(100%);
    z-index: @zindex--overlay;

    @media @media__sm--min {
        width: 75%;
    }

    @media @media__md--min {
        width: 50%;
    }

    @media @media__lg--min {
        position: static;
        width: auto;
        transform: none;
        background: transparent;

        transition: none;
    }
}

.dws__m17-filter__offcanvas-content {
    max-height: 100%;
    overflow: auto;

    @media @media__lg--min {
        flex: 0 1 auto;
        overflow: visible;
    }
}

.dws__m17-filter__offcanvas-close {
    display: block;
    width: 3rem;
    height: 3rem;

    color: @color__brand-primary-red;

    cursor: pointer;

    .as__icon {
        width: inherit;
        height: inherit;

        transform: rotate(90deg);
    }
}

.dws__m17-filter__accordion-group {
    @media @media__lg--min {
        margin-bottom: -2rem;
    }
}

.dws__m17-filter__accordion-item-wrapper {
    @media @media__lg--min {
        margin-bottom: 2rem;
    }
}

.dws__m17-filter__offcanvas .as__accordion__toggle:first-child + .as__accordion__item {
    border-top: 0;
}

.dws__m17-filter__offcanvas .dws__m17-filter__accordion-item-wrapper:first-child .as__accordion__toggle + .as__accordion__item {
    border-top: @accordion__item__border-top--first-child;
}

.dws__m17-filter__offcanvas .dws__m17-filter__accordion-item-wrapper .as__accordion__toggle + .as__accordion__item {
    @media @media__lg--min {
        border-top: @accordion__item__border-top--first-child;
    }
}

.dws__m17-filter__offcanvas .dws__m17-filter__active-filters {
    background: @color__secondary--dark;
}

.dws__m17-filter__offcanvas-footer {
    box-shadow: 0px -3px 4px #0000001A;
    background: #F8F8F8;

    @media @media__lg--min {
        flex: 0 0 auto;

        box-shadow: none;
        background: transparent;
    }
}

.dws__m17-filter__reset-btn {
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.dws__m17-filter__offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    opacity: 0;
    visibility: hidden;

    background-color: @modal__overlay__background-color;
    transition: 300ms ease opacity, 300ms ease visibility;
    z-index: @zindex--overlay - 1;
}

.dws__m17-filter__offcanvas-toggle:checked + .as__accordion__item > .as__accordion__label .dws__m17-filter__offcanvas-toggle-icon {
    transform: none;
}

.dws__m17-filter__offcanvas-toggle:checked ~ .dws__m17-filter__offcanvas {
    transition: 850ms ease transform;
    transform: translateX(0);
}

.dws__m17-filter__offcanvas-toggle:checked ~ .dws__m17-filter__offcanvas-backdrop {
    opacity: 1;
    visibility: visible;
}

.dws__m17-filter__form {
    position: relative;
}