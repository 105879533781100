.dws__m45-search-result {
    .as__h6 {
        font-weight: @font-weight__700;
    }

    .as__accordion__item {
        border: none !important;
    }

    .dws__m45-search-result__accordion-trigger {
        display: block;
        border: .1rem solid @color__secondary--light;
        border-right: none;
        border-left: none;
    }

    .dws__m45-search-result__accordion-trigger[aria-expanded="false"] {
        .dws__m45-search-result__show-more {
            display: inline;
        }
        .dws__m45-search-result__show-less {
            display: none;
        }
    }

    .dws__m45-search-result__accordion-trigger[aria-expanded="true"] {
        .dws__m45-search-result__show-more {
            display: none;
        }
        .dws__m45-search-result__show-less {
            display: inline;
        }
    }
}

.as__accordion--m45-search-result .as__link {
    text-decoration: none;
}