.dws__footer:not(.dws__footer--reduced) {
    background-image: ~'url(@{image__path}/footer_wave_desktop.svg)';
    background-repeat: no-repeat;
    background-position: center bottom;

    @media (min-width: @breakpoint--md) {
        background-size: 1920px auto;
    }

    @media (min-width: 1920px) {
        background-size: 100%;
    }

}

.dws__footer .as__slider::after {
    @media (min-width: @breakpoint--md) {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 45px;
        z-index: 2;
        height: 100%;
        border-top-right-radius: 9999px;
        border-bottom-right-radius: 9999px;
        background-color: @color__brand-primary-red;
        box-shadow: 6px 0px 6px rgb(83,21,33, 30%);
    }
}

.dws__footer__info {
    color: @footer__info__color;
    font-size: @footer__info__font-size;
    line-height: @footer__info__line-height;
    padding: @footer__info__padding;
}

.dws__footer__service {
    background-color: @color__brand-primary-red;
    @media (min-width: @breakpoint--md) {
        background: linear-gradient(90deg, rgba(137,35,55,1) 0%, rgba(137,35,55,1) 49%, rgba(100,27,41,1) 49%, rgba(100,27,41,1) 100%);
    }
}

.dws__footer__service__link {
    color: @color__neutral;
    padding: 2.3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .as__icon {
        color: @color__neutral;
        width: 3.3rem;
        height: 2.4rem;
    }
}

.dws__footer__service {
    overflow-y: hidden;

    .as__slider {
        position: relative;
    }

    .as__slider__item {
        z-index: 3;
        min-height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .as__slider__arrow {
        color: @color__neutral;
        position: absolute;
        top: 50%;
        z-index: 3;

        @media (min-width: @breakpoint--md) {
            display: none;
        }

        .as__icon {
            width: 2.4rem;
            height: 1.2rem;
        }
    }

    .as__slider__arrow--next {
        right: -0.6rem;
        transform: translateY(-50%) rotate(-90deg)
    }

    .as__slider__arrow--prev {
        left: -0.6rem;
        transform: translateY(-50%) rotate(90deg)
    }
}

.dws__footer .as__slider__container {
    background-color: @color__brand-primary-red;
}

.dws__footer__navigation {
    @media (min-width: @breakpoint--md) {
        padding: 4.5rem 0 3rem;
    }
}

.dws__footer__mainnavigation {
    order: 2;

    @media (min-width: @breakpoint--md) {
        order: 0
    }
}

.dws__footer__mainnavigation__heading {
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-family: 'Barlow';
    font-weight: @font-weight__700;
    text-transform: uppercase;

    @media (min-width: @breakpoint--md) {
        padding: 0;
    }

    .as__icon {
        width: 1.8rem;
        height: 0.9rem;
        top: 50%;
        transform: translateY(-50%)
    }
}

.dws__footer__mainnavigation__links {
    margin: 0;
    padding: 0.8rem 0 0 0;
    list-style: none;
}

.dws__footer__mainnavigation__link {
    line-height: 2rem;
    padding: 0.8rem 0;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
        color: @footer__link__color;
    }

    &.dws__footer__mainnavigation__link--highlight {
        color: @footer__link__color;
        font-size: 1.8rem;

        &:hover,
        &:focus {
            color: @color__red--dark;
        }
    }

    .as__icon {
        color: @color__secondary--dark;
        width: 1.8rem;
        height: 1.8rem;
    }
}

.dws__footer__mainnavigation .as__accordion__content-wrapper {
    padding-bottom: 2.2rem;
}

.dws__footer .as__accordion__item {
    @media (min-width: @breakpoint--md) {
        margin-right: 4.6rem;

        &:last-child {
            margin-right: 0;
        }
    }

    @media (min-width: @breakpoint--xl) {
        margin-right: 8.6rem;
    }
}

.dws__footer .as__accordion__toggle:checked+.as__accordion__item .as__accordion__label .as__icon {
    transform: rotate(180deg) translateY(50%);
}

.dws__footer .as__accordion__label[aria-expanded="true"] + .as__accordion__content {
    max-height: 26rem;
}

.dws__footer__quicklinks {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    @media (min-width: @breakpoint--md) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.dws__footer__quicklink-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    padding: 0.8rem 2rem 0.7rem 2rem;
    border-radius: 15px;
    box-shadow: @footer__btn__box-shadow;
    position: relative;
    background-color: @color__neutral;
    transition: background-color @transition__duration @transition__timing-function,
        color @transition__duration @transition__timing-function;

    @media (min-width: @breakpoint--md) {
        display: block;
        padding: 1.8rem 2rem 1.8rem 8.3rem;
        width: 30rem;
    }

    .as__icon {
        display: inline-block;
        width: 3.4rem;
        height: 3.4rem;
        margin-right: 2rem;

        @media (min-width: @breakpoint--md) {
            margin-left: 2rem;
            margin-right: 0;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &:hover,
    &:focus {
        background-color: @footer__link__color;
    }
}

.dws__footer__quicklink__heading {
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-transform: uppercase;

    @media (min-width: @breakpoint--md) {
        font-size: 2.1rem;
        line-height: 3.1rem;
    }
}

.dws__footer__quicklink__subheading {
    color: @footer__link__color;
    font-size: 1.6rem;
    line-height: 2.1rem;

    @media (min-width: @breakpoint--md) {
        font-size: 1.8rem;
        line-height: 3.1rem;
    }
}

.dws__footer__quicklink-btn:hover,
.dws__footer__quicklink-btn:focus {
    .dws__footer__quicklink__heading,
    .dws__footer__quicklink__subheading,
    .as__icon {
        color: @color__neutral;
    }
}

.dws__footer__bottom {
    padding: @footer__bottom__padding--xs;

    @media (min-width: @breakpoint--md) {
        padding: @footer__bottom__padding--md;
    }
}

.dws__footer__bottom__border {
    padding-top: 2rem;
    border-top: 1px solid #BEBEBE;
}

.dws__footer__metanavigation {
    order: 2;

    @media (min-width: @breakpoint--md) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        order: 0
    }

    @media (min-width: @breakpoint--lg) {
        align-items: center;
        flex-direction: row;
    }
}

.dws__footer__metanavigation__links {
    list-style: none;
    margin: 0;
    padding: 6.5rem 0 4.5rem;
    display: flex;
    justify-content: space-between;
    order: 2;

    a {
        margin-right: 1.5rem;

        &:last-child {
            margin-right: 0;
        }
    }

    @media (min-width: @breakpoint--md) {
        padding: 0;
        margin-bottom: 3rem;
        justify-content: start;
        order: 0;

        a {
            margin-right: 3.3rem;

            &:last-child {
                margin-right: 3.3rem;
            }
        }
    }

    @media (min-width: @breakpoint--lg) {
        margin-bottom: 0;
    }
}

.dws__footer__metanavigation__links .dws__metanavigation__link {
    color: @color__secondary--dark;
    font-size: @footer__metanavigation__link__font-size--xs;
    line-height: @footer__metanavigation__link__line-height;

    @media (min-width: @breakpoint--md) {
        color: @color__secondary--dark;
        font-size: @footer__metanavigation__link__font-size--md;
    }

    &:hover,
    &:focus {
        color: @footer__link__color;
    }

    &:last-child {
        padding-left: 0.2rem;
        flex-grow: 0;
    }
}

.dws__footer__metanavigation__logo {
    width: 14.6rem;
    height: 2.4rem;
    background-image: ~'url(@{icon__path}/DWS_logo_footer.svg)';
    background-repeat: no-repeat;
    background-size: 100%;

    @media (min-width: @breakpoint--md) {
        width: 20.2rem;
        height: 3.3rem;
    }
}

.dws__footer__social {
    display: flex;
    justify-content: space-between;
    order: 1;
    @media (min-width: @breakpoint--md) {
        justify-content: flex-end;
        order: 0;
    }
}

.dws__footer__social__link {
    display: inline-block;
    border-radius: 5px;
    height: 3.5rem;
    width: 3.5rem;
    margin-right: 1.5rem;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @color__neutral;
    box-shadow: @footer__btn__box-shadow--small;

    @media (min-width: @breakpoint--md) {
        margin-right: 3.2rem;
    }

    &:last-child {
        margin-right: 0;
    }

    .as__icon {
        color: @footer__link__color;
        width: 2.2rem;
        height: 2.2rem;
    }

    &:hover,
    &:focus {
        background-color: @footer__link__color;

        .as__icon {
            color: @color__neutral;
        }
    }
}

/* Overwrite accordion styles for md+ */

@media (min-width: @breakpoint--md) {
    .dws__footer .as__accordion__group {
        display: flex;
        justify-content: space-between;

        @media (min-width: @breakpoint--lg) {
            justify-content: flex-start;
        }

        .as__accordion__label {

            color: @color__secondary--dark;
            &:hover,
            &:focus {
                cursor: default;
            }
            .as__icon {
                display: none;
            }
        }

        .as__accordion__toggle:checked+.as__accordion__item .as__accordion__label {
            color: @color__secondary--dark;
        }

        .as__accordion__item,
        .as__accordion__toggle:first-child+.as__accordion__item  {
            border: none;
        }

        .as__accordion__content {
            overflow: auto;
            max-height: unset;
        }

        .as__accordion__content-wrapper {
            padding: 0;
        }
    }
}
