@import "config.less";

@devices:   sm @breakpoint--sm,
            md @breakpoint--md,
            lg @breakpoint--lg,
            xl @breakpoint--xl;

.as__container {
    margin-right: auto;
    margin-left: auto;
    max-width: @max-container-width--xs;
    padding-right: @outer-margin--xs;
    padding-left: @outer-margin--xs;
}

.as__container,
.as__container-fluid {
    padding-right: @outer-margin--xs;
    padding-left: @outer-margin--xs;
}


.as__container-fullwidth {
    padding: 0;
    width: 100%;
    overflow-x: hidden;
}

.as__row {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: @gutter-compensation;
    margin-left: @gutter-compensation;
}

.as__row.as__row--fullwidth,
.as__row.as__row--fullwidth {
    margin-right: -@outer-margin--xs;
    margin-left: -@outer-margin--xs;
}

.as__row.as__row--fullwidth-md-max,
.as__row.as__row--fullwidth-md-max {
    @media (max-width: (@breakpoint--md - 1)) {
        margin-right: -@outer-margin--xs + @gutter-compensation;
        margin-left: -@outer-margin--xs + @gutter-compensation;
    }
}

.as__row.as__row--no-gutter,
.as__row.as__row--no-gutter .as__row {
    margin-left: 0;
    margin-right: 0;

    .as__col,
    [class*="as__col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.as__row.as__row--no-gutter-force,
.as__row.as__row--no-gutter-force .as__row {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .as__col,
    [class*="as__col-"] {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

.as__row.as__row--gutter-5,
.as__row.as__row--gutter-5 .as__row {
    margin-left: -.25rem;
    margin-right: -.25rem;

    .as__col,
    [class*="as__col-"] {
        padding-right: .25rem;
        padding-left: .25rem;
    }
}

.as__row.as__row--gutter-10,
.as__row.as__row--gutter-10 .as__row {
    margin-left: -.5rem;
    margin-right: -.5rem;

    .as__col,
    [class*="as__col-"] {
        padding-right: .5rem;
        padding-left: .5rem;
    }
}

.as__row.as__row--gutter-20,
.as__row.as__row--gutter-20 .as__row {
    margin-left: -1rem;
    margin-right: -1rem;

    .as__col,
    [class*="as__col-"] {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.as__row.as__row--gutter-40,
.as__row.as__row--gutter-40 .as__row {
    margin-left: -2rem;
    margin-right: -2rem;

    .as__col,
    [class*="as__col-"] {
        padding-right: 2rem;
        padding-left: 2rem;
    }
}

.as__row.as__row--gutter-60,
.as__row.as__row--gutter-60 .as__row {
    margin-left: -3rem;
    margin-right: -3rem;

    .as__col,
    [class*="as__col-"] {
        padding-right: 3rem;
        padding-left: 3rem;
    }
}

.as__row.reverse {
    flex-direction: row-reverse;
}

.as__col.reverse {
    flex-direction: column-reverse;
}

.as__w-50 {
    width: 50% !important;
}

.as__w-100 {
    width: 100% !important;
}

.as__w-auto {
    width: auto !important;
}

.as__w-100-md-max {
    @media (max-width: (@breakpoint--md - 1)) {
        width: 100% !important;
    }
}

.as__h-100 {
    height: 100% !important;
}

.as__h-auto {
    height: auto !important;
}

.as__col,
.as__col-auto,
.as__col-1,
.as__col-2,
.as__col-3,
.as__col-4,
.as__col-5,
.as__col-6,
.as__col-7,
.as__col-8,
.as__col-9,
.as__col-10,
.as__col-11,
.as__col-12 {
    flex: 0 0 auto;
    padding-right: @half-gutter-width;
    padding-left: @half-gutter-width;
}

.as__col {
    flex-grow: 1;
    flex-basis: 0;
}

.as__col,
.as__col-auto {
    max-width: 100%;
}

.as__col-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
}

.as__col-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
}

.as__col-3 {
    flex-basis: 25%;
    max-width: 25%;
}

.as__col-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
}

.as__col-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
}

.as__col-6 {
    flex-basis: 50%;
    max-width: 50%;
}

.as__col-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
}

.as__col-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
}

.as__col-9 {
    flex-basis: 75%;
    max-width: 75%;
}

.as__col-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
}

.as__col-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
}

.as__col-12 {
    flex-basis: 100%;
    max-width: 100%;
}

.as__mr-auto,
.as__ma-auto,
.as__mx-auto {
    margin-right: auto !important;
}

.as__mr-0,
.as__ma-0,
.as__mx-0 {
    margin-right: 0 !important;
}

.as__ma-1,
.as__mx-1,
.as__mr-1 {
    margin-right: .5rem !important;
}

.as__ma-2,
.as__mx-2,
.as__mr-2 {
    margin-right: 1rem !important;
}

.as__ma-3,
.as__mx-3,
.as__mr-3 {
    margin-right: 1.5rem !important;
}

.as__ma-4,
.as__mx-4,
.as__mr-4 {
    margin-right: 2rem !important;
}

.as__ma-5,
.as__mx-5,
.as__mr-5 {
    margin-right: 2.5rem !important;
}

.as__ma-6,
.as__mx-6,
.as__mr-6 {
    margin-right: 3rem !important;
}

.as__ma-7,
.as__mx-7,
.as__mr-7 {
    margin-right: 3.5rem !important;
}

.as__ma-8,
.as__mx-8,
.as__mr-8 {
    margin-right: 4rem !important;
}

.as__ma-9,
.as__mx-9,
.as__mr-9 {
    margin-right: 4.5rem !important;
}

.as__ma-10,
.as__mx-10,
.as__mr-10 {
    margin-right: 5rem !important;
}

.as__ma-11,
.as__mx-11,
.as__mr-11 {
    margin-right: 5.5rem !important;
}

.as__ma-12,
.as__mx-12,
.as__mr-12 {
    margin-right: 6rem !important;
}

.as__ma-13,
.as__mx-13,
.as__mr-13 {
    margin-right: 6.5rem !important;
}

.as__ma-14,
.as__mx-14,
.as__mr-14 {
    margin-right: 7rem !important;
}

.as__ma-15,
.as__mx-15,
.as__mr-15 {
    margin-right: 7.5rem !important;
}

.as__ma-16,
.as__mx-16,
.as__mr-16 {
    margin-right: 8rem !important;
}

.as__ma-17,
.as__mx-17,
.as__mr-17 {
    margin-right: 8.5rem !important;
}

.as__ma-18,
.as__mx-18,
.as__mr-18 {
    margin-right: 9rem !important;
}

.as__ml-auto,
.as__ma-auto,
.as__mx-auto {
    margin-left: auto !important;
}

.as__ml-0,
.as__ma-0,
.as__mx-0 {
    margin-left: 0 !important;
}

.as__ma-1,
.as__mx-1,
.as__ml-1 {
    margin-left: .5rem !important;
}

.as__ma-2,
.as__mx-2,
.as__ml-2 {
    margin-left: 1rem !important;
}

.as__ma-3,
.as__mx-3,
.as__ml-3 {
    margin-left: 1.5rem !important;
}

.as__ma-4,
.as__mx-4,
.as__ml-4 {
    margin-left: 2rem !important;
}

.as__ma-5,
.as__mx-5,
.as__ml-5 {
    margin-left: 2.5rem !important;
}

.as__ma-6,
.as__mx-6,
.as__ml-6 {
    margin-left: 3rem !important;
}

.as__ma-7,
.as__mx-7,
.as__ml-7 {
    margin-left: 3.5rem !important;
}

.as__ma-8,
.as__mx-8,
.as__ml-8 {
    margin-left: 4rem !important;
}

.as__ma-9,
.as__mx-9,
.as__ml-9 {
    margin-left: 4.5rem !important;
}

.as__ma-10,
.as__mx-10,
.as__ml-10 {
    margin-left: 5rem !important;
}

.as__ma-11,
.as__mx-11,
.as__ml-11 {
    margin-left: 5.5rem !important;
}

.as__ma-12,
.as__mx-12,
.as__ml-12 {
    margin-left: 6rem !important;
}

.as__ma-13,
.as__mx-13,
.as__ml-13 {
    margin-left: 6.5rem !important;
}

.as__ma-14,
.as__mx-14,
.as__ml-14 {
    margin-left: 7rem !important;
}

.as__ma-15,
.as__mx-15,
.as__ml-15 {
    margin-left: 7.5rem !important;
}

.as__ma-16,
.as__mx-16,
.as__ml-16 {
    margin-left: 8rem !important;
}

.as__ma-17,
.as__mx-17,
.as__ml-17 {
    margin-left: 8.5rem !important;
}

.as__ma-18,
.as__mx-18,
.as__ml-18 {
    margin-left: 9rem !important;
}

.as__mb-auto,
.as__ma-auto,
.as__my-auto {
    margin-bottom: auto !important;
}

.as__mb-0,
.as__ma-0,
.as__my-0 {
    margin-bottom: 0 !important;
}

.as__ma-1,
.as__my-1,
.as__mb-1 {
    margin-bottom: .5rem !important;
}

.as__ma-2,
.as__my-2,
.as__mb-2 {
    margin-bottom: 1rem !important;
}

.as__ma-3,
.as__my-3,
.as__mb-3 {
    margin-bottom: 1.5rem !important;
}

.as__ma-4,
.as__my-4,
.as__mb-4 {
    margin-bottom: 2rem !important;
}

.as__ma-5,
.as__my-5,
.as__mb-5 {
    margin-bottom: 2.5rem !important;
}

.as__ma-6,
.as__my-6,
.as__mb-6 {
    margin-bottom: 3rem !important;
}

.as__ma-7,
.as__my-7,
.as__mb-7 {
    margin-bottom: 3.5rem !important;
}

.as__ma-8,
.as__my-8,
.as__mb-8 {
    margin-bottom: 4rem !important;
}

.as__ma-9,
.as__my-9,
.as__mb-9 {
    margin-bottom: 4.5rem !important;
}

.as__ma-10,
.as__my-10,
.as__mb-10 {
    margin-bottom: 5rem !important;
}

.as__ma-11,
.as__my-11,
.as__mb-11 {
    margin-bottom: 5.5rem !important;
}

.as__ma-12,
.as__my-12,
.as__mb-12 {
    margin-bottom: 6rem !important;
}

.as__ma-13,
.as__my-13,
.as__mb-13 {
    margin-bottom: 6.5rem !important;
}

.as__ma-14,
.as__my-14,
.as__mb-14 {
    margin-bottom: 7rem !important;
}

.as__ma-15,
.as__my-15,
.as__mb-15 {
    margin-bottom: 7.5rem !important;
}

.as__ma-16,
.as__my-16,
.as__mb-16 {
    margin-bottom: 8rem !important;
}

.as__ma-17,
.as__my-17,
.as__mb-17 {
    margin-bottom: 8.5rem !important;
}

.as__ma-18,
.as__my-18,
.as__mb-18 {
    margin-bottom: 9rem !important;
}

.as__ma-19,
.as__my-19,
.as__mb-19 {
    margin-bottom: 9.5rem !important;
}

.as__ma-20,
.as__my-20,
.as__mb-20 {
    margin-bottom: 10rem !important;
}

.as__ma-26,
.as__my-26,
.as__mb-26 {
    margin-bottom: 13rem !important;
}

.as__ma-30,
.as__my-30,
.as__mb-30 {
    margin-bottom: 15rem !important;
}

.as__ma-32,
.as__my-32,
.as__mb-32 {
    margin-bottom: 16rem !important;
}

.as__ma-36,
.as__my-36,
.as__mb-36 {
    margin-bottom: 18rem !important;
}

.as__mt-auto,
.as__ma-auto,
.as__my-auto {
    margin-top: auto !important;
}

.as__mt-0,
.as__ma-0,
.as__my-0 {
    margin-top: 0 !important;
}

.as__ma-1,
.as__my-1,
.as__mt-1 {
    margin-top: .5rem !important;
}

.as__ma-2,
.as__my-2,
.as__mt-2 {
    margin-top: 1rem !important;
}

.as__ma-3,
.as__my-3,
.as__mt-3 {
    margin-top: 1.5rem !important;
}

.as__ma-4,
.as__my-4,
.as__mt-4 {
    margin-top: 2rem !important;
}

.as__ma-5,
.as__my-5,
.as__mt-5 {
    margin-top: 2.5rem !important;
}

.as__ma-6,
.as__my-6,
.as__mt-6 {
    margin-top: 3rem !important;
}

.as__ma-7,
.as__my-7,
.as__mt-7 {
    margin-top: 3.5rem !important;
}

.as__ma-8,
.as__my-8,
.as__mt-8 {
    margin-top: 4rem !important;
}

.as__ma-9,
.as__my-9,
.as__mt-9 {
    margin-top: 4.5rem !important;
}

.as__ma-10,
.as__my-10,
.as__mt-10 {
    margin-top: 5rem !important;
}

.as__ma-11,
.as__my-11,
.as__mt-11 {
    margin-top: 5.5rem !important;
}

.as__ma-12,
.as__my-12,
.as__mt-12 {
    margin-top: 6rem !important;
}

.as__ma-13,
.as__my-13,
.as__mt-13 {
    margin-top: 6.5rem !important;
}

.as__ma-14,
.as__my-14,
.as__mt-14 {
    margin-top: 7rem !important;
}

.as__ma-15,
.as__my-15,
.as__mt-15 {
    margin-top: 7.5rem !important;
}

.as__ma-16,
.as__my-16,
.as__mt-16 {
    margin-top: 8rem !important;
}

.as__ma-17,
.as__my-17,
.as__mt-17 {
    margin-top: 8.5rem !important;
}

.as__ma-18,
.as__my-18,
.as__mt-18 {
    margin-top: 9rem !important;
}

.as__ma-26,
.as__my-26,
.as__mt-26 {
    margin-top: 13rem !important;
}

.as__pr-auto,
.as__pa-auto,
.as__px-auto {
    padding-right: auto !important;
}

.as__pr-0,
.as__pa-0,
.as__px-0 {
    padding-right: 0 !important;
}

.as__pa-1,
.as__px-1,
.as__pr-1 {
    padding-right: .5rem !important;
}

.as__pa-2,
.as__px-2,
.as__pr-2 {
    padding-right: 1rem !important;
}

.as__pa-3,
.as__px-3,
.as__pr-3 {
    padding-right: 1.5rem !important;
}

.as__pa-4,
.as__px-4,
.as__pr-4 {
    padding-right: 2rem !important;
}

.as__pa-5,
.as__px-5,
.as__pr-5 {
    padding-right: 2.5rem !important;
}

.as__pa-6,
.as__px-6,
.as__pr-6 {
    padding-right: 3rem !important;
}

.as__pa-7,
.as__px-7,
.as__pr-7 {
    padding-right: 3.5rem !important;
}

.as__pa-8,
.as__px-8,
.as__pr-8 {
    padding-right: 4rem !important;
}

.as__pa-9,
.as__px-9,
.as__pr-9 {
    padding-right: 4.5rem !important;
}

.as__pa-10,
.as__px-10,
.as__pr-10 {
    padding-right: 5rem !important;
}

.as__pa-11,
.as__px-11,
.as__pr-11 {
    padding-right: 5.5rem !important;
}

.as__pa-12,
.as__px-12,
.as__pr-12 {
    padding-right: 6rem !important;
}

.as__pa-13,
.as__px-13,
.as__pr-13 {
    padding-right: 6.5rem !important;
}

.as__pa-14,
.as__px-14,
.as__pr-14 {
    padding-right: 7rem !important;
}

.as__pa-15,
.as__px-15,
.as__pr-15 {
    padding-right: 7.5rem !important;
}

.as__pa-16,
.as__px-16,
.as__pr-16 {
    padding-right: 8rem !important;
}

.as__pa-17,
.as__px-17,
.as__pr-17 {
    padding-right: 8.5rem !important;
}

.as__pa-18,
.as__px-18,
.as__pr-18 {
    padding-right: 9rem !important;
}

.as__pl-auto,
.as__pa-auto,
.as__px-auto {
    padding-left: auto !important;
}

.as__pl-0,
.as__pa-0,
.as__px-0 {
    padding-left: 0 !important;
}

.as__pa-1,
.as__px-1,
.as__pl-1 {
    padding-left: .5rem !important;
}

.as__pa-2,
.as__px-2,
.as__pl-2 {
    padding-left: 1rem !important;
}

.as__pa-3,
.as__px-3,
.as__pl-3 {
    padding-left: 1.5rem !important;
}

.as__pa-4,
.as__px-4,
.as__pl-4 {
    padding-left: 2rem !important;
}

.as__pa-5,
.as__px-5,
.as__pl-5 {
    padding-left: 2.5rem !important;
}

.as__pa-6,
.as__px-6,
.as__pl-6 {
    padding-left: 3rem !important;
}

.as__pa-7,
.as__px-7,
.as__pl-7 {
    padding-left: 3.5rem !important;
}

.as__pa-8,
.as__px-8,
.as__pl-8 {
    padding-left: 4rem !important;
}

.as__pa-9,
.as__px-9,
.as__pl-9 {
    padding-left: 4.5rem !important;
}

.as__pa-10,
.as__px-10,
.as__pl-10 {
    padding-left: 5rem !important;
}

.as__pa-11,
.as__px-11,
.as__pl-11 {
    padding-left: 5.5rem !important;
}

.as__pa-12,
.as__px-12,
.as__pl-12 {
    padding-left: 6rem !important;
}

.as__pa-13,
.as__px-13,
.as__pl-13 {
    padding-left: 6.5rem !important;
}

.as__pa-14,
.as__px-14,
.as__pl-14 {
    padding-left: 7rem !important;
}

.as__pa-15,
.as__px-15,
.as__pl-15 {
    padding-left: 7.5rem !important;
}

.as__pa-16,
.as__px-16,
.as__pl-16 {
    padding-left: 8rem !important;
}

.as__pa-17,
.as__px-17,
.as__pl-17 {
    padding-left: 8.5rem !important;
}

.as__pa-18,
.as__px-18,
.as__pl-18 {
    padding-left: 9rem !important;
}

.as__pb-auto,
.as__pa-auto,
.as__px-auto {
    padding-bottom: auto !important;
}

.as__pb-0,
.as__pa-0,
.as__py-0 {
    padding-bottom: 0 !important;
}

.as__pa-1,
.as__py-1,
.as__pb-1 {
    padding-bottom: .5rem !important;
}

.as__pa-2,
.as__py-2,
.as__pb-2 {
    padding-bottom: 1rem !important;
}

.as__pa-3,
.as__py-3,
.as__pb-3 {
    padding-bottom: 1.5rem !important;
}

.as__pa-4,
.as__py-4,
.as__pb-4 {
    padding-bottom: 2rem !important;
}

.as__pa-5,
.as__py-5,
.as__pb-5 {
    padding-bottom: 2.5rem !important;
}

.as__pa-6,
.as__py-6,
.as__pb-6 {
    padding-bottom: 3rem !important;
}

.as__pa-7,
.as__py-7,
.as__pb-7 {
    padding-bottom: 3.5rem !important;
}

.as__pa-8,
.as__py-8,
.as__pb-8 {
    padding-bottom: 4rem !important;
}

.as__pa-9,
.as__py-9,
.as__pb-9 {
    padding-bottom: 4.5rem !important;
}

.as__pa-10,
.as__py-10,
.as__pb-10 {
    padding-bottom: 5rem !important;
}

.as__pa-11,
.as__py-11,
.as__pb-11 {
    padding-bottom: 5.5rem !important;
}

.as__pa-12,
.as__py-12,
.as__pb-12 {
    padding-bottom: 6rem !important;
}

.as__pa-13,
.as__py-13,
.as__pb-13 {
    padding-bottom: 6.5rem !important;
}

.as__pa-14,
.as__py-14,
.as__pb-14 {
    padding-bottom: 7rem !important;
}

.as__pa-15,
.as__py-15,
.as__pb-15 {
    padding-bottom: 7.5rem !important;
}

.as__pa-16,
.as__py-16,
.as__pb-16 {
    padding-bottom: 8rem !important;
}

.as__pa-17,
.as__py-17,
.as__pb-17 {
    padding-bottom: 8.5rem !important;
}

.as__pa-18,
.as__py-18,
.as__pb-18 {
    padding-bottom: 9rem !important;
}

.as__pt-auto,
.as__pa-auto,
.as__px-auto {
    padding-top: auto !important;
}

.as__pt-0,
.as__pa-0,
.as__px-0 {
    padding-top: 0 !important;
}

.as__pa-1,
.as__py-1,
.as__pt-1 {
    padding-top: .5rem !important;
}

.as__pa-2,
.as__py-2,
.as__pt-2 {
    padding-top: 1rem !important;
}

.as__pa-3,
.as__py-3,
.as__pt-3 {
    padding-top: 1.5rem !important;
}

.as__pa-4,
.as__py-4,
.as__pt-4 {
    padding-top: 2rem !important;
}

.as__pa-5,
.as__py-5,
.as__pt-5 {
    padding-top: 2.5rem !important;
}

.as__pa-6,
.as__py-6,
.as__pt-6 {
    padding-top: 3rem !important;
}

.as__pa-7,
.as__py-7,
.as__pt-7 {
    padding-top: 3.5rem !important;
}

.as__pa-8,
.as__py-8,
.as__pt-8 {
    padding-top: 4rem !important;
}

.as__pa-9,
.as__py-9,
.as__pt-9 {
    padding-top: 4.5rem !important;
}

.as__pa-10,
.as__py-10,
.as__pt-10 {
    padding-top: 5rem !important;
}

.as__pa-11,
.as__py-11,
.as__pt-11 {
    padding-top: 5.5rem !important;
}

.as__pa-12,
.as__py-12,
.as__pt-12 {
    padding-top: 6rem !important;
}

.as__pa-13,
.as__py-13,
.as__pt-13 {
    padding-top: 6.5rem !important;
}

.as__pa-14,
.as__py-14,
.as__pt-14 {
    padding-top: 7rem !important;
}

.as__pa-15,
.as__py-15,
.as__pt-15 {
    padding-top: 7.5rem !important;
}

.as__pa-16,
.as__py-16,
.as__pt-16 {
    padding-top: 8rem !important;
}

.as__pa-17,
.as__py-17,
.as__pt-17 {
    padding-top: 8.5rem !important;
}

.as__pa-18,
.as__py-18,
.as__pt-18 {
    padding-top: 9rem !important;
}

.as__flex-row {
    flex-direction: row;
}

.as__flex-column {
    flex-direction: column;
}

.as__start {
    justify-content: flex-start;
    text-align: start;
}

.as__center {
    justify-content: center;
    text-align: center;
}

.as__end {
    justify-content: flex-end;
    text-align: end;
}

.as__self-end {
    align-self: end;
}

.as__top {
    align-items: flex-start;
}

.as__middle {
    align-items: center;
}

.as__bottom {
    align-items: flex-end;
}

.as__around {
    justify-content: space-around;
}

.as__between {
    justify-content: space-between;
}

.as__wrap {
    flex-wrap: wrap;
}

.as__nowrap {
    flex-wrap: nowrap;
}

.as__first {
    order: -1;
}

.as__last {
    order: 1;
}

.as__grow {
    flex: 1 !important;
}

.as__hide {
    display: none !important;
}

.as__show {
    display: block !important;
}

.as__show--inline {
    display: inline !important;
}

.as__show--inline-block {
    display: inline-block !important;
}

.as__show--flex {
    display: flex !important;
}

.as__show--inline-flex {
    display: inline-flex !important;
}

.make-grid-columns();
.make-grid-columns(@iteration: length(@devices)) when (@iteration > 0) {
    .make-grid-columns(@iteration - 1);
    @device: extract(@devices, @iteration);
    @name: extract(@device, 1);
    @size: extract(@device, 2);


    @media (min-width: @size) {
        .as__container {
            @container-width: ~'max-container-width--@{name}';
            .container-width(@container-width);
        }

        .as__container,
        .as__container-fluid {
            @container-padding: ~"outer-margin--@{name}";
            .container-padding(@container-padding);
        }

        .as__col-@{name} {

            flex-grow: 1;
            flex-basis: 0;

            &,
            &-auto {
                max-width: 100%;
            }

            &-auto,
            &-1,
            &-2,
            &-3,
            &-4,
            &-5,
            &-6,
            &-7,
            &-8,
            &-9,
            &-10,
            &-11,
            &-12 {
                flex: 0 0 auto;
                padding-right: @half-gutter-width;
                padding-left: @half-gutter-width;
            }

            &-1 {
                flex-basis: 8.33333333%;
                max-width: 8.33333333%;
            }

            &-2 {
                flex-basis: 16.66666667%;
                max-width: 16.66666667%;
            }

            &-3 {
                flex-basis: 25%;
                max-width: 25%;
            }

            &-4 {
                flex-basis: 33.33333333%;
                max-width: 33.33333333%;
            }

            &-5 {
                flex-basis: 41.66666667%;
                max-width: 41.66666667%;
            }

            &-6 {
                flex-basis: 50%;
                max-width: 50%;
            }

            &-7 {
                flex-basis: 58.33333333%;
                max-width: 58.33333333%;
            }

            &-8 {
                flex-basis: 66.66666667%;
                max-width: 66.66666667%;
            }

            &-9 {
                flex-basis: 75%;
                max-width: 75%;
            }

            &-10 {
                flex-basis: 83.33333333%;
                max-width: 83.33333333%;
            }

            &-11 {
                flex-basis: 91.66666667%;
                max-width: 91.66666667%;
            }

            &-12 {
                flex-basis: 100%;
                max-width: 100%;
            }
        }

        .as__w-50-@{name} {
            width: 50% !important;
        }

        .as__w-100-@{name} {
            width: 100% !important;
        }

        .as__w-auto-@{name} {
            width: auto !important;
        }

        .as__flex-row-@{name} {
            flex-direction: row;
        }

        .as__flex-column-@{name} {
            flex-direction: column;
        }

        .as__start-@{name} {
            justify-content: flex-start;
            text-align: start;
        }

        .as__center-@{name} {
            justify-content: center;
            text-align: center;
        }

        .as__end-@{name} {
            justify-content: flex-end;
            text-align: end;
        }

        .as__top-@{name} {
            align-items: flex-start;
        }

        .as__middle-@{name} {
            align-items: center;
        }

        .as__bottom-@{name} {
            align-items: flex-end;
        }

        .as__around-@{name} {
            justify-content: space-around;
        }

        .as__between-@{name} {
            justify-content: space-between;
        }

        .as__first-@{name} {
            order: -1;
        }

        .as__last-@{name} {
            order: 1;
        }

        .as__mr-@{name}-auto,
        .as__ma-@{name}-auto,
        .as__mx-@{name}-auto {
            margin-right: auto !important;
        }

        .as__mr-@{name}-0,
        .as__ma-@{name}-0,
        .as__mx-@{name}-0 {
            margin-right: 0 !important;
        }

        .as__ma-@{name}-1,
        .as__mx-@{name}-1,
        .as__mr-@{name}-1 {
            margin-right: .5rem !important;
        }

        .as__ma-@{name}-2,
        .as__mx-@{name}-2,
        .as__mr-@{name}-2 {
            margin-right: 1rem !important;
        }

        .as__ma-@{name}-3,
        .as__mx-@{name}-3,
        .as__mr-@{name}-3 {
            margin-right: 1.5rem !important;
        }

        .as__ma-@{name}-4,
        .as__mx-@{name}-4,
        .as__mr-@{name}-4 {
            margin-right: 2rem !important;
        }

        .as__ma-@{name}-5,
        .as__mx-@{name}-5,
        .as__mr-@{name}-5 {
            margin-right: 2.5rem !important;
        }

        .as__ma-@{name}-6,
        .as__mx-@{name}-6,
        .as__mr-@{name}-6 {
            margin-right: 3rem !important;
        }

        .as__ma-@{name}-7,
        .as__mx-@{name}-7,
        .as__mr-@{name}-7 {
            margin-right: 3.5rem !important;
        }

        .as__ma-@{name}-8,
        .as__mx-@{name}-8,
        .as__mr-@{name}-8 {
            margin-right: 4rem !important;
        }

        .as__ma-@{name}-9,
        .as__mx-@{name}-9,
        .as__mr-@{name}-9 {
            margin-right: 4.5rem !important;
        }

        .as__ma-@{name}-10,
        .as__mx-@{name}-10,
        .as__mr-@{name}-10 {
            margin-right: 5rem !important;
        }

        .as__ma-@{name}-11,
        .as__mx-@{name}-11,
        .as__mr-@{name}-11 {
            margin-right: 5.5rem !important;
        }

        .as__ma-@{name}-12,
        .as__mx-@{name}-12,
        .as__mr-@{name}-12 {
            margin-right: 6rem !important;
        }

        .as__ma-@{name}-13,
        .as__mx-@{name}-13,
        .as__mr-@{name}-13 {
            margin-right: 6.5rem !important;
        }

        .as__ma-@{name}-14,
        .as__mx-@{name}-14,
        .as__mr-@{name}-14 {
            margin-right: 7rem !important;
        }

        .as__ma-@{name}-15,
        .as__mx-@{name}-15,
        .as__mr-@{name}-15 {
            margin-right: 7.5rem !important;
        }

        .as__ma-@{name}-16,
        .as__mx-@{name}-16,
        .as__mr-@{name}-16 {
            margin-right: 8rem !important;
        }

        .as__ma-@{name}-17,
        .as__mx-@{name}-17,
        .as__mr-@{name}-17 {
            margin-right: 8.5rem !important;
        }

        .as__ma-@{name}-18,
        .as__mx-@{name}-18,
        .as__mr-@{name}-18 {
            margin-right: 9rem !important;
        }

        .as__ml-@{name}-auto,
        .as__ma-@{name}-auto,
        .as__mx-@{name}-auto {
            margin-left: auto !important;
        }

        .as__ml-@{name}-0,
        .as__ma-@{name}-0,
        .as__mx-@{name}-0 {
            margin-left: 0 !important;
        }

        .as__ma-@{name}-1,
        .as__mx-@{name}-1,
        .as__ml-@{name}-1 {
            margin-left: .5rem !important;
        }

        .as__ma-@{name}-2,
        .as__mx-@{name}-2,
        .as__ml-@{name}-2 {
            margin-left: 1rem !important;
        }

        .as__ma-@{name}-3,
        .as__mx-@{name}-3,
        .as__ml-@{name}-3 {
            margin-left: 1.5rem !important;
        }

        .as__ma-@{name}-4,
        .as__mx-@{name}-4,
        .as__ml-@{name}-4 {
            margin-left: 2rem !important;
        }

        .as__ma-@{name}-5,
        .as__mx-@{name}-5,
        .as__ml-@{name}-5 {
            margin-left: 2.5rem !important;
        }

        .as__ma-@{name}-6,
        .as__mx-@{name}-6,
        .as__ml-@{name}-6 {
            margin-left: 3rem !important;
        }

        .as__ma-@{name}-7,
        .as__mx-@{name}-7,
        .as__ml-@{name}-7 {
            margin-left: 3.5rem !important;
        }

        .as__ma-@{name}-8,
        .as__mx-@{name}-8,
        .as__ml-@{name}-8 {
            margin-left: 4rem !important;
        }

        .as__ma-@{name}-9,
        .as__mx-@{name}-9,
        .as__ml-@{name}-9 {
            margin-left: 4.5rem !important;
        }

        .as__ma-@{name}-10,
        .as__mx-@{name}-10,
        .as__ml-@{name}-10 {
            margin-left: 5rem !important;
        }

        .as__ma-@{name}-11,
        .as__mx-@{name}-11,
        .as__ml-@{name}-11 {
            margin-left: 5.5rem !important;
        }

        .as__ma-@{name}-12,
        .as__mx-@{name}-12,
        .as__ml-@{name}-12 {
            margin-left: 6rem !important;
        }

        .as__ma-@{name}-13,
        .as__mx-@{name}-13,
        .as__ml-@{name}-13 {
            margin-left: 6.5rem !important;
        }

        .as__ma-@{name}-14,
        .as__mx-@{name}-14,
        .as__ml-@{name}-14 {
            margin-left: 7rem !important;
        }

        .as__ma-@{name}-15,
        .as__mx-@{name}-15,
        .as__ml-@{name}-15 {
            margin-left: 7.5rem !important;
        }

        .as__ma-@{name}-16,
        .as__mx-@{name}-16,
        .as__ml-@{name}-16 {
            margin-left: 8rem !important;
        }

        .as__ma-@{name}-17,
        .as__mx-@{name}-17,
        .as__ml-@{name}-17 {
            margin-left: 8.5rem !important;
        }

        .as__ma-@{name}-18,
        .as__mx-@{name}-18,
        .as__ml-@{name}-18 {
            margin-left: 9rem !important;
        }

        .as__mb-@{name}-auto,
        .as__ma-@{name}-auto,
        .as__my-@{name}-auto {
            margin-bottom: auto !important;
        }

        .as__mb-@{name}-0,
        .as__ma-@{name}-0,
        .as__my-@{name}-0 {
            margin-bottom: 0 !important;
        }

        .as__ma-@{name}-1,
        .as__my-@{name}-1,
        .as__mb-@{name}-1 {
            margin-bottom: .5rem !important;
        }

        .as__ma-@{name}-2,
        .as__my-@{name}-2,
        .as__mb-@{name}-2 {
            margin-bottom: 1rem !important;
        }

        .as__ma-@{name}-3,
        .as__my-@{name}-3,
        .as__mb-@{name}-3 {
            margin-bottom: 1.5rem !important;
        }

        .as__ma-@{name}-4,
        .as__my-@{name}-4,
        .as__mb-@{name}-4 {
            margin-bottom: 2rem !important;
        }

        .as__ma-@{name}-5,
        .as__my-@{name}-5,
        .as__mb-@{name}-5 {
            margin-bottom: 2.5rem !important;
        }

        .as__ma-@{name}-6,
        .as__my-@{name}-6,
        .as__mb-@{name}-6 {
            margin-bottom: 3rem !important;
        }

        .as__ma-@{name}-7,
        .as__my-@{name}-7,
        .as__mb-@{name}-7 {
            margin-bottom: 3.5rem !important;
        }

        .as__ma-@{name}-8,
        .as__my-@{name}-8,
        .as__mb-@{name}-8 {
            margin-bottom: 4rem !important;
        }

        .as__ma-@{name}-9,
        .as__my-@{name}-9,
        .as__mb-@{name}-9 {
            margin-bottom: 4.5rem !important;
        }

        .as__ma-@{name}-10,
        .as__my-@{name}-10,
        .as__mb-@{name}-10 {
            margin-bottom: 5rem !important;
        }

        .as__ma-@{name}-11,
        .as__my-@{name}-11,
        .as__mb-@{name}-11 {
            margin-bottom: 5.5rem !important;
        }

        .as__ma-@{name}-12,
        .as__my-@{name}-12,
        .as__mb-@{name}-12 {
            margin-bottom: 6rem !important;
        }

        .as__ma-@{name}-13,
        .as__my-@{name}-13,
        .as__mb-@{name}-13 {
            margin-bottom: 6.5rem !important;
        }

        .as__ma-@{name}-14,
        .as__my-@{name}-14,
        .as__mb-@{name}-14 {
            margin-bottom: 7rem !important;
        }

        .as__ma-@{name}-15,
        .as__my-@{name}-15,
        .as__mb-@{name}-15 {
            margin-bottom: 7.5rem !important;
        }

        .as__ma-@{name}-16,
        .as__my-@{name}-16,
        .as__mb-@{name}-16 {
            margin-bottom: 8rem !important;
        }

        .as__ma-@{name}-17,
        .as__my-@{name}-17,
        .as__mb-@{name}-17 {
            margin-bottom: 8.5rem !important;
        }

        .as__ma-@{name}-18,
        .as__my-@{name}-18,
        .as__mb-@{name}-18 {
            margin-bottom: 9rem !important;
        }

        .as__ma-@{name}-19,
        .as__my-@{name}-19,
        .as__mb-@{name}-19 {
            margin-bottom: 9.5rem !important;
        }

        .as__ma-@{name}-20,
        .as__my-@{name}-20,
        .as__mb-@{name}-20 {
            margin-bottom: 10rem !important;
        }

        .as__ma-@{name}-26,
        .as__my-@{name}-26,
        .as__mb-@{name}-26 {
            margin-bottom: 13rem !important;
        }

        .as__ma-@{name}-30,
        .as__my-@{name}-30,
        .as__mb-@{name}-30 {
            margin-bottom: 15rem !important;
        }

        .as__ma-@{name}-32,
        .as__my-@{name}-32,
        .as__mb-@{name}-32 {
            margin-bottom: 16rem !important;
        }

        .as__ma-@{name}-36,
        .as__my-@{name}-36,
        .as__mb-@{name}-36 {
            margin-bottom: 18rem !important;
        }

        .as__mt-@{name}-auto,
        .as__ma-@{name}-auto,
        .as__my-@{name}-auto {
            margin-top: auto !important;
        }

        .as__mt-@{name}-0,
        .as__ma-@{name}-0,
        .as__my-@{name}-0 {
            margin-top: 0 !important;
        }

        .as__ma-@{name}-1,
        .as__my-@{name}-1,
        .as__mt-@{name}-1 {
            margin-top: .5rem !important;
        }

        .as__ma-@{name}-2,
        .as__my-@{name}-2,
        .as__mt-@{name}-2 {
            margin-top: 1rem !important;
        }

        .as__ma-@{name}-3,
        .as__my-@{name}-3,
        .as__mt-@{name}-3 {
            margin-top: 1.5rem !important;
        }

        .as__ma-@{name}-4,
        .as__my-@{name}-4,
        .as__mt-@{name}-4 {
            margin-top: 2rem !important;
        }

        .as__ma-@{name}-5,
        .as__my-@{name}-5,
        .as__mt-@{name}-5 {
            margin-top: 2.5rem !important;
        }

        .as__ma-@{name}-6,
        .as__my-@{name}-6,
        .as__mt-@{name}-6 {
            margin-top: 3rem !important;
        }

        .as__ma-@{name}-7,
        .as__my-@{name}-7,
        .as__mt-@{name}-7 {
            margin-top: 3.5rem !important;
        }

        .as__ma-@{name}-8,
        .as__my-@{name}-8,
        .as__mt-@{name}-8 {
            margin-top: 4rem !important;
        }

        .as__ma-@{name}-9,
        .as__my-@{name}-9,
        .as__mt-@{name}-9 {
            margin-top: 4.5rem !important;
        }

        .as__ma-@{name}-10,
        .as__my-@{name}-10,
        .as__mt-@{name}-10 {
            margin-top: 5rem !important;
        }

        .as__ma-@{name}-11,
        .as__my-@{name}-11,
        .as__mt-@{name}-11 {
            margin-top: 5.5rem !important;
        }

        .as__ma-@{name}-12,
        .as__my-@{name}-12,
        .as__mt-@{name}-12 {
            margin-top: 6rem !important;
        }

        .as__ma-@{name}-13,
        .as__my-@{name}-13,
        .as__mt-@{name}-13 {
            margin-top: 6.5rem !important;
        }

        .as__ma-@{name}-14,
        .as__my-@{name}-14,
        .as__mt-@{name}-14 {
            margin-top: 7rem !important;
        }

        .as__ma-@{name}-15,
        .as__my-@{name}-15,
        .as__mt-@{name}-15 {
            margin-top: 7.5rem !important;
        }

        .as__ma-@{name}-16,
        .as__my-@{name}-16,
        .as__mt-@{name}-16 {
            margin-top: 8rem !important;
        }

        .as__ma-@{name}-17,
        .as__my-@{name}-17,
        .as__mt-@{name}-17 {
            margin-top: 8.5rem !important;
        }

        .as__ma-@{name}-18,
        .as__my-@{name}-18,
        .as__mt-@{name}-18 {
            margin-top: 9rem !important;
        }

        .as__ma-@{name}-26,
        .as__my-@{name}-26,
        .as__mt-@{name}-26 {
            margin-top: 13rem !important;
        }

        .as__pr-@{name}-auto,
        .as__pa-@{name}-auto,
        .as__px-@{name}-auto {
            padding-right: auto !important;
        }

        .as__pr-@{name}-0,
        .as__pa-@{name}-0,
        .as__px-@{name}-0 {
            padding-right: 0 !important;
        }

        .as__pa-@{name}-1,
        .as__px-@{name}-1,
        .as__pr-@{name}-1 {
            padding-right: .5rem !important;
        }

        .as__pa-@{name}-2,
        .as__px-@{name}-2,
        .as__pr-@{name}-2 {
            padding-right: 1rem !important;
        }

        .as__pa-@{name}-3,
        .as__px-@{name}-3,
        .as__pr-@{name}-3 {
            padding-right: 1.5rem !important;
        }

        .as__pa-@{name}-4,
        .as__px-@{name}-4,
        .as__pr-@{name}-4 {
            padding-right: 2rem !important;
        }

        .as__pa-@{name}-5,
        .as__px-@{name}-5,
        .as__pr-@{name}-5 {
            padding-right: 2.5rem !important;
        }

        .as__pa-@{name}-6,
        .as__px-@{name}-6,
        .as__pr-@{name}-6 {
            padding-right: 3rem !important;
        }

        .as__pa-@{name}-7,
        .as__px-@{name}-7,
        .as__pr-@{name}-7 {
            padding-right: 3.5rem !important;
        }

        .as__pa-@{name}-8,
        .as__px-@{name}-8,
        .as__pr-@{name}-8 {
            padding-right: 4rem !important;
        }

        .as__pa-@{name}-9,
        .as__px-@{name}-9,
        .as__pr-@{name}-9 {
            padding-right: 4.5rem !important;
        }

        .as__pa-@{name}-10,
        .as__px-@{name}-10,
        .as__pr-@{name}-10 {
            padding-right: 5rem !important;
        }

        .as__pa-@{name}-11,
        .as__px-@{name}-11,
        .as__pr-@{name}-11 {
            padding-right: 5.5rem !important;
        }

        .as__pa-@{name}-12,
        .as__px-@{name}-12,
        .as__pr-@{name}-12 {
            padding-right: 6rem !important;
        }

        .as__pa-@{name}-13,
        .as__px-@{name}-13,
        .as__pr-@{name}-13 {
            padding-right: 6.5rem !important;
        }

        .as__pa-@{name}-14,
        .as__px-@{name}-14,
        .as__pr-@{name}-14 {
            padding-right: 7rem !important;
        }

        .as__pa-@{name}-15,
        .as__px-@{name}-15,
        .as__pr-@{name}-15 {
            padding-right: 7.5rem !important;
        }

        .as__pa-@{name}-16,
        .as__px-@{name}-16,
        .as__pr-@{name}-16 {
            padding-right: 8rem !important;
        }

        .as__pa-@{name}-17,
        .as__px-@{name}-17,
        .as__pr-@{name}-17 {
            padding-right: 8.5rem !important;
        }

        .as__pa-@{name}-18,
        .as__px-@{name}-18,
        .as__pr-@{name}-18 {
            padding-right: 9rem !important;
        }

        .as__pl-@{name}-auto,
        .as__pa-@{name}-auto,
        .as__px-@{name}-auto {
            padding-left: auto !important;
        }

        .as__pl-@{name}-0,
        .as__pa-@{name}-0,
        .as__px-@{name}-0 {
            padding-left: 0 !important;
        }

        .as__pa-@{name}-1,
        .as__px-@{name}-1,
        .as__pl-@{name}-1 {
            padding-left: .5rem !important;
        }

        .as__pa-@{name}-2,
        .as__px-@{name}-2,
        .as__pl-@{name}-2 {
            padding-left: 1rem !important;
        }

        .as__pa-@{name}-3,
        .as__px-@{name}-3,
        .as__pl-@{name}-3 {
            padding-left: 1.5rem !important;
        }

        .as__pa-@{name}-4,
        .as__px-@{name}-4,
        .as__pl-@{name}-4 {
            padding-left: 2rem !important;
        }

        .as__pa-@{name}-5,
        .as__px-@{name}-5,
        .as__pl-@{name}-5 {
            padding-left: 2.5rem !important;
        }

        .as__pa-@{name}-6,
        .as__px-@{name}-6,
        .as__pl-@{name}-6 {
            padding-left: 3rem !important;
        }

        .as__pa-@{name}-7,
        .as__px-@{name}-7,
        .as__pl-@{name}-7 {
            padding-left: 3.5rem !important;
        }

        .as__pa-@{name}-8,
        .as__px-@{name}-8,
        .as__pl-@{name}-8 {
            padding-left: 4rem !important;
        }

        .as__pa-@{name}-9,
        .as__px-@{name}-9,
        .as__pl-@{name}-9 {
            padding-left: 4.5rem !important;
        }

        .as__pa-@{name}-10,
        .as__px-@{name}-10,
        .as__pl-@{name}-10 {
            padding-left: 5rem !important;
        }

        .as__pa-@{name}-11,
        .as__px-@{name}-11,
        .as__pl-@{name}-11 {
            padding-left: 5.5rem !important;
        }

        .as__pa-@{name}-12,
        .as__px-@{name}-12,
        .as__pl-@{name}-12 {
            padding-left: 6rem !important;
        }

        .as__pa-@{name}-13,
        .as__px-@{name}-13,
        .as__pl-@{name}-13 {
            padding-left: 6.5rem !important;
        }

        .as__pa-@{name}-14,
        .as__px-@{name}-14,
        .as__pl-@{name}-14 {
            padding-left: 7rem !important;
        }

        .as__pa-@{name}-15,
        .as__px-@{name}-15,
        .as__pl-@{name}-15 {
            padding-left: 7.5rem !important;
        }

        .as__pa-@{name}-16,
        .as__px-@{name}-16,
        .as__pl-@{name}-16 {
            padding-left: 8rem !important;
        }

        .as__pa-@{name}-17,
        .as__px-@{name}-17,
        .as__pl-@{name}-17 {
            padding-left: 8.5rem !important;
        }

        .as__pa-@{name}-18,
        .as__px-@{name}-18,
        .as__pl-@{name}-18 {
            padding-left: 9rem !important;
        }

        .as__pb-@{name}-auto,
        .as__pa-@{name}-auto,
        .as__px-@{name}-auto {
            padding-bottom: auto !important;
        }

        .as__pb-@{name}-0,
        .as__pa-@{name}-0,
        .as__py-@{name}-0 {
            padding-bottom: 0 !important;
        }

        .as__pa-@{name}-1,
        .as__py-@{name}-1,
        .as__pb-@{name}-1 {
            padding-bottom: .5rem !important;
        }

        .as__pa-@{name}-2,
        .as__py-@{name}-2,
        .as__pb-@{name}-2 {
            padding-bottom: 1rem !important;
        }

        .as__pa-@{name}-3,
        .as__py-@{name}-3,
        .as__pb-@{name}-3 {
            padding-bottom: 1.5rem !important;
        }

        .as__pa-@{name}-4,
        .as__py-@{name}-4,
        .as__pb-@{name}-4 {
            padding-bottom: 2rem !important;
        }

        .as__pa-@{name}-5,
        .as__py-@{name}-5,
        .as__pb-@{name}-5 {
            padding-bottom: 2.5rem !important;
        }

        .as__pa-@{name}-6,
        .as__py-@{name}-6,
        .as__pb-@{name}-6 {
            padding-bottom: 3rem !important;
        }

        .as__pa-@{name}-7,
        .as__py-@{name}-7,
        .as__pb-@{name}-7 {
            padding-bottom: 3.5rem !important;
        }

        .as__pa-@{name}-8,
        .as__py-@{name}-8,
        .as__pb-@{name}-8 {
            padding-bottom: 4rem !important;
        }

        .as__pa-@{name}-9,
        .as__py-@{name}-9,
        .as__pb-@{name}-9 {
            padding-bottom: 4.5rem !important;
        }

        .as__pa-@{name}-10,
        .as__py-@{name}-10,
        .as__pb-@{name}-10 {
            padding-bottom: 5rem !important;
        }

        .as__pa-@{name}-11,
        .as__py-@{name}-11,
        .as__pb-@{name}-11 {
            padding-bottom: 5.5rem !important;
        }

        .as__pa-@{name}-12,
        .as__py-@{name}-12,
        .as__pb-@{name}-12 {
            padding-bottom: 6rem !important;
        }

        .as__pa-@{name}-13,
        .as__py-@{name}-13,
        .as__pb-@{name}-13 {
            padding-bottom: 6.5rem !important;
        }

        .as__pa-@{name}-14,
        .as__py-@{name}-14,
        .as__pb-@{name}-14 {
            padding-bottom: 7rem !important;
        }

        .as__pa-@{name}-15,
        .as__py-@{name}-15,
        .as__pb-@{name}-15 {
            padding-bottom: 7.5rem !important;
        }

        .as__pa-@{name}-16,
        .as__py-@{name}-16,
        .as__pb-@{name}-16 {
            padding-bottom: 8rem !important;
        }

        .as__pa-@{name}-17,
        .as__py-@{name}-17,
        .as__pb-@{name}-17 {
            padding-bottom: 8.5rem !important;
        }

        .as__pa-@{name}-18,
        .as__py-@{name}-18,
        .as__pb-@{name}-18 {
            padding-bottom: 9rem !important;
        }

        .as__pt-@{name}-auto,
        .as__pa-@{name}-auto,
        .as__px-@{name}-auto {
            padding-top: auto !important;
        }

        .as__pt-@{name}-0,
        .as__pa-@{name}-0,
        .as__py-@{name}-0 {
            padding-top: 0 !important;
        }

        .as__pa-@{name}-1,
        .as__py-@{name}-1,
        .as__pt-@{name}-1 {
            padding-top: .5rem !important;
        }

        .as__pa-@{name}-2,
        .as__py-@{name}-2,
        .as__pt-@{name}-2 {
            padding-top: 1rem !important;
        }

        .as__pa-@{name}-3,
        .as__py-@{name}-3,
        .as__pt-@{name}-3 {
            padding-top: 1.5rem !important;
        }

        .as__pa-@{name}-4,
        .as__py-@{name}-4,
        .as__pt-@{name}-4 {
            padding-top: 2rem !important;
        }

        .as__pa-@{name}-5,
        .as__py-@{name}-5,
        .as__pt-@{name}-5 {
            padding-top: 2.5rem !important;
        }

        .as__pa-@{name}-6,
        .as__py-@{name}-6,
        .as__pt-@{name}-6 {
            padding-top: 3rem !important;
        }

        .as__pa-@{name}-7,
        .as__py-@{name}-7,
        .as__pt-@{name}-7 {
            padding-top: 3.5rem !important;
        }

        .as__pa-@{name}-8,
        .as__py-@{name}-8,
        .as__pt-@{name}-8 {
            padding-top: 4rem !important;
        }

        .as__pa-@{name}-9,
        .as__py-@{name}-9,
        .as__pt-@{name}-9 {
            padding-top: 4.5rem !important;
        }

        .as__pa-@{name}-10,
        .as__py-@{name}-10,
        .as__pt-@{name}-10 {
            padding-top: 5rem !important;
        }

        .as__pa-@{name}-11,
        .as__py-@{name}-11,
        .as__pt-@{name}-11 {
            padding-top: 5.5rem !important;
        }

        .as__pa-@{name}-12,
        .as__py-@{name}-12,
        .as__pt-@{name}-12 {
            padding-top: 6rem !important;
        }

        .as__pa-@{name}-13,
        .as__py-@{name}-13,
        .as__pt-@{name}-13 {
            padding-top: 6.5rem !important;
        }

        .as__pa-@{name}-14,
        .as__py-@{name}-14,
        .as__pt-@{name}-14 {
            padding-top: 7rem !important;
        }

        .as__pa-@{name}-15,
        .as__py-@{name}-15,
        .as__pt-@{name}-15 {
            padding-top: 7.5rem !important;
        }

        .as__pa-@{name}-16,
        .as__py-@{name}-16,
        .as__pt-@{name}-16 {
            padding-top: 8rem !important;
        }

        .as__pa-@{name}-17,
        .as__py-@{name}-17,
        .as__pt-@{name}-17 {
            padding-top: 8.5rem !important;
        }

        .as__pa-@{name}-18,
        .as__py-@{name}-18,
        .as__pt-@{name}-18 {
            padding-top: 9rem !important;
        }

        .as__hide-@{name} {
            display: none !important;
        }

        .as__show-@{name} {
            display: block !important;
        }

        .as__show-@{name}--inline {
            display: inline !important;
        }

        .as__show-@{name}--inline-block {
            display: inline-block !important;
        }

        .as__show-@{name}--flex {
            display: flex !important;
        }

        .as__show-@{name}--inline-flex {
            display: inline-flex !important;
        }
    }
}

//create variables
.container-width(@@container-width) {
    max-width: @@container-width;
}

.container-padding(@outer-margin) {
    padding-left: @@outer-margin;
    padding-right: @@outer-margin;
}
