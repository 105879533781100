.dws__m30-checkout-steps {
    overflow: hidden;
    margin-bottom: 3rem;

    @media @media__md--min {
        margin-bottom: 7rem;
    }
}

.dws__m30-checkout-steps__row {
    @media @media__xl--min {
        margin-left: -4.5rem;
        margin-right: -4.5rem;

        .as__col,
        [class*="as__col-"] {
            padding-right: 4.5rem;
            padding-left: 4.5rem;
        }
    }
}

.dws__m30-checkout-steps__item-number {
    font-family: @font-family__secondary;
    font-size: 1.3rem;
    line-height: 1.6rem;
    text-align: center;
    color: @color__gray--light;

    &::before {
        display: block;

        padding-bottom: 1rem;
        border-bottom: 0.3rem solid currentColor;
        margin-bottom: 1rem;

        @media @media__lg--min {
            display: inline;

            padding: 0;
            border: 0;
            margin: 0;
        }
    }


    @media @media__lg--min {
        font-size: 2.4rem;
        line-height: 2.8rem;

        padding-bottom: 1rem;
        border-bottom: 0.3rem solid currentColor;
    }

    &.current {
        color: @color__brand-primary-red;
        font-weight: bold;
    }

    &.checked {
        color: @color__gray--dark;
    }

    &.step-1::before {
        content: '1'
    }

    &.step-2::before {
        content: '2'
    }

    &.step-3::before {
        content: '3'
    }

    @media @media__lg--min {
        &.step-1::before {
            content: '1. '
        }

        &.step-2::before {
            content: '2. '
        }

        &.step-3::before {
            content: '3. '
        }
    }
}

.dws__m30-checkout-steps__item-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    padding: 1.3rem 2rem;

    font-family: @font-family__secondary;
    font-size: 1.3rem;
    line-height: 1.1;
    color: @color__gray--light;

    &.current {
        color: @color__brand-primary-red;
    }
    &.checked {
        color: @color__gray--dark;
    }
}
