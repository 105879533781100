.dws__m75-account-addresses-item .as__icon {
    width: 1.8rem;
    height: 1.8rem;
}

.dws__m75-account-addresses-item .as__icon-edit {
    color: @color__brand-primary-red;
}

.dws__m75-account-addresses-item-action {
    cursor: pointer;
}

.dws__m75-account-addresses-item__heading {
    display: flex;
    margin-right: -2rem;
}

.dws__m75-account-addresses-item__heading::after {
    content: '';
    width: 100%;
    border-bottom: 2px solid #C6C7C8;
    margin-left: 0.5rem;
    margin-bottom: 0.7rem;
}