.dws__m81-text-anchor {
    text-align: center;
}

.dws__m81-text-anchor__headline {
    color: #3F4B59;
    font-size: 4rem;
    font-family: @font-family__secondary;
    font-weight: @font-weight__700;
    line-height: 1;

    @media @media__lg--min {
        font-size: 5rem;
    }
}

.dws__m81-text-anchor__text {
    color: #676767;
}

.dws__m81-text-anchor__link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 8.8rem;
    height: 8.8rem;
    margin: 0 auto;
    background-color: @color__neutral;
    border-radius: 100%;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.16);
    cursor: pointer;
    transition: background-color @transition__duration @transition__timing-function;


    &:hover,
    &:focus {
        background-color: @color__brand-primary-red;

        .color-brand-primary-blue & {
            background-color: @color__brand-primary-blue;
        }

        .color-brand-primary-yellow & {
            background-color: @color__brand-primary-yellow;
        }

        .dws__m81-text-anchor__icon {
            color: @color__neutral;
        }
    }

}

.dws__m81-text-anchor__icon {
    color: @color__brand-primary-red;
    width: 3.6rem;
    height: 1.8rem;
    transition: color @transition__duration @transition__timing-function;

    .color-brand-primary-blue & {
        color: @color__brand-primary-blue;
    }

    .color-brand-primary-yellow & {
        color: @color__brand-primary-yellow;
    }
}