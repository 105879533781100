.dws__m66-navigation-account__heading-link:hover {
    color: @color__brand-primary-red;
    text-decoration: underline;
}

.dws__m66-navigation-account__heading {
    color: @color__brand-primary-red;
    display: flex;
    align-items: center;
}

.dws__m66-navigation-account__block {
    margin-bottom: 3rem;
}

.dws__m66-navigation-account__headline {
    font-weight: @font-weight__700;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid @color__secondary--light;
}

.dws__m66-navigation-account__links {
    list-style: none;
    margin: 0;
    padding: 0;
}

.dws__m66-navigation-account__link {
    display: inline-block;
    margin: 0.9rem 0;

    &:hover,
    &:focus {
        color: @color__brand-primary-red;
    }
}

.dws__navigation-account__btn {
    display: inline-flex;
    align-items: center;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);

    .as__icon {
        width: 1.8rem;
        height: 1.5rem;
        flex-grow: 0;
        position: relative;
        top: -1px;
    }

    @media (max-width: 374px) {
        padding-right: 1rem;
    }
}