.dws__sr4-meta-dropdown{
    width: 19.2rem;
    box-shadow: .3rem .3rem .3rem #00000029;
    border-radius: .5rem;
    background-color: @color__neutral;
}

.dws__sr4-meta-dropdown__links {
    padding: 1.2rem 0 1.7rem 0;
}

.dws__sr4-meta-dropdown__link {
    display: block;
    color: @sr4-meta-dropdown__link__color;
    font-size: @sr4-meta-dropdown__link__font-size;
    line-height: @sr4-meta-dropdown__link__line-height;
    padding: .8rem 1.5rem;
}

.dws__sr4-meta-dropdown__link:hover {
    color: @color__brand-primary-red;
}

.dws__sr4-meta-dropdown__highlight-links,
.dws__sr4-meta-dropdown__action-links{
    background-color: #f8f8f8;
    box-shadow: 0 .3rem .6rem #00000029;
}

.dws__sr4-meta-dropdown__highlight-links {
    margin-bottom: 1rem;
}

.dws__sr4-meta-dropdown__action-links {
    border-bottom: .3rem solid @color__brand-primary-red;
}

.dws__sr4-meta-dropdown__highlight-link {
    display: block;
    position: relative;
    color: @sr4-meta-dropdown__highlight-link__color;
    font-size: @sr4-meta-dropdown__highlight-link__font-size;
    line-height: @sr4-meta-dropdown__highlight-link__line-height;
    text-align: @sr4-meta-dropdown__highlight-link__text-align;
    padding: 1.1rem @sr4-meta-dropdown__highlight-link__padding-right 1.1rem 0;
}

.dws__sr4-meta-dropdown__highlight-link_icon {
    position: absolute;
    right: @sr4-meta-dropdown__link__icon__margin-right;
    top: 1.3rem;
    width: @sr4-meta-dropdown__highlight-link__icon__width;
    height: @sr4-meta-dropdown__highlight-link__icon__height;
}

.dws__sr4-meta-dropdown__action-link {
    display: block;
    position: relative;
    color: @sr4-meta-dropdown__action-link__color;
    font-size: @sr4-meta-dropdown__action-link__font-size;
    line-height: @sr4-meta-dropdown__action-link__line-height;
    text-align: @sr4-meta-dropdown__action-link__text-align;
    padding: 1.1rem @sr4-meta-dropdown__action-link__padding-right 1.1rem 0;
}

.dws__sr4-meta-dropdown__action-link__icon {
    position: absolute;
    right: @sr4-meta-dropdown__link__icon__margin-right;
    top: 1.3rem;
    width: @sr4-meta-dropdown__action-link__icon__width;
    height: @sr4-meta-dropdown__action-link__icon__height;
}

.dws__sr4-meta-dropdown__highlight-link:hover,
.dws__sr4-meta-dropdown__action-link:hover {
    background-color: @color__brand-primary-red;
    box-shadow: inset 0 .3rem .6rem #00000029;
    color: @color__neutral;
}