.dws__m91-generate-invitation-link .dws__m87-flash-message--has-items {
    margin-bottom: 3rem;

    @media @media__lg--min {
        margin-bottom: 4rem;
    }
}

.dws__m91-generate-invitation-link__content {
    background-color: #fff;
    box-shadow: 3px 3px 6px #00000029, -3px -3px 6px #CFCFCF29;
    border-radius: 5px;
}

.dws__m91-generate-invitation-link__product-toggle:checked ~ .as__checkbox-group .dws__m91-generate-invitation-link__product-toggle-label::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6Izg5MjMzNzt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI5MiAtNDE2LjA2MykiPjxwYXRoIGNsYXNzPSJhIiBkPSJNMjk2LjEyNSw0MjUuMDYyYS45OS45OSwwLDAsMS0uNzA3LS4zbC0zLjEyNS0zLjE2OWExLjAyNCwxLjAyNCwwLDAsMSwwLTEuNDM1Ljk5MS45OTEsMCwwLDEsMS40MTQsMGwyLjQxOCwyLjQ1Miw2LjE2OC02LjI1NWEuOTkxLjk5MSwwLDAsMSwxLjQxNCwwLDEuMDI0LDEuMDI0LDAsMCwxLDAsMS40MzVsLTYuODc1LDYuOTcxQS45OS45OSwwLDAsMSwyOTYuMTI1LDQyNS4wNjJaIi8+PC9nPjwvc3ZnPg==");
    border-color: @checkbox__label__border-color--checked;
}

.dws__m91-generate-invitation-link__product-form-content {
    display: none;
}

.dws__m91-generate-invitation-link__product-toggle:checked ~ .dws__m91-generate-invitation-link__product-form .dws__m91-generate-invitation-link__product-form-content {
    display: block;
}
