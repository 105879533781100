.m55-learning-content {
    margin-bottom: 3rem;
}

.m55-learning-content__item__headline {
    font-size: 1.8rem;
    line-height: 2.4rem;
    padding-right: 2.5rem;

    @media @media__lg--min {
        font-size: 2.1rem;
        line-height: 2.6rem;
    }
}

.m55-learning-content__item__link {
    line-height: 2.1rem;
    text-decoration: none;
    position: relative;
    top: 2px;
}

.m55-learning-content__box {
    border-radius: 5px;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.16),
                -3px -3px 3px rgba(225,225,225,0.16);
}

.m55-learning-content__box__content {
    padding: 2rem 2rem 2.5rem 2rem;
}

.m55-learning-content__box__media {
    border-radius: 5px;
    overflow: hidden;
}

.m55-learning-content__box__topline {
    color: @color__brand-primary-red;
    margin-bottom: 0.3rem;

    .color-brand-primary-yellow & {
        color: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue & {
        color: @color__brand-primary-blue;
    }
}

.m55-learning-content__box__topline-type {
    flex-shrink: 0;
}

.m55-learning-content__box__headline {
    font-weight: @font-weight__700;
    line-height: 2.1rem;
}

.m55-learning-content {

    .dws__video__media-container::before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 10;
        background: rgba(88,88,90,0.55);
    }

    .dws__video--loaded .dws__video__media-container::before {
        content: none;
    }

    .dws__progress {
        margin-bottom: 0.7rem;
        flex: 1 0 100%;
    }
}

