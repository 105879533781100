.as__rte {
    p {
        .as__text();
    }

    b, strong {
        .as__strong();
    }

    a {
        .as__link();
    }

    ul {
        .as__list();
    }

    ul li {
        .as__list-item();
    }

    ol {
        .as__list();
        .as__list--ordered();
    }

    ol li {
        .as__list--ordered .as__list-item();
    }

    h1 {
        &:extend(.as__h1);
    }

    h2 {
        &:extend(.as__h2);
    }

    h3 {
        &:extend(.as__h3);
    }

    h4 {
        &:extend(.as__h4);
    }

    h5 {
        &:extend(.as__h5);
    }

    h6 {
        &:extend(.as__h6);
    }

    small {
        &:extend(.as__text-small);
    }
}
