.dws__m9-list {
    @media @media__lg--min {
        position: relative;
        background: #F8F8F8;
    }
}

.dws__m9-list__background {
    position: absolute;
    top: 0;
    bottom: 0;
    right: @half-gutter-width - @outer-margin--xs;
    left: @half-gutter-width - @outer-margin--xs;

    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;

    box-shadow: 3px 0px 6px #00000029;
    background-color: @color__brand-primary-red;

    pointer-events: none;
    z-index: 2;

    @media @media__sm--min {
        right: @half-gutter-width - @outer-margin--sm;
        left: @half-gutter-width - @outer-margin--sm;
    }

    @media @media__md--min {
        right: @half-gutter-width - @outer-margin--md;
        left: @half-gutter-width - @outer-margin--md;
    }

    @media @media__lg--min {
        right: 0;
        left: 0;
    }

    .color-brand-primary-yellow & {
        background-color: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue & {
        background-color: @color__brand-primary-blue;
    }
}

.dws__m9-list__textbanner,
.dws__m9-list__items-wrapper {
    position: relative;

    &::before {
        content: '';

        position: absolute;
        bottom: 0;
        right: @half-gutter-width - @outer-margin--xs;
        left: @half-gutter-width - @outer-margin--xs;

        height: 50%;

        background: #F8F8F8;
        pointer-events: none;
        z-index: 1;

        @media @media__sm--min {
            right: @half-gutter-width - @outer-margin--sm;
            left: @half-gutter-width - @outer-margin--sm;
        }

        @media @media__md--min {
            right: @half-gutter-width - @outer-margin--md;
            left: @half-gutter-width - @outer-margin--md;
        }

        @media @media__lg--min {
            display: none;
        }

        @media @media__xl--min {
            right: @half-gutter-width - @outer-margin--xl;
            left: @half-gutter-width - @outer-margin--xl;
        }
    }
}

.dws__m9-list .dws__m9-list__textbanner-text {
    position: relative;

    font-family: @font-family__secondary;
    color: @color__neutral;
    font-size: 2.8rem;
    line-height: 3.4rem;
    font-weight: @font-weight__600;

    z-index: 3;

    @media @media__lg--min {
        font-size: 3.4rem;
        line-height: 4.0rem;
    }
}

.dws__m9-list__items-wrapper {
    &::before {
        height: auto;
        top: 0;
    }
}

.dws__m9-list .dws__m9-list__items {
    position: relative;
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: -@m9-list__item__margin-bottom;

    z-index: 2;

    .as__row& {
        margin-left: @gutter-compensation;
        margin-right: @gutter-compensation;
    }
}

.dws__m9-list .dws__m9-list__item {
    margin-bottom: @m9-list__item__margin-bottom;

    &::before {
        display: none;
    }
}

.dws__m9-list__item-inner {
    font-size: 2.1rem;
    line-height: 2.4rem;
    font-weight: @font-weight__700;
    color: @color__secondary--dark;
    text-decoration: none;

    @media @media__lg--min {
        font-size: 2.8rem;
        line-height: 3.2rem;
    }

    &::after {
        content: '';

        width: 5rem;
        flex: 0 0 auto;
        align-self: flex-start;

        border-top: solid @color__brand-primary-red 1px;

        order: 2;
    }

    .color-brand-primary-yellow &::after {
        border-color: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue &::after {
        border-color: @color__brand-primary-blue;
    }
}

.dws__m9-list__item-icon {
    flex: 0 0 auto;
    width: 3.9rem;
    height: 3.9rem;
    align-self: baseline;

    padding: .7rem;
    border-radius: 9999px;

    color: @color__neutral;
    background-color: @color__brand-primary-red;

    transform: rotate(-90deg);

    order: 1;

    .color-brand-primary-yellow & {
        background-color: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue & {
        background-color: @color__brand-primary-blue;
    }
}

.dws__m9-list__item-text {
    order: 3;
}
