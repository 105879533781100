// region Roboto
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 1, 2021 */
@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/roboto-black-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-black-webfont.woff') format('woff');
    font-weight: @font-weight__900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/roboto-blackitalic-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-blackitalic-webfont.woff') format('woff');
    font-weight: @font-weight__900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/roboto-bold-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-bold-webfont.woff') format('woff');
    font-weight: @font-weight__700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/roboto-bolditalic-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-bolditalic-webfont.woff') format('woff');
    font-weight: @font-weight__700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/roboto-italic-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-italic-webfont.woff') format('woff');
    font-weight: @font-weight__400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/roboto-light-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-light-webfont.woff') format('woff');
    font-weight: @font-weight__300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/roboto-lightitalic-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-lightitalic-webfont.woff') format('woff');
    font-weight: @font-weight__300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/roboto-medium-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-medium-webfont.woff') format('woff');
    font-weight: @font-weight__500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/roboto-mediumitalic-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-mediumitalic-webfont.woff') format('woff');
    font-weight: @font-weight__500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/roboto-regular-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-regular-webfont.woff') format('woff');
    font-weight: @font-weight__400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/roboto-thin-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-thin-webfont.woff') format('woff');
    font-weight: @font-weight__100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/roboto/roboto-thinitalic-webfont.woff2') format('woff2'),
         url('../../fonts/roboto/roboto-thinitalic-webfont.woff') format('woff');
    font-weight: @font-weight__100;
    font-style: italic;

}
// endregion Roboto

// region Barlow
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 1, 2021 */
@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-black-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-black-webfont.woff') format('woff');
    font-weight: @font-weight__900;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-blackitalic-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-blackitalic-webfont.woff') format('woff');
    font-weight: @font-weight__900;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-bold-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-bold-webfont.woff') format('woff');
    font-weight: @font-weight__700;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-bolditalic-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-bolditalic-webfont.woff') format('woff');
    font-weight: @font-weight__700;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-extrabold-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-extrabold-webfont.woff') format('woff');
    font-weight: @font-weight__800;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-extrabolditalic-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-extrabolditalic-webfont.woff') format('woff');
    font-weight: @font-weight__800;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-extralight-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-extralight-webfont.woff') format('woff');
    font-weight: @font-weight__200;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-extralightitalic-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-extralightitalic-webfont.woff') format('woff');
    font-weight: @font-weight__200;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-italic-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-italic-webfont.woff') format('woff');
    font-weight: @font-weight__400;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-light-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-light-webfont.woff') format('woff');
    font-weight: @font-weight__300;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-lightitalic-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-lightitalic-webfont.woff') format('woff');
    font-weight: @font-weight__300;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-medium-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-medium-webfont.woff') format('woff');
    font-weight: @font-weight__500;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-thinitalic-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-thinitalic-webfont.woff') format('woff');
    font-weight: @font-weight__100;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-thin-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-thin-webfont.woff') format('woff');
    font-weight: @font-weight__100;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-semibolditalic-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-semibolditalic-webfont.woff') format('woff');
    font-weight: @font-weight__600;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-semibold-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-semibold-webfont.woff') format('woff');
    font-weight: @font-weight__600;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-regular-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-regular-webfont.woff') format('woff');
    font-weight: @font-weight__400;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('../../fonts/barlow/barlow-mediumitalic-webfont.woff2') format('woff2'),
         url('../../fonts/barlow/barlow-mediumitalic-webfont.woff') format('woff');
    font-weight: @font-weight__500;
    font-style: italic;
}
// endregion Barlow
