.dws__m22-references__item {
    display: flex;
    justify-content: flex-start;
    padding: 1rem;
    height: 100%;
    position: relative;
    border-radius: 5px;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.16),
                -3px -3px 3px rgba(225,225,225,0.16);

    @media @media__sm--min {
        padding: 2rem;
    }

    &::before {
        content: '';
        height: 4px;
        display: block;
        position: absolute;
        top: 0;
        width: 0;
        background-color: @color__brand-primary-red;
        left: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        transition: width @transition__duration @transition__timing-function;
    }

    .dws__m22-references__link:hover &,
    .dws__m22-references__link:focus & {
        &::before,
        &::before {
            width: 100%;
        }
    }
}



.dws__m22-references__item-image {
    width: 7rem;
    height: 7rem;
    flex: 0 0 7rem;
    margin-right: 2rem;

    @media @media__sm--min {
        width: 8rem;
        height: 8rem;
        flex: 0 0 8rem;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    &.dws__m22-references__item-image--avatar img {
        border-radius: 100%;
    }

    &.dws__m22-references__item-image--rounded img {
        border-radius: 6px;
    }
}

.dws__m22-references__item-text {
    color: @color__brand-primary-red;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: @font-weight__700;
    word-wrap: anywhere;

    @media @media__sm--min {
        font-size: 1.8rem;
    }
}