// REGION shipping-info
.dws__shipping-info {
    position: relative;
    padding-left: 1.2rem + .8rem;

    &::before {
        width: 1.2rem;
        height: 1.2rem;
        position: absolute;
        left: 0;
        content: '';
        border-radius: 50%;
    }

    &--red {
        &::before {
            background-color: @color__error;
        }
    }

    &--yellow {
        &::before {
            background-color: @color__orange;
        }
    }

    &--green {
        &::before {
            background-color: @color__success;
        }
    }
}
// END REGION

.dws__shipping-info.as__text-small {
    top: (@text-small__line-height--xs - @text-small__font-size--xs) / 2;

    &::before {
        @media @media__sm--min {
            top: (@text-small__line-height--sm - @text-small__font-size--sm) / 2;
        }

        @media @media__md--min {
            top: (@text-small__line-height--md - @text-small__font-size--md) / 2;
        }

        @media @media__lg--min {
            top: (@text-small__line-height--lg - @text-small__font-size--lg) / 2;
        }
    }
}

.dws__shipping-info.as__text-smaller {
    top: (@text-smaller__line-height--xs - @text-smaller__font-size--xs) / 2;

    &::before {
        @media @media__sm--min {
            top: (@text-smaller__line-height--sm - @text-smaller__font-size--sm) / 2;
        }

        @media @media__md--min {
            top: (@text-smaller__line-height--md - @text-smaller__font-size--md) / 2;
        }

        @media @media__lg--min {
            top: (@text-smaller__line-height--lg - @text-smaller__font-size--lg) / 2;
        }
    }
}

.dws__shipping-info.as__text-smallest {
    top: (@text-smallest__line-height--xs - @text-smallest__font-size--xs) / 2;

    &::before {
        @media @media__sm--min {
            top: (@text-smallest__line-height--sm - @text-smallest__font-size--sm) / 2;
        }

        @media @media__md--min {
            top: (@text-smallest__line-height--md - @text-smallest__font-size--md) / 2;
        }

        @media @media__lg--min {
            top: (@text-smallest__line-height--lg - @text-smallest__font-size--lg) / 2;
        }
    }
}
