// region slider
.dws__m3-product-teaser-slider {
    position: relative;
}

.dws__m3-product-teaser-slider__arrow {
    position: absolute;
    top: calc((@m3-product-teaser-slider__item-image__height--xs / 2) - (@m3-product-teaser-slider__arrow__height / 2));
    color: @color__secondary--dark;
    height: @m3-product-teaser-slider__arrow__height;
    width: 1rem;
    line-height: 0;
}

.dws__m3-product-teaser-slider__arrow--prev {
    left: @half-gutter-width;
    transform: scaleX(-1);
}

.dws__m3-product-teaser-slider__arrow--next {
    right: @half-gutter-width;
}

.dws__m3-product-teaser-slider__arrow .as__icon {
    width: 100%;
    height: 100%;
}

.dws__m3-product-teaser-slider__nav--mobile {
    position: absolute;
    top: calc(@m3-product-teaser-slider__item-image__height--xs + 3rem);
    left: 0;
    right: 0;
    height: @m3-product-teaser-slider__nav--mobile__height;
    line-height: 0;

    @media @media__md--min {
        top: calc(@m3-product-teaser-slider__item-image__height--md + 3rem);
    }
}

.dws__m3-product-teaser-slider__nav--mobile .dws__m3-product-teaser-slider__nav-item {
    display: inline-block;
    width: 3rem;
    height: @m3-product-teaser-slider__nav--mobile__height;
    background: @color__secondary--light;
    margin-right: 3rem;
    vertical-align: top;

    &:last-child {
        margin-right: 0;
    }

    &.dws__m3-product-teaser-slider__nav-item--active {
        background: @color__brand-primary-red--dark;
    }
}
// endregion

// region items
.dws__m3-product-teaser-slider__container {
    margin-bottom: 6rem;
}

.dws__m3-product-teaser-slider__item-image {
    height: @m3-product-teaser-slider__item-image__height--xs;
    margin: 0 2rem 7.3rem 2rem;

    @media @media__md--min {
        height: @m3-product-teaser-slider__item-image__height--md;
        max-width: @m3-product-teaser-slider__item-image__max-width--md;
        flex: 0 0 @m3-product-teaser-slider__item-image__max-width--md;
        margin: 0;
    }

    @media @media__lg--min {
        height: @m3-product-teaser-slider__item-image__height--lg;
        max-width: @m3-product-teaser-slider__item-image__max-width--lg;
        flex: 0 0 @m3-product-teaser-slider__item-image__max-width--lg;
    }
}

.dws__m3-product-teaser-slider__item-image img {
    display: block;

    width: auto;
    height: auto;
    max-width: 100%;
    max-height: @m3-product-teaser-slider__item-image__height--xs;

    @media @media__md--min {
        max-height: @m3-product-teaser-slider__item-image__height--md;
    }
}

.dws__m3-product-teaser-slider__item-content {
    @media @media__md--min {
        padding: 4rem 3rem 0 4rem;
    }
}

.dws__m3-product-teaser-slider__item-category {
    font-family: @font-family__secondary;
    text-transform: uppercase;
    color: @color__brand-primary-red--dark;
    margin-bottom: 1.5rem;
}

.dws__m3-product-teaser-slider__item-headline {
    margin-bottom: 1.5rem;
    line-height: 3.4rem;
}

.dws__m3-product-teaser-slider__item-version {
    font-size: 1.4rem;
    line-height: 2.6rem;
    color: @color__secondary--regular;
    margin-bottom: 3rem;

    @media @media__md--min {
        margin-bottom: 1rem;
    }
}

.dws__m3-product-teaser-slider__item-text {
    margin-bottom: 4rem;
}

.dws__m3-product-teaser-slider__container .as__btn {
    padding: 1rem 1.8rem;
    margin-bottom: 2.5rem;
    width: 100%;

    &:last-of-type {
        margin-bottom: 0;
    }

    @media @media__sm--min {
        padding: @btn__padding;
        margin-bottom: 0;
        width: auto;
    }

    & + .as__btn {
        @media @media__sm--min {
            margin-left: 2.3rem;
        }

        @media @media__md--min {
            margin-left: 1.5rem;
        }
    }
}

// endregion

// region nav

.dws__m3-product-teaser-slider__nav {
    overflow-x: hidden;
}

.dws__m3-product-teaser-slider__nav__container {
    @media @media__md--min {
        width: 137.7%;
    }

    @media @media__lg--min {
        width: auto;
    }
}

.dws__m3-product-teaser-slider__nav-item {
    cursor: pointer;
}

.dws__m3-product-teaser-slider__nav-item__divider {
    height: .5rem;
    background: @color__secondary--dark;
    margin-bottom: 2rem;

    .dws__m3-product-teaser-slider__nav-item--active &,
    .dws__m3-product-teaser-slider__nav-item:hover & {
        background: @color__brand-primary-red--dark;
    }
}

.dws__m3-product-teaser-slider__nav-item .as__h3 {
    margin-bottom: 1.5rem;
}

.dws__m3-product-teaser-slider__nav-item--active .as__h3,
.dws__m3-product-teaser-slider__nav-item:hover .as__h3 {
    color: @color__brand-primary-red--dark;
}

.dws__m3-product-teaser-slider__nav-item__category {
    line-height: 2.2rem;
    color: @color__secondary--regular;
}

// endregion

.dws__m3-product-teaser-slider__item-image .pimcore_editable_image {
    width: auto !important;
    max-height: 100%;
}

.dws__m3-product-teaser-slider__item-image .pimcore_editable_image_empty {
    width: 100% !important;
    height: 100%;
}
