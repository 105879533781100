.dws__m54b-accordion__status-- {
    &completed {
        color: @color__brand-secondary-green;
    }

    &notStarted {
        color: @color__secondary--regular;
    }

    &inProgress {
        color: @color__brand-secondary-orange;
    }
}
