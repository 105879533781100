.dws__m33-payment-methods {
    background-color: @color__neutral;
    margin: 0 -@outer-margin--xs 6rem;
    box-shadow: 3px 3px 6px #00000029, -3px -3px 6px #CFCFCF29;
    border-radius: 5px;
    padding: 2.5rem @outer-margin--xs;

    @media @media__sm--min {
        margin-left: -@outer-margin--sm;
        margin-right: -@outer-margin--sm;
        padding-left: @outer-margin--sm;
        padding-right: @outer-margin--sm;
    }

    @media @media__md--min {
        margin: 0;
        padding: 2rem;
    }
}

.dws__m33-payment-methods .dws__m33-payment-methods__label {
    font-size: 1.8rem;
    line-height: 2.4rem;

    &:focus,
    &:hover {
        color: @color__brand-primary-red;
    }

    @media @media__lg--min {
        font-size: 2.1rem;
        line-height: 2.6rem;
    }
}

.dws__m33-payment-methods .dws__m33-payment-methods__toggle:first-child + .as__accordion__item {
    border-top: none;
}

.dws__m33-payment-methods__toggle + .as__accordion__item .dws__m33-payment-methods__label::before {
    width: 1.2rem;
    height: 1.2rem;
    top: 2.5rem + ((2.4rem - 2rem) / 2);

    @media @media__md--min {
        top: 3rem + ((2.6rem - 2rem) / 2);
    }
}

.dws__m33-payment-methods__toggle:checked + .as__accordion__item .dws__m33-payment-methods__label::before {
    background-color: @color__brand-primary-red;
    border-color: @color__brand-primary-red;
}

.dws__m33-payment-methods__info-text {
    font-size: 1.4rem;
    color: @color__secondary--regular;
}
