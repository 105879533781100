.dws__m46-lms-details {
    border-radius: 5px;
    overflow: hidden;
}

.dws__m46-lms-details .dws__video__media-container::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background: fade(@color__secondary--regular, 45%);

    pointer-events: none;
    z-index: 1;
}

.dws__m46-lms-details .dws__video--loaded .dws__video__media-container::before {
    display: none;
}

// Override reactive property of video component
[class*=color-brand-] .dws__m46-lms-details .dws__video__preview-playBtn {
    color: @color__neutral;
}

// Set reactive property of video component on hover/focus/"hover"-state
.dws__m46-lms-details .dws__video--hover .dws__video__preview-playBtn,
.dws__m46-lms-details .dws__video__preview-playBtn:hover,
.dws__m46-lms-details .dws__video__preview-playBtn:focus {
    .color-brand-primary-red & {
        color: @color__brand-primary-red;
    }

    .color-brand-primary-blue & {
        color: @color__brand-primary-blue;
    }

    .color-brand-primary-yellow & {
        color: @color__brand-primary-yellow;
    }
}

// Sync hover of icon with video btn
.dws__m46-lms-details .dws__video--hover .dws__btn--video {
    color: @color__neutral;
    border-color: @color__brand-primary-blue;
    background-color: @color__brand-primary-blue;
}
