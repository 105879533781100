@import "config.less";

.as__cookie-banner {
    display: none;

    position: fixed;
    left: 0;
    bottom: 0;

    width: 100%;
    height: auto;
    max-height: @cookie-banner__max-height;

    padding: 2rem @outer-margin--xs;
    border-top: @cookie-banner__border;
    margin-left: auto;
    margin-right: auto;

    background-color: @cookie-banner__background;
    overflow: hidden;
    -ms-overflow-y: auto;
    overflow-y: auto;
    z-index: @cookie-banner__z-index;

    @media @media__sm--min {
        padding-left: @outer-margin--sm;
        padding-right: @outer-margin--sm;
    }

    @media @media__md--min {
        padding-left: @outer-margin--md;
        padding-right: @outer-margin--md;
    }

    @media @media__lg--min {
        padding-left: @outer-margin--lg;
        padding-right: @outer-margin--lg;
    }

    &__btn {
        &-details {
            position: relative;
            top: 0;
            left: 0;
            right: .8rem;
            height: 100%;

            &__icon {
                display: inline-block;
                width: .8rem;
                height: .8rem;

                position: absolute;
                right: 1rem;
                top: -.4rem;
                bottom: 0;

                margin: auto;

                -webkit-transform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -ms-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
                transform: rotate(-135deg);

                z-index: 1;

                &::before {
                    content: '';

                    display: block;
                    width: 100%;
                    height: 100%;

                    position: absolute;
                    top: 0;
                    left: 0;

                    border-top: 2px solid currentColor;
                    border-left: 2px solid currentColor;

                    z-index: 1;
                }
            }

            &--active &__icon {
                top: .6rem;

                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }

    &__submit--all {
        margin-bottom: @cookie-banner__button__margin-bottom;
    }

    &__table {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-direction: row;
        flex-direction: row;

        -ms-flex-pack: justify;
        justify-content: space-between;

        margin-bottom: 1rem;

        @media @media__md--min {
            max-width: 72rem;
            margin-right: auto;
        }

        &__head,
        &__content {
            padding: 0.5rem 1rem;
            border: @cookie-banner__border;
        }

        &__head {
            width: 41.666667%;

            color: @color__white;
            background: @cookie-banner__table__background;

            @media @media__sm--min {
                width: 33.333333%;
            }
        }

        &__content {
            width: 58.333333%;

            font-size: 80%;
            background: @color__white;

            @media @media__sm--min {
                width: 66.666667%;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__form {
        &__list {
            padding-left: 0;

            &__item {
                display: inline-block;
                margin-right: 1rem;

                &--not-changeable {
                    pointer-events: none;
                    opacity: .5;
                }
            }
        }
    }

    &__details {
        display: none;
        margin-top: 3rem;

        &__item {
            margin-bottom: 2rem;
            padding: 1rem;

            background: @color__secondary--lighter;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__cookies {
            display: none;
            padding-top: 1rem;

            &__name {
                word-break: break-all;

                @media @media__sm--min {
                    word-break: normal;
                }
            }

            &--active {
                display: block;
            }
        }

        &--active {
            display: block;
        }
    }

    &--active {
        display: block;
    }

    &__toggle {
        padding-right: 3.5rem;
    }
}
