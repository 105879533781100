.dws__m19-pagination {
    border-top: 1px solid @color__secondary--light;
}

.dws__m19-pagination__list {
    list-style: none;

    .as__row& {
        flex-wrap: nowrap;
        margin-left: -.5rem;
        margin-right: -.5rem;

        @media @media__lg--min {
            margin-left: -1rem;
            margin-right: -1rem;
        }

        .as__col,
        [class*=as__col-] {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.dws__m19-pagination__btn {
    padding: 1rem 0.5rem;
    margin: 0 0.4rem;

    @media (min-width: @breakpoint--lg) {
        margin: 0 1rem;
    }
}

.dws__m19-pagination__btn-icon {
    display: block;

    width: 1.4rem;
    height: 1.4rem;

    transform: rotate(-90deg);
}

.dws__m19-pagination__btn--prev .dws__m19-pagination__btn-icon {
    transform: rotate(90deg);
}

.dws__m19-pagination__list-link {
    font-family: @font-family__secondary;
    font-size: 2rem;
    line-height: 1em;
    color: @color__brand-primary-red;
    display: inline-block;
    padding: 0.2rem 1.2rem 0.2rem 1.1rem;

    & .dws__m19-pagination__list-link-label {
        transition: border-color 300ms ease;
        border-bottom: 2px solid transparent;
    }
}

.dws__m19-pagination__list-item--active .dws__m19-pagination__list-link {
    padding: 0.2rem 0.9rem;
}

.dws__m19-pagination__list-link:hover,
.dws__m19-pagination__list-link:focus,
.dws__m19-pagination__list-item--active .dws__m19-pagination__list-link {
    &:not(.dws__m19-pagination__list-link--no-link) .dws__m19-pagination__list-link-label {
        border-color: currentColor;
    }
}

.dws__m19-pagination__list-item--active .dws__m19-pagination__list-link {
    font-weight: @font-weight__700;
}
