
.dws__product-carousel-tile.as__carousel__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    padding: 0 1rem 2rem;

    background: @color__neutral;
    border-radius: 5px;
    box-shadow: 0 6px 6px #00000029;

    @media @media__lg--min {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.dws__product-carousel-tile__slide-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 100%;
}

.dws__product-carousel-tile__tags {
    margin-bottom: -1rem;
}

.dws__product-carousel-tile__metadata,
.dws__product-carousel-tile__title {
    position: relative;
    padding-bottom: 1rem;

    &::before {
        content: '';

        position: absolute;
        bottom: 0;
        left: 0;

        display: block;
        width: 3rem;

        border-bottom: 2px solid @color__brand-primary-red;

        pointer-events: none;
        z-index: 1;
    }
}

.dws__product-carousel-tile__demo-link {
    text-decoration: none;
}

.dws__product-carousel-tile__images.as__row {
    flex: 1 1 100%;

    margin-left: -1rem;
    margin-right: -1rem;

    .as__col,
    [class*="as__col-"] {
        padding-right: 1rem;
        padding-left: 1rem;
    }

    @media @media__lg--min {
        margin-left: -.5rem;
        margin-right: -.5rem;

        .as__col,
        [class*="as__col-"] {
            padding-right: .5rem;
            padding-left: .5rem;
        }
    }
}

.dws__product-carousel-tile__images {
    margin-bottom: -2rem;

    img {
        max-height: 17rem;
    }

    @media @media__lg--min {
        margin-bottom: -1rem;
    }
}

.dws__product-carousel-tile__image-wrapper {
    display: block;
    margin-bottom: 2rem;

    @media @media__lg--min {
        margin-bottom: 1rem;
    }
}

.dws__product-carousel-tile__image {
    display: block;
    max-width: 100%;
    min-width: 10rem;
    min-height: 10rem;

    @media @media__lg--min {
        min-width: 9rem;
        min-height: 9rem;
    }
}

.dws__product-carousel-tile__price {
    font-family: @font-family__secondary;
    font-weight: @font-weight__600;
    font-size: 2.1rem;
    line-height: 2.7rem;

    @media @media__lg--min {
        font-size: 2.5rem;
        line-height: 3.4rem;
    }

    sup {
        top: -.25em;
    }
}

.dws__product-carousel-tile__popup-container {
    position: relative;
}

.dws__product-carousel-tile__popup-toggle {
    display: none;
}

.dws__product-carousel-tile__popup-wrapper {
    position: absolute;
    top: 100%;
    left: 0;

    width: 100%;

    background: @color__neutral;
    border: 2px solid @color__brand-primary-red;
    border-radius: 6px;
    box-shadow: 3px 3px 6px #00000029;

    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    transition: 300ms ease opacity,
                300ms ease visibility;

    z-index: 1;
}

.dws__product-carousel-tile__popup-trigger[aria-expanded="true"] ~ .dws__product-carousel-tile__popup-wrapper {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.dws__product-carousel-tile__popup-content-rte {
    .as__rte();         // Hack to workaround compiling order (.as__rte compiled later)

    a {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
