.dws__tab {
    font-size: @dws__tab__font-size--xs;
    font-family: @font-family__secondary;
    line-height: @dws__tab__line-height--xs;
    font-weight: @font-weight__500;
    border: 2px solid @color__brand-primary-red;
    border-radius: @dws__tab__border-radius;
    padding: @dws__tab__padding--xs;
    background-color: @color__neutral;
    height: @dws__tab__height--xs;
    display: flex;
    align-items: center;
    cursor: pointer;

    transition: background-color @transition__duration @transition__timing-function;

    @media @media__lg--min {
        font-size: @dws__tab__font-size--lg;
        font-family: @font-family__primary;
        font-weight: @font-weight__400;
        line-height: @dws__tab__line-height--lg;
        //min-width: @dws__tab__min-width--lg;
        padding: @dws__tab__padding--lg;
        height: auto;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
        text-align: center;
        justify-content: center;
    }

    @media @media__xl--min {
        //min-width: @dws__tab__min-width--xl;
    }

    &.dws__tab--blue {
        border-color: @color__brand-primary-blue;
    }

    &.dws__tab--yellow {
        border-color: @color__brand-primary-yellow;
    }

    &.dws__tab--gray {
        border-color: @color__secondary--regular;
    }

    &.dws__tab--active,
    input[type="radio"].dws__tab__toggle:checked + & {
        color: @color__neutral;
        height: @dws__tab__height--lg;
        font-size: @dws__tab__font-size--lg;
        position: relative;

        background-color: @color__brand-primary-red;

        @media @media__lg--min {
            color: @color__brand-primary-red;
            font-weight: @font-weight__500;
            height: auto;
            padding: @dws__tab--active__padding--lg;
            background-color: @color__neutral;
            text-transform: uppercase;
        }

        &.dws__tab--blue {
            background-color: @color__brand-primary-blue;

            @media @media__lg--min {
                background-color: @color__neutral;
            }
        }

        &.dws__tab--yellow {
            background-color: @color__brand-primary-yellow;

            @media @media__lg--min {
                background-color: @color__neutral;
            }
        }

        &.dws__tab--gray {
            background-color: @color__secondary--regular;

            @media @media__lg--min {
                background-color: @color__neutral;
            }
        }
    }
}

.dws__tab.dws__tab--active,
input[type="radio"].dws__tab__toggle:checked + .dws__tab {

    @media @media__lg--min {
        border-radius: 0;

        &::after,
        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            width: @dws__tab__beforeAfter-width;
            top: -1px;
            height: ~"calc(100% + 1px)";
            /* bottom: 0; */
            z-index: 1;
            border-top: 1px solid @color__brand-primary-red;
            background-color: white;

        }

        &::after {
            left: 100%;
            border-right: 2px solid @color__brand-primary-red;
            box-shadow: 3px 3px 3px rgba(0,0,0,0.16);
            border-top-right-radius: @dws__tab__border-radius;
        }

        &::before {
            left: -@dws__tab__beforeAfter-width;
            border-top-left-radius: @dws__tab__border-radius;
            border-left: 2px solid @color__brand-primary-red;
        }

        &.dws__tab--blue {
            color: @color__brand-primary-blue;

            &::after {
                left: 100%;
                border-right-color: @color__brand-primary-blue;
                border-top-color: @color__brand-primary-blue;
            }

            &::before {
                border-left-color: @color__brand-primary-blue;
                border-top-color: @color__brand-primary-blue;
            }
        }

        &.dws__tab--yellow {
            color: @color__brand-primary-yellow;

            &::after {
                left: 100%;
                border-right-color: @color__brand-primary-yellow;
                border-top-color: @color__brand-primary-yellow;
            }

            &::before {
                border-left-color: @color__brand-primary-yellow;
                border-top-color: @color__brand-primary-yellow;
            }
        }

        &.dws__tab--gray {
            color: @color__secondary--regular;

            &::after {
                left: 100%;
                border-right-color: @color__secondary--regular;
                border-top-color: @color__secondary--regular;
            }

            &::before {
                border-left-color: @color__secondary--regular;
                border-top-color: @color__secondary--regular;
            }
        }
    }
}

.dws__tab.dws__tab {
    &:first-child.dws__tab--active {
        border-top-left-radius: @dws__tab__border-radius;

        &::before {
            content: none;
        }
    }

    &:last-child.dws__tab--active {
        border-top-right-radius: @dws__tab__border-radius;

        &::after {
            content: none;
        }
    }
}


