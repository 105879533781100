.dws__m63a-favourite-list-factsheet {
    position: relative;

    &::before {
        content: '';
        display: block;

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        height: 100%;

        background: ~'url(@{image__path}/favourite_factsheets_wave_yellow.svg)' no-repeat bottom left;

        margin-bottom: 5.3rem;              // Slider-nav height + 1rem spacing

        pointer-events: none;
        z-index: 1;

        @media @media__lg--min {
            margin-bottom: 0;
        }

        @media (min-width: 1921px) {
            background-size: 100%;
            background-position: center;
        }
    }
}

.dws__m63a-favourite-list-factsheet__header {
    position: relative;
    z-index: 2;
}

.dws__m63a-favourite-list-factsheet__headline {
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dws__m63a-favourite-list-factsheet__slider {
    position: relative;

    margin-right: auto;
    margin-left: auto;

    padding-left: .5rem;
    padding-right: .5rem;

    z-index: 2;

    @media @media__lg--min {
        max-width: @max-container-width--lg;
        padding-right: @outer-margin--lg;
        padding-left: @outer-margin--lg;
    }
}

.dws__m63a-favourite-list-factsheet__slider-container {
    margin-left: -.5rem;
    margin-right: -.5rem;

    .as__col,
    [class*="as__col-"] {
        padding-right: .5rem;
        padding-left: .5rem;
    }

    @media @media__lg--min {
        margin-left: -1.5rem;
        margin-right: -1.5rem;

        .as__col,
        [class*="as__col-"] {
            padding-right: 1.5rem;
            padding-left: 1.5rem;
        }
    }
}

.dws__m63a-favourite-list-factsheet__slide {
    padding-bottom: 1rem;

    @media @media__lg--min {
        transform: none !important;
        position: static !important;
    }

    &.as__slider__infinite-clone {
        @media @media__lg--min {
            display: none;
        }
    }
}

.dws__favourite-list-factsheet__title {
    &::before,
    &::after {
        margin-top: .5rem;
        margin-bottom: .5rem;
        content: "";
        display: block;
        width: 3rem;
        height: .2rem;
        background-color: @color__brand-primary-red;
    }
}

.dws__m63a-favourite-list-factsheet__download-btn {
    color: @color__brand-primary-red;
    font-weight: @font-weight__500;
    cursor: pointer;

    &:hover {
        color: @color__brand-primary-red--dark;
    }

    .as__icon {
        position: relative;
        display: inline-block;
        top: 0.2rem;
        width: 1rem;
        height: 1.7rem;
    }
}

.dws__m63a-favourite-list-factsheet__popup {
    display: flex;
    flex-direction: column;
    border: 2px solid @color__brand-primary-red;
    background-color: @color__neutral;
    border-radius: 0.6rem;
    padding: 2rem 2rem;

    .as__link {
        font-size: 1.4rem;
        text-decoration: none;
        margin-bottom: 2.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.dws__m63a-favourite-list-factsheet {
    .as__btn--notice {
        color: @color__brand-primary-red;
        box-shadow: none;
    }
}


.dws__m63a-favourite-list-factsheet__icon.as__link--icon__icon {
    width: 1rem;
    height: 1.6rem;
}
