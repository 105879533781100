.dws__m53-product-list-reduced__headline {
    font-weight: @font-weight__700;
}

.dws__m53-product-list-reduced__show-more-link {
    text-decoration: none;
}

.dws__m53-product-list-reduced__items {
    margin-bottom: -2rem;
}

.dws__m53-product-list-reduced__item {
    margin-bottom: 2rem;
}

.dws__m53-product-list-reduced__item-inner {
    position: relative;
    display: block;

    border-radius: 5px;

    box-shadow: 3px 3px 3px #00000029;
    overflow: hidden;

    &::before {
        content: '';

        display: block;
        width: 100%;
        height: .5rem;

        position: absolute;
        top: 0;
        left: 0;

        transform: translateX(-100%);
        transition: 300ms ease transform;

        z-index: 1;
        pointer-events: none;

        background-color: @color__brand-primary-red;

        .color-brand-primary-yellow & {
            background-color: @color__brand-primary-yellow;
        }

        .color-brand-primary-blue & {
            background-color: @color__brand-primary-blue;
        }
    }

    &:hover,
    &:focus {
        &::before {
            transform: translateX(0);
        }
    }
}

.dws__m53-product-list-reduced__image {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.dws__m53-product-list-reduced__item-type,
.dws__m53-product-list-reduced__item-type-suffix {
    color: @color__brand-primary-red;

    .color-brand-primary-yellow & {
        color: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue & {
        color: @color__brand-primary-blue;
    }
}

.dws__m53-product-list-reduced__item-title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media @media__md--min {
        -webkit-line-clamp: 2;
    }

    .dws__m53-product-list-reduced__item--has-image & {
        -webkit-line-clamp: 4;

        @media @media__md--min {
            -webkit-line-clamp: 3;
        }
    }
}

.dws__m53-product-list-reduced__item-tags {
    margin-bottom: -1rem;
}

.dws__m53-product-list-reduced__icon {
    color: @color__brand-primary-red;

    .color-brand-primary-yellow & {
        color: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue & {
        color: @color__brand-primary-blue;
    }
}
