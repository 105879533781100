.dws__m84a-learnpaths .dws__m84a-learnpaths__accordion,
.dws__m84a-learnpaths .as__accordion__toggle:first-child + .dws__m84a-learnpaths__accordion,
.dws__m84a-learnpaths .as__accordion__toggle:first-child:checked + .dws__m84a-learnpaths__accordion {
    border: 0;
}

.dws__m84a-learnpaths__accordion {
    margin-bottom: 2rem;

    &:last-child {
        margin-bottom: 0;
    }

    @media @media__md--min {
        padding-left: 10.5rem;
        position: relative;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 3.5rem;
            left: 2rem;
            border-right: 4px solid @color__secondary--light;
            height: calc(100% + 2rem);
            transform: translateX(-50%);

            transition: background-color 300ms ease,
                        opacity 300ms ease,
                        border-color 300ms ease;
        }

        &:last-child {
            &::before {
                display: none;
            }
        }
    }
}

.dws__m84a-learnpaths__accordion--is-currently-available {
    &:not(.dws__m84a-learnpaths__accordion--last-currently-available)::before {
        border-color: @color__brand-primary-blue;
    }
}

.dws__m84a-learnpaths .as__accordion__label {
    display: flex;
    align-items: center;
    min-height: 8rem;

    padding-top: 1.65rem;
    padding-bottom: 1.65rem;

    color: @color__secondary--regular;
    background: @color__neutral;

    border-radius: 5px;
    box-shadow: 3px 3px 6px #00000029;

    @media @media__md--min {
        padding-top: 2.6rem;
        padding-bottom: 2.6rem;
        padding-right: 12.4rem;

        &::before,
        &::after {
            content: '';
            display: block;
            width: 2rem;
            height: 2rem;
            background: @color__secondary--light;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 1rem;
            border-radius: 9999px;
            transform: translateX(calc(-100% - 8.5rem));
            margin: auto;
            z-index: 2;

            transition: background-color 300ms ease,
                        opacity 300ms ease,
                        border-color 300ms ease;
        }

        &::after {
            width: 4rem;
            height: 4rem;
            transform: translateX(calc(-100% - 6.5rem));
            left: 0;
            background: @color__neutral;
            border: 4px solid @color__secondary--light;
            z-index: 1;
            opacity: 0;

            .as__bg-greige & {
                background: @color__background__gray-light;
            }
        }
    }
}

.dws__m84a-learnpaths__accordion--is-currently-available .as__accordion__label,
.dws__m84a-learnpaths__end--is-finished .as__accordion__label {
    color: @color__secondary--dark;

    &::before {
        background: @color__brand-primary-blue;
    }

    &::after {
        border-color: @color__brand-primary-blue;
    }
}

.dws__m84a-learnpaths .as__accordion__toggle:checked + .dws__m84a-learnpaths__accordion .as__accordion__label,
.dws__m84a-learnpaths__end--is-finished .as__accordion__label {
    @media @media__md--min {
        &::after {
            opacity: 1;
        }
    }
}

.dws__m84a-learnpaths .as__accordion__label .as__icon {
    top: 0;
    bottom: 0;

    margin: auto;

    @media @media__md--min {
        right: 3rem;
    }
}

.dws__m84a-learnpaths .as__accordion__content-wrapper,
.dws__m84a-learnpaths .as__accordion__toggle:checked + .dws__m84a-learnpaths__accordion .as__accordion__content-wrapper {
    padding-top: 3rem;
    padding-bottom: 6rem;

    background: rgba(153, 207, 229, .1);

    @media @media__md--min {
        padding-top: 4rem;
        padding-bottom: 6rem;
    }
}

.dws__m84a-learnpaths__group {
    margin-bottom: 3rem;
    padding-bottom: 8rem;
    border-bottom: 1px solid @color__secondary--light;

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }
}

.dws__m84a-learnpaths__item-category {
    color: @color__brand-primary-red;

    .color-brand-primary-blue & {
        color: @color__brand-primary-blue;
    }

    .color-brand-primary-yellow & {
        color: @color__brand-primary-yellow;
    }
}

.dws__m84a-learnpaths__item {
    margin-bottom: 8rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.dws__m84a-learnpaths__image-wrapper {
    display: block;
}

.dws__m84a-learnpaths__image {
    display: block;
    max-width: 100%;
    height: auto;
}

.dws__m84a-learnpaths .dws__m84a-learnpaths__end-label {
    cursor: auto;
}

.dws__m84a-learnpaths__end--is-finished .dws__m84a-learnpaths__end-label {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;

    color: @color__neutral;
    background: @color__success;
}

.dws__m84a-learnpaths .dws__m84a-learnpaths__end-label .as__icon {
    position: static;

    width: 2.5rem;
    height: 2.5rem;

    margin: 0;
}

.dws__m84a-learnpaths__end--is-finished .dws__m84a-learnpaths__end-label .as__icon {
    width: 4rem;
    height: 4rem;
}

.as__accordion__toggle:checked + .dws__m84a-learnpaths__end > .as__accordion__label .as__icon {
    transform: none;
}

.dws__m84a-learnpaths .dws__m84a-learnpaths__end-slide-wrapper {
    padding-top: 4rem;
    padding-bottom: 4rem;

    background: @color__neutral;
    box-shadow: 3px 3px 6px #00000029;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
