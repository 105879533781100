.dws__dropdown {
    position: relative;

    input[type="checkbox"] {
        clip: rect(0 0 0 0);
        clip-path: inset(100%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}

.dws__dropdown__btn {
    border-radius: 0.3rem;
    border: 1px solid @color__secondary--light;
}

.dws__dropdown__label {
    font-size: 1.3rem;
    line-height: 2rem;
    width: 100%;
    padding: 1.2rem 3.7rem 1.2rem 1.4rem;
    display: inline-block;
    position: relative;
    cursor: pointer;

    .as__icon {
        width: 1.6rem;
        height: .8rem;
        position: absolute;
        right: 1rem;
        top: ~"calc(50% - 4px)";
        transition: all @transition__duration @transition__timing-function;

        .dws__dropdown > input[type="checkbox"]:checked ~ .dws__dropdown__btn & {
            transform: rotate(180deg);
        }
    }

    .dws__dropdown__icon {
        left: 0.5rem;
        width: 1.8rem;
        height: 1.8rem;
        top: 50%;
        transform: translateY(-50%);
    }

    .dws__dropdown--icon & {
        padding-left: 3rem;
    }
}

.dws__dropdown__btn-label {
    white-space: nowrap;
}

.dws__dropdown__menu {
    width: 100%;
    height: 0;
    margin-top: 0.5rem;
    padding: 1rem;
    visibility: hidden;
    opacity: 0;
    border-radius: 5px;
    position: absolute;
    top: 100%;
    background-color: @color__neutral;

    box-shadow: 3px 3px 3px rgba(0,0,0,0.16),
        -3px -3px 3px rgba(225,225,225,0.16);

    transition: opacity @transition__duration 0.1s @transition__timing-function;

    .dws__dropdown > input[type="checkbox"]:checked ~ & {
        visibility: visible;
        opacity: 1;
        height: auto;
        z-index: 2;
    }
}

