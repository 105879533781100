.dws__m98-employees__employee-container {
    margin-bottom: -3rem;
}

.dws__m98-employees__employee-wrapper {
    margin-bottom: 3rem;
}

.dws__m98-employees__image {
    display: block;
    max-width: 100%;
    height: auto;
}

.dws__m98-employees__image-wrapper {
    display: block;
    margin-bottom: .3rem;
}

.dws__m98-employees__content {
    flex-grow: 1;

    background: @color__brand-primary-red;
    color: @color__neutral;
}
