@import "config.less";

.as__image-container{
    position:relative;
    width: 100%;
    height: 0;
    padding-bottom: @image-container__padding-bottom;
    overflow: hidden;
}
.as__image{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
}