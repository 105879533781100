.dws__m41-speaker-detail-header {
    max-width: 1920px;

    padding-top: 4rem;
    padding-bottom: 2rem;

    position: relative;

    overflow: hidden;

    @media @media__md--min {
        padding-top: 7rem;
        padding-bottom: 5rem;
    }

    @media @media__lg--min {
        padding-top: @m41-speaker-detail__header__padding-top--lg;
        padding-bottom: @m41-speaker-detail__header__padding-bottom--lg;
    }
}

.dws__m41-speaker-detail-header-wrapper {
    @media @media__md--min {
        display: flex;
    }
}

.dws__m41-speaker-detail-header__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;

    picture {
        display: flex;
        height: 100%;
        width: 100%;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(238,238,238,0.85);

            @media @media__lg--min {
                background: rgba(238,238,238,0.6);
            }
        }
    }

    img {
        object-fit: cover;
        width: 100%;
        height: auto;
    }
}

.dws__m41-speaker-detail-header-img {
    width: auto;
    height: @m41-speaker-detail__avatar__height--xs;
    border-radius: 50%;
    box-shadow: 3px 3px 6px #00000029;
    margin-bottom: 1rem;

    @media @media__md--min {
        width: auto;
        height: @m41-speaker-detail__avatar__height--lg;
        margin-bottom: 0;
    }
}

.dws__m41-speaker-detail-header-heading {
    max-width: 32rem;

    @media @media__md--min {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 5rem;

        & :last-child {
            margin-bottom: 0;
        }
    }

    @media @media__lg--min {
        max-width: none;
        padding-bottom: 2rem;
    }
}