.imageContainer(@widthRatio, @heightRatio) {
    position: relative;
    overflow: hidden;

    &:before {
        display: block;
        content: '';
        width: 100%;
        height: 0;
        padding-top: 100% / @widthRatio * @heightRatio;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 0;
        min-width: 100%;
        min-height: 100%;
    }
}