.dws__m59-header.as__module {
    margin-bottom: 4rem;

    @media @media__sm--min {
        margin-bottom: @module__margin-bottom--sm;
    }

    @media @media__md--min {
        margin-bottom: @module__margin-bottom--md;
    }

    @media @media__lg--min {
        margin-bottom: 10rem;
    }

    @media @media__xl--min {
        margin-bottom: 10rem;
    }
}

.dws__m59-header--margin-reduced.as__module {
    @media @media__lg--min {
        margin-bottom: 7rem;
    }
}

.dws__m59-header__image-wrapper {
    display: block;

    @media @media__lg--min {
        height: 29.5rem;
    }
}

.dws__m59-header__image {
    display: block;
    width: 100%;
    height: auto;

    @media @media__lg--min {
        height: 100%;
        object-fit: cover;
        object-position: center;
        font-family: 'object-fit: cover; object-position: center;';
    }
}

.dws__m59-header__box {
    display: flex;
    min-height: 15.3rem;

    flex-direction: column;
    justify-content: space-between;

    margin-top: -10rem;
    background-color: @color__neutral;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    border-radius: 5px;

    @media @media__lg--min {
        margin-top: -2.5rem;
    }
}

.dws__m59-header__link--logout {
    font-size: @text-small__font-size--xs;
    line-height: @text-small__line-height--xs;

    padding-right: 2.7rem;

    @media @media__lg--min {
        font-size: @text__font-size--xs;
        line-height: @text__line-height--xs;

        padding-right: 3.7rem;
    }
}

.dws__m59-header__link--logout .as__icon {
    top: 0;
    bottom: 0;

    width: 1.7rem;
    height: 2rem;

    margin-top: auto;
    margin-bottom: auto;

    @media @media__lg--min {
        top: auto;

        width: 2.7rem;
        height: 3rem;
    }
}

.dws__m59-header-account__tile .dws__m57-tile__media {
    display: none;

    @media @media__sm--min {
        display: block;
    }

    @media @media__md--min {
        min-height: 10rem;
    }

    @media @media__lg--min {
        min-height: 10rem;
    }
}

.dws__m59-header-account__tile .dws__m57-tile__text {
    white-space: nowrap;
    overflow: hidden;              /* "overflow"-Wert darf nicht "visible" sein */

    text-overflow: ellipsis;
}


.dws__m59-header-account__tile .dws__m57-tile__text {
    @media (max-width: @breakpoint--sm - 1px) {
        display: flex;
        align-items: center;

        padding: 1rem 2rem;
    }
}

.dws__m59-header-account__tile .dws__m57-tile__icon {
    margin-bottom: 0;
    margin-right: 1rem;

    @media @media__sm--min {
        margin-right: 0;
        margin-bottom: 1rem;
    }
}

.dws__m59-header-account__tile.dws__m59-header-account__tile--active {
    .dws__m57-tile--small & {
        background-color: @color__secondary--dark;
    }

    .dws__m57-tile--small-inverse & {
        color: @color__neutral;
        border-color: @color__brand-primary-blue;
        background-color: @color__brand-primary-blue;

        .color-brand-primary-yellow & {
            color: @color__neutral;
            border-color: @color__brand-primary-yellow;
            background-color: @color__brand-primary-yellow;
        }

        .color-brand-primary-red & {
            color: @color__neutral;
            border-color: @color__brand-primary-red;
            background-color: @color__brand-primary-red;
        }

        .color-brand-primary-blue & {
            color: @color__neutral;
            border-color: @color__brand-primary-blue;
            background-color: @color__brand-primary-blue;
        }
    }
}
