//region input
.as__input {
    color: @input__color;
    font-family: @input__font-family;
    font-size: @input__font-size;
    line-height: @input__line-height;
    padding: @input__padding;
    margin-bottom: @input__margin-bottom;
    border: @input__border-width solid @input__border-color;
    border-radius: @input__border-radius;
    width: @input__width;

    .fieldState(@input__border-color--active, @input__border-color--focus);

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: @input__placeholder__color;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: @input__placeholder__color;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: @input__placeholder__color;
    }
}

//endregion
