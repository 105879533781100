.dws__m11-news__box {
    margin-bottom: @m11-box__margin-bottom;
    padding: @m11-box__padding--xs;
    box-shadow: @m11-box__shadow;
    border-radius: @m11-box__border-radius;
    position: relative;

    @media (min-width: @breakpoint--md) {
        height: 100%;
        padding: @m11-box__padding--md;
    }
}

.dws__m11-news__box__subheading {
    font-family: @font-family__secondary;
    color: @m11-box__subheading__color;
    text-transform: uppercase
}


.color-brand-primary-yellow .dws__m11-news__box__subheading {
    color: @m11-box__subheading__color--yellow;
}

.color-brand-primary-blue .dws__m11-news__box__subheading {
    color: @m11-box__subheading__color--blue;
}

.dws__m11-news__box__action {
    .as__btn {
        margin: 0;
        position: absolute;
        bottom: @m11-box__action-btn__bottom;
        right: @m11-box__action-btn__right--xs;

        @media (min-width: @breakpoint--md) {
            right: @m11-box__action-btn__right--md;
        }
    }
}
