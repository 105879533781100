@import "config.less";

.dws__m6-carousel-cross-selling {
    background: ~'url(@{image__path}/cross_selling_wave.svg)' left bottom no-repeat;
    overflow: hidden;

    @media (min-width: 1921px) {
        background-size: 100%;
        background-position: center;
    }
}

.dws__m6-carousel-cross-selling__slider {
    position: relative;

    margin-right: auto;
    margin-left: auto;

    padding-left: .5rem;
    padding-right: .5rem;

    @media @media__lg--min {
        max-width: @max-container-width--lg;
        padding-right: @outer-margin--lg;
        padding-left: @outer-margin--lg;
    }
}

.dws__m6-carousel-cross-selling__slider-container {
    margin-left: -.5rem;
    margin-right: -.5rem;

    .as__col,
    [class*="as__col-"] {
        padding-right: .5rem;
        padding-left: .5rem;
    }

    @media @media__lg--min {
        margin-left: -1.5rem;
        margin-right: -1.5rem;

        .as__col,
        [class*="as__col-"] {
            padding-right: 1.5rem;
            padding-left: 1.5rem;
        }
    }
}

.dws__m6-carousel-cross-selling__images--speaker .dws__m6-carousel-cross-selling__image {
    border-radius: 9999px;
}

.dws__m6-carousel-cross-selling__slide {
    padding-bottom: 1rem;
}

.dws__m6-carousel-cross-selling .as__slider-nav__wrapper {
    margin: 0;

    @media @media__lg--min {
        display: none;
    }
}

.dws__m6-carousel-cross-selling__arrow {
    display: none;

    right: 100%;
    left: auto;

    border: 0;
    margin-right: -2.5rem;

    color: @color__secondary--light;

    transition: 300ms ease color;

    &,
    &:hover {
        background: transparent
    }

    // Custom media query to show element when browser has enough space
    @media (min-width: 1490px) {
        display: flex;
    }

    &:hover {
        color: @color__secondary--dark;
    }
}

.dws__m6-carousel-cross-selling__arrow.as__carousel__arrow--next {
    left: 100%;
    right: auto;

    margin-right: 0;
    margin-left: -2.5rem;
}

.dws__m6-carousel-cross-selling__arrow::before {
    display: none;
}

.dws__m6-carousel-cross-selling__arrow .as__icon {
    transform: rotate(90deg);
    width: 2rem;
    height: 1rem;
}

.dws__m6-carousel-cross-selling__arrow.as__carousel__arrow--next .as__icon {
    transform: rotate(270deg);
}

.dws__m6-carousel-cross-selling .as__slider-nav__item {
    margin: 0 0.8rem;

    &::before {
        width: 2rem;
        height: 0.4rem;
    }
}

.dws__m6-carousel-cross-selling__arrow.as__carousel__arrow--hidden,
.dws__m6-carousel-cross-selling__arrow.as__carousel__arrow--hidden:hover {
    color: @color__secondary--light;
}

.dws__m6-carousel-cross-selling-action {
    display: flex;

    .as__btn {
        font-weight: @font-weight__500;
        width: 100%;
        flex-basis: 100%;
        text-transform: uppercase;

        @media (min-width: @breakpoint--md) {
            margin: 0;

            & +.as__btn {
                margin-left: 0.5rem;
            }
        }
    }
}

.dws__m6-carousel-cross-selling .as__h1--underline .pimcore_editable_input {
    display: inline-block;
}

.dws__m6-carousel-cross-selling__slide .pimcore_block_entry {
    height: 100%;
}

.dws__m6-carousel-cross-selling-action .pimcore_block_entry {
    width: 100%;

    @media (min-width: @breakpoint--md) {
        & +.pimcore_block_entry {
            margin-left: 0.5rem;
        }
    }
}
