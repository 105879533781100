.dws__m61-leaflet-db-search {
    margin-bottom: 7rem;
}

.dws__m61-leaflet-db-search__form {
    display: flex;
    margin-bottom: 2rem;

    border-color: @color__secondary--light;
    border-radius: 5px;
    background: @color__neutral;

    box-shadow: 3px 3px 6px #85858529;
    overflow: hidden;
}

.dws__m61-leaflet-db-search__form:hover,
.dws__m61-leaflet-db-search__form:focus,
.dws__m61-leaflet-db-search__form:focus-within {
    border-color: @color__brand-primary-red;
}

.dws__m61-leaflet-db-search__form-input,
.dws__m61-leaflet-db-search__form-input:not(.as__form__error-wrapper):hover,
.dws__m61-leaflet-db-search__form-submit,
.dws__m61-leaflet-db-search__form-submit:hover,
.dws__m61-leaflet-db-search__form-submit:focus {
    border-color: inherit;
    border-radius: inherit;
    background-color: inherit;
}

.dws__m61-leaflet-db-search__form-input {
    margin-bottom: 0;
    padding: 1.1rem 2.2rem;

    border-right: 0;

    color: @color__gray--darker;
    font-size: 1.8rem;
    line-height: 2.6rem;
}

.dws__m61-leaflet-db-search__form-input,
.dws__m61-leaflet-db-search__form-input:not(.as__form__error-wrapper):hover {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.dws__m61-leaflet-db-search__form-submit {
    position: relative;
    width: 6.5rem;

    border-left: 0;

    box-shadow: none;
    transition: none;
}

.dws__m61-leaflet-db-search__form-submit,
.dws__m61-leaflet-db-search__form-submit:hover,
.dws__m61-leaflet-db-search__form-submit:focus {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    color: @color__brand-primary-red;
}

.dws__m61-leaflet-db-search__form-submit .as__icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 2.5rem;
    height: 2.5rem;

    margin: auto;

    pointer-events: none;
    z-index: 1;
}

.dws__m61-leaflet-db-search__form-submit .as__icon:first-child,
.dws__m61-leaflet-db-search__form-submit:hover .as__icon:last-child,
.dws__m61-leaflet-db-search__form-submit:focus .as__icon:last-child {
    display: block
}

.dws__m61-leaflet-db-search__form-submit .as__icon:last-child,
.dws__m61-leaflet-db-search__form-submit:hover .as__icon:first-child,
.dws__m61-leaflet-db-search__form-submit:focus .as__icon:first-child {
    display: none;
}

.dws__m61-leaflet-db-search__product-updates-form {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 300ms ease;
}

.dws__m61-leaflet-db-search__product-updates-label:not(.dws__m61-leaflet-db-search__product-updates-form--initialized) {
    cursor: pointer;
}

.dws__m61-leaflet-db-search__product-updates-toggle {
    position: relative;

    display: block;
    width: 5rem;
    height: 2.5rem;

    background-color: @color__gray--light;
    border-radius: 9999px;
    box-shadow: inset 1px 1px 6px 0 #00000029;

    transition: background-color 300ms ease;
}

.dws__m61-leaflet-db-search__product-updates-toggle::before {
    content: '';

    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    width: 2.5rem;
    height: 2.5rem;

    margin: auto 0;

    border: 1px solid @color__gray--light;
    border-radius: inherit;


    background: @color__neutral;
    z-index: 1;
    pointer-events: none;

    transition: transform 300ms ease;
}


.dws__m61-leaflet-db-search__product-updates-input:checked + .dws__m61-leaflet-db-search__product-updates-label .dws__m61-leaflet-db-search__product-updates-toggle {
    background-color: @color__brand-primary-red;
}

.dws__m61-leaflet-db-search__product-updates-input:checked + .dws__m61-leaflet-db-search__product-updates-label .dws__m61-leaflet-db-search__product-updates-toggle::before {
    transform: translateX(101%);
}

.dws__m61-leaflet-db-search__product-updates-form:not(.dws__m61-leaflet-db-search__product-updates-form--initialized),
.dws__m61-leaflet-db-search__product-updates-form--loading {
    opacity: .7;
    pointer-events: none;
}

.dws__m61-leaflet-db-search__product-updates-form:not(.dws__m61-leaflet-db-search__product-updates-form--initialized) .dws__m61-leaflet-db-search__product-updates-label,
.dws__m61-leaflet-db-search__product-updates-form--loading .dws__m61-leaflet-db-search__product-updates-label {
    cursor: not-allowed;
}
