.dws__m85-learn-control-quiz {
    margin-top: 6rem;
    scroll-margin-top: 5rem;
}

.dws__m85-learn-control-quiz__top {
    position: relative;

    border-radius: .5rem;

    box-shadow: 0 3px 6px #00000029;
    background: @color__neutral;
    overflow: hidden;

    .dws__m85-learn-control-quiz.dws__m85-learn-control-quiz--is-question & {
        overflow: visible;
    }
}

.dws__m85-learn-control-quiz__header {
    color: @color__neutral;
    background-color: @color__brand-primary-red;

    box-shadow: 0 3px 3px #00000029;

    .dws__m85-learn-control-quiz.color-brand-primary-blue & {
        background-color: @color__brand-primary-blue;
    }

    .dws__m85-learn-control-quiz.color-brand-primary-yellow & {
        background-color: @color__brand-primary-yellow;
    }

    .dws__m85-learn-control-quiz.dws__m85-learn-control-quiz--is-question & {
        color: inherit;
        background-color: @color__secondary--lighter;
        box-shadow: none;
    }
}

.dws__m85-learn-control-quiz__headline {
    color: inherit;

    .dws__m85-learn-control-quiz--is-question & {
        display: none;
    }
}

.dws__m85-learn-control-quiz__header-pagination-wrapper {
    display: none;

    .dws__m85-learn-control-quiz--is-question & {
        display: block;
    }
}

.dws__m85-learn-control-quiz__header-info--hidden,
.dws__m85-learn-control-quiz__btn-wrapper--hidden,
.dws__m85-learn-control-quiz__bottom--hidden {
    display: none;
}

.dws__m85-learn-control-quiz__question-text {
    margin-bottom: 4rem;
}

.dws__m85-learn-control-quiz__content-inner .as__checkbox-group,
.dws__m85-learn-control-quiz__content-inner .as__radio-group {
    &:last-child {
        margin-bottom: 0;
    }
}

.dws__m85-learn-control-quiz__content-inner .as__label.as__checkbox__label,
.dws__m85-learn-control-quiz__content-inner .as__label.as__radio__label {
    hyphens: manual;
}

.dws__m85-learn-control-quiz__nav-select-wrapper {
    flex-grow: 1;

    @media (min-width: @breakpoint--lg) {
        flex-grow: 0;
    }
}

.dws__m85-learn-control-quiz__nav-select-wrapper {
    .as__select-wrapper-inner::before {
        width: 1rem;
        height: 1rem;

        top: 0;
        bottom: .5rem;
        margin-top: auto;
        margin-bottom: auto;

        z-index: 1;
    }
}

.dws__m85-learn-control-quiz__nav-select,
.dws__m85-learn-control-quiz__button {
    .dws__m85-learn-control-quiz--is-loading & {
        pointer-events: none;
    }
}

.dws__m85-learn-control-quiz__btn-wrapper-row {
    margin-top: -3rem;

    @media (min-width: @breakpoint--lg) {
        margin-top: 0;
    }
}

.dws__m85-learn-control-quiz__btn-wrapper {
    margin-top: 3rem;

    @media (min-width: @breakpoint--lg) {
        margin-top: 0;
    }
}

.dws__m85-learn-control-quiz__button {
    .dws__m85-learn-control-quiz--is-loading & {
        .as__btn--disabled();
    }
}

.dws__m85-learn-control-quiz__btn-wrapper--retry-btn {
    border-top: 1px solid @color__secondary--light;
}

.dws__m85-learn-control-quiz .dws__m87-flash-message--has-items {
    margin-bottom: 2rem;

    @media (min-width: @breakpoint--lg) {
        margin-bottom: 4rem;
    }
}

// Evaluation & solution mode
.dws__m85-learn-control-quiz--evaluation-mode,
.dws__m85-learn-control-quiz--solution-mode {
    margin-top: 0;
}

.dws__m85-learn-control-quiz__evaluation-top,
.dws__m85-learn-control-quiz__solution-top {
    height: 6rem;
    padding-bottom: 1.8rem;
}

.dws__m85-learn-control-quiz__evaluation-top,
.dws__m85-learn-control-quiz__nav-select--evaluation-mode,
.dws__m85-learn-control-quiz--evaluation-mode .dws__m85-learn-control-quiz__headline,
.dws__m85-learn-control-quiz--evaluation-mode .dws__m85-learn-control-quiz__header-info,
.dws__m85-learn-control-quiz--evaluation-mode .dws__m85-learn-control-quiz__nav-select,
.dws__m85-learn-control-quiz--evaluation-mode .dws__m85-learn-control-quiz__btn-wrapper--end-btn,
.dws__m85-learn-control-quiz__solution-top,
.dws__m85-learn-control-quiz__nav-select--solution-mode,
.dws__m85-learn-control-quiz--solution-mode .dws__m85-learn-control-quiz__nav-select,
.dws__m85-learn-control-quiz--solution-mode .dws__m85-learn-control-quiz__btn-wrapper--end-btn {
    display: none !important;
}

.dws__m85-learn-control-quiz--evaluation-mode .dws__m85-learn-control-quiz__evaluation-top,
.dws__m85-learn-control-quiz--solution-mode .dws__m85-learn-control-quiz__solution-top {
    display: flex !important;
}

.dws__m85-learn-control-quiz--evaluation-mode .dws__m85-learn-control-quiz__nav-select--evaluation-mode,
.dws__m85-learn-control-quiz--solution-mode .dws__m85-learn-control-quiz__nav-select--solution-mode {
    display: block !important;
}

.dws__m85-learn-control-quiz.dws__m85-learn-control-quiz--evaluation-mode .dws__m85-learn-control-quiz__header {
    color: @color__neutral;
    background-color: @color__error;
}

.dws__m85-learn-control-quiz.dws__m85-learn-control-quiz--evaluation-correct .dws__m85-learn-control-quiz__header {
    background-color: @color__success;
}

.dws__m85-learn-control-quiz--evaluation-mode .dws__m85-learn-control-quiz__headline-wrapper {
    order: -1;
}

.dws__m85-learn-control-quiz--evaluation-mode .dws__m85-learn-control-quiz__header-pagination-wrapper,
.dws__m85-learn-control-quiz--solution-mode .dws__m85-learn-control-quiz__header-pagination-wrapper {
    flex: 0 0 auto;
    margin-left: auto;
}

.dws__m85-learn-control-quiz__headline-evaluation {
    color: inherit;
}

.dws__m85-learn-control-quiz--evaluation-mode .as__label.as__checkbox__label,
.dws__m85-learn-control-quiz--evaluation-mode .as__label.as__radio__label,
.dws__m85-learn-control-quiz--solution-mode .as__label.as__checkbox__label,
.dws__m85-learn-control-quiz--solution-mode .as__label.as__radio__label {
    pointer-events: none;
}
