.dws__m36-payment-data-overview__wrapper {
    background-color: @color__neutral;
    margin: 0 -@outer-margin--xs 3rem;
    border-radius: .5rem;
    box-shadow: 3px 3px 6px #00000029, -3px -3px 6px #CFCFCF29;

    @media @media__sm--min {
        margin-left: -@outer-margin--sm;
        margin-right: -@outer-margin--sm;
    }

    @media @media__md--min {
        margin-left: 0;
        margin-right: 0;
    }

    @media @media__lg--min {
        margin-bottom: 8rem;
    }
}

.dws__m36-payment-data-overview__heading {
    display: flex;
    align-items: flex-end;
    margin-bottom: 2rem;

    &::after {
        content: '';
        width: 100%;

        border-bottom: 2px solid @color__secondary--light;
        margin-left: 0.5rem;
        margin-bottom: 0.7rem;
    }
}

.dws__m36-payment-data-overview__text {
    word-wrap: break-word;
    font-size: 1.4rem;
}

.dws__m36-payment-data-overview-modal__reset-btn {
    color: @color__brand-primary-red;
    background-color: @color__neutral;
    border: none;
    cursor: pointer;
    font-weight: 400;

    &:focus,
    &:hover {
        color: @color__brand-primary-red--dark;
    }
}

.as__modal .as__select-wrapper-inner::before {
    z-index: 1;
}