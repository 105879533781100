.dws__m47-publishing {
    position: relative;
    min-height: 500px;
    padding-top: 1.5rem;
    margin-bottom: 3rem;

    @media @media__lg--min {
        padding-top: 0;
        margin-bottom: 6rem;
    }
}

.dws__m47-publishing__content {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 2rem 1rem 4rem;
    position: relative;
    background-color: @color__neutral;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.16);

    @media @media__lg--min {
        min-height: 44.3rem;
        flex-wrap: nowrap;
        
        margin-left: 0;
        margin-right: 0;
        padding: 2rem 33.333333% 4rem 2rem;
    }
}

.dws__m47-publishing__image {
    flex-basis: 55.5%;
    width: 55.5%;
    max-width: 27rem;
    margin: auto;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.16);

    @media @media__lg--min {
        align-self: flex-start;
        flex: 0 0 27rem;
        width: 27rem;
        margin: 0 6rem 0 0;
    }

    img {
        max-width: 100%;
        height: auto;
        display: block;
        object-fit: cover;
    }
}

.dws__m47-publishing__details {
    flex-basis: 100%;
    width: 100%;

    @media @media__lg--min {
        flex: 1 1 auto;
        width: auto;
    }
}

.dws__m47-publishing__row {
    display: flex;
    margin-bottom: 1.5rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.dws__m47-publishing__label,
.dws__m47-publishing__value {
    display: inline-block;
}

.dws__m47-publishing__label {
    color: @color__secondary--regular;
    font-size: @text-small__font-size--xs;
    width: 15.5rem;
    flex-basis: 15.5rem;
    margin-right: 1rem;

    @media @media__lg--min {
        flex: 0 0 15.5rem;
    }
}

.dws__m47-publishing__value {
    color: @color__secondary--dark;
    font-size: 1.6rem;
    font-weight: @font-weight__500;
    width: 45%;
    flex-basis: 45%;
    word-break: break-word;

    @media @media__lg--min {
        width: auto;
        flex: 1 1 auto;
        max-width: 30rem;
    }
}

.dws__m47-publishing__links {

    border-top: 1px solid @color__secondary--light;
    padding-top: 4rem;

    @media @media__lg--min {
        padding-top: 0;
        border-top: none;
    }

    a {
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    .as__icon {
        width: 1.4rem;
        height: 1.5rem;
        position: relative;
        top: 2px;
    }
}

.dws__m47-publishing__link {
    font-size: 1.4rem;
    display: inline-block;
    width: 100%;
    margin-bottom: 2rem;

    @media @media__lg--min {
        width: auto;
        margin-bottom: 0;
    }
}

@media (max-width: 349px) {
    .dws__m47-publishing__value {
        padding-left: 1rem;
    }
}
