.keyframes(@name,@rules) {
    @-webkit-keyframes @name {
        @rules();
    }

    @-moz-keyframes @name {
        @rules();
    }

    @-ms-keyframes @name {
        @rules();
    }

    @-o-keyframes @name {
        @rules();
    }

    @keyframes @name {
        @rules();
    }
}

.removeButtonStyle {
    border: none;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    display: block;
    width: 100%;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}