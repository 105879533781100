@import "config.less";

//as__text used in rte.less
body,
.as__text,
.as__text-smallest {
    color: @text__color;
    font-family: @font-family__primary;
    font-size: @text__font-size--xs;
    line-height: @text__line-height--xs;
    font-weight: @font-weight__400;
}

.as__text,
.as__text-small,
.as__text-smaller,
.as__text-smallest {
    margin-bottom: @text__margin-bottom--xs;
    margin-top:0;

    &:last-child {
        margin-bottom: 0;
    }
}


.as__text-big {
    font-size: @text-big__font-size--xs;
    line-height: @text-big__line-height--xs;

    margin-bottom: @text-big__margin-bottom--xs;
}

.as__text-small {
    font-size: @text-small__font-size--xs;
    line-height: @text-small__line-height--xs;

    margin-bottom: @text-small__margin-bottom--xs;
}

.as__text-small-sm-max {
    @media (max-width: 767px) {
        margin-top:0;
        font-size: @text-small__font-size--xs;
        line-height: @text-small__line-height--xs;
        margin-bottom: @text-small__margin-bottom--xs;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.as__text-smaller {
    font-size: @text-smaller__font-size--xs;
    line-height: @text-smaller__line-height--xs;

    margin-bottom: @text-small__margin-bottom--xs;
}

.as__text-smallest {
    font-size: @text-smallest__font-size--xs;
    line-height: @text-smallest__line-height--xs;

    margin-bottom: @text-small__margin-bottom--xs;
}

@media @media__sm--min {
    body,
    .as__text {
        font-size: @text__font-size--sm;
        line-height: @text__line-height--sm;
    }

    .as__text {
        margin-bottom: @text__margin-bottom--sm;
    }

    .as__text-big {
        font-size: @text-big__font-size--sm;
        line-height: @text-big__line-height--sm;

        margin-bottom: @text-big__margin-bottom--sm;
    }

    .as__text-small {
        font-size: @text-small__font-size--sm;
        line-height: @text-small__line-height--sm;

        margin-bottom: @text-small__margin-bottom--sm;
    }

    .as__text-smaller {
        font-size: @text-smaller__font-size--sm;
        line-height: @text-smaller__line-height--sm;

        margin-bottom: @text-small__margin-bottom--sm;
    }

    .as__text-smallest {
        font-size: @text-smallest__font-size--sm;
        line-height: @text-smallest__line-height--sm;

        margin-bottom: @text-small__margin-bottom--sm;
    }
}

@media @media__md--min {
    body,
    .as__text {
        font-size: @text__font-size--md;
        line-height: @text__line-height--md;
    }

    .as__text {
        margin-bottom: @text__margin-bottom--md;
    }

    .as__text-big {
        font-size: @text-big__font-size--lg;
        line-height: @text-big__line-height--lg;

        margin-bottom: @text-big__margin-bottom--lg;
    }

    .as__text-small {
        font-size: @text-small__font-size--md;
        line-height: @text-small__line-height--md;

        margin-bottom: @text-small__margin-bottom--md;
    }

    .as__text-smaller {
        font-size: @text-smaller__font-size--md;
        line-height: @text-smaller__line-height--md;

        margin-bottom: @text-small__margin-bottom--md;
    }

    .as__text-smallest {
        font-size: @text-smallest__font-size--md;
        line-height: @text-smallest__line-height--md;

        margin-bottom: @text-small__margin-bottom--md;
    }
}

@media @media__lg--min {
    body,
    .as__text {
        font-size: @text__font-size--lg;
        line-height: @text__line-height--lg;
    }

    .as__text-big {
        font-size: @text-big__font-size--lg;
        line-height: @text-big__line-height--lg;

        margin-bottom: @text-big__margin-bottom--lg;
    }

    .as__text {
        margin-bottom: @text__margin-bottom--lg;
    }

    .as__text-small {
        font-size: @text-small__font-size--lg;
        line-height: @text-small__line-height--lg;

        margin-bottom: @text-small__margin-bottom--lg;
    }

    .as__text-smaller {
        font-size: @text-smaller__font-size--lg;
        line-height: @text-smaller__line-height--lg;

        margin-bottom: @text-small__margin-bottom--lg;
    }

    .as__text-smallest {
        font-size: @text-smallest__font-size--lg;
        line-height: @text-smallest__line-height--lg;

        margin-bottom: @text-small__margin-bottom--lg;
    }
}

strong,
.as__strong {
    font-weight: @font-weight__700 !important;
}

.as__text__medium {
    font-weight: @font-weight__500 !important;
}

.as__text__semi-bold {
    font-weight: @font-weight__600 !important;
}

.as__line-trough {
    text-decoration: line-through;
}

.as__no-decoration {
    text-decoration: none !important;
}

.as__text-family--secondary {
    font-family: @font-family__secondary;
}

.as__text-color--secondary-light {
    color: @color__secondary--light;
}

.as__text-color--secondary-regular {
    color: @color__secondary--regular !important;
}

.as__text-color {
    color: @color__secondary--darker !important;
}

.as__text-color__grey-dark {
    color: @color__gray--dark !important;
}

.as__text-color--brand-primary-red-dark {
    color: @color__red--dark !important;
}

.as__text-color--brand-primary-red {
    color: @color__red !important;
}

.as__text-color--brand-primary-yellow-dark {
    color: @color__yellow--dark !important;
}

.as__text-color--brand-primary-yellow {
    color: @color__yellow !important;
}

.as__text-color--brand-primary-blue-dark {
    color: @color__blue--dark !important;
}

.as__text-color--brand-primary-blue {
    color: @color__blue !important;
}

.as__text-color--error {
    color: @color__error !important;
}

.as__text-color--brand-secondary-regular {
    color: @color__secondary--regular !important;
}

.as__text-color--brand-secondary-orange {
    color: @color__brand-secondary-orange !important;
}

.as__text-color--brand-secondary-green {
    color: @color__brand-secondary-green !important;
}

.as__text-color--inherit {
    color: inherit !important;
}

.as__text-uppercase {
    text-transform: uppercase;
}

.as__font-weight--thin {
    font-weight: @font-weight__100 !important;
}

.as__font-weight--extra-light {
    font-weight: @font-weight__200 !important;
}

.as__font-weight--light {
    font-weight: @font-weight__300 !important;
}

.as__font-weight {
    font-weight: @font-weight__400 !important;
}

.as__font-weight--medium {
    font-weight: @font-weight__500 !important;
}

.as__font-weight--semi-bold {
    font-weight: @font-weight__600 !important;
}

.as__font-weight--bold {
    font-weight: @font-weight__700 !important;
}

.as__font-weight--bold-md {
    @media @media__md--min {
        font-weight: @font-weight__700 !important;
    }
}

.as__font-weight--bold-sm-max {
    @media (max-width: 767px) {
        font-weight: @font-weight__700 !important;
    }
}

.as__font-weight--extra-bold {
    font-weight: @font-weight__800 !important;
}

.as__font-weight--black {
    font-weight: @font-weight__900 !important;
}
