.m38-contact {
    padding-top: 6rem;
    padding-bottom: 2.5rem;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.16),
                -3px -3px 6px rgba(207,207,207,0.16);

    @media @media__sm--min {
        padding-bottom: 1.5rem;
    }
}

.m38-contact__topline {
    color: @color__brand-primary-red;
    line-height: 3.2rem;
    font-family: @font-family__secondary;
    text-transform: uppercase;

}

.m38-contact__actions {

    .as__btn {
        width: 100%;
    }

    @media @media__sm--min {
        .as__btn + .as__btn {
            margin-left: 0;
        }
    }

    @media @media__lg--min {
        display: flex;

        .as__btn {
            flex-basis: 50%;
            width: 50%;
        }

        .as__btn + .as__btn {
            margin-left: @btn__margin-left--preceeding-btn;
        }
    }
}