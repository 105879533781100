@import "config.less";
//h1 - h6 must be defined
.as__h1, .as__h2, .as__h3, .as__h4, .as__h5, .as__h6 {
    color: @headline__color;
    font-family: @headline__font-family;
    margin-top:0;
}

.as__h1 {
    font-size: @h1__font-size--xs;
    font-weight: @h1__font-weight;
    line-height: @h1__line-height--xs;
    margin-bottom: @h1__margin-bottom--xs;
}

.as__h1--underline {
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 100%;

        position: absolute;
        left: 0;
        bottom: 0;

        border-bottom: 1px solid @color__brand-primary-red;
        margin-bottom: @h1--underline__border__margin-bottom--xs;

        z-index: 1;
    }

    .color-brand-primary-yellow &::after {
        background: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue &::after {
        background: @color__brand-primary-blue;
    }
}

.as__h1--underline .as__h1__inner {
    display: inline;
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 100%;

        position: absolute;
        left: 0;
        bottom: 0;

        border-bottom: 10px solid @color__neutral;

        z-index: 2;
    }
}

.as__bg-greige {
    .as__h1--underline .as__h1__inner::after {
        border-bottom: 10px solid @color__background__gray-light;
    }
}

.as__h1--underline .as__h1__inner-text {
    position: relative;
    z-index: 3;
}

.as__h1--underline .pimcore_editable {
    display: inline-block;
}

.as__h2 {
    font-size: @h2__font-size--xs;
    font-weight: @h2__font-weight;
    line-height: @h2__line-height--xs;
    margin-bottom: @h2__margin-bottom--xs;
}

.as__h3 {
    font-size: @h3__font-size--xs;
    font-weight: @h3__font-weight;
    line-height: @h3__line-height--xs;
    margin-bottom: @h3__margin-bottom--xs;
}

.as__h4 {
    font-size: @h4__font-size--xs;
    font-weight: @h4__font-weight;
    line-height: @h4__line-height--xs;
    margin-bottom: @h4__margin-bottom--xs;
}

.as__h5,
.as__h6{
    font-family: @headline__font-family--small;
}

.as__h5 {
    font-size: @h5__font-size--xs;
    font-weight: @h5__font-weight;
    line-height: @h5__line-height--xs;
    margin-bottom: @h5__margin-bottom--xs;
}

.as__h6 {
    font-size: @h6__font-size--xs;
    font-weight: @h6__font-weight;
    line-height: @h6__line-height--xs;
    margin-bottom: @h6__margin-bottom--xs;
}

.as__h6--underline {
    padding-bottom: 1rem;
    border-bottom: .1rem solid @color__secondary--light;
}

@media @media__sm--min {
    .as__h1 {
        font-size: @h1__font-size--sm;
        line-height: @h1__line-height--sm;
        margin-bottom: @h1__margin-bottom--sm;
    }

    .as__h1--underline {
        &::after {
            margin-bottom: @h1--underline__border__margin-bottom--sm;
        }
    }

    .as__h2 {
        font-size: @h2__font-size--sm;
        line-height: @h2__line-height--sm;
        margin-bottom: @h2__margin-bottom--sm;
    }

    .as__h3 {
        font-size: @h3__font-size--sm;
        line-height: @h3__line-height--sm;
        margin-bottom: @h3__margin-bottom--sm;
    }

    .as__h4 {
        font-size: @h4__font-size--sm;
        line-height: @h4__line-height--sm;
        margin-bottom: @h4__margin-bottom--sm;
    }

    .as__h5 {
        font-size: @h5__font-size--sm;
        line-height: @h5__line-height--sm;
        margin-bottom: @h5__margin-bottom--sm;
    }

    .as__h6 {
        font-size: @h6__font-size--sm;
        line-height: @h6__line-height--sm;
        margin-bottom: @h6__margin-bottom--sm;
    }
}

@media @media__md--min {
    .as__h1 {
        font-size: @h1__font-size--md;
        line-height: @h1__line-height--md;
        margin-bottom: @h1__margin-bottom--md;
    }

    .as__h1--underline {
        &::after {
            margin-bottom: @h1--underline__border__margin-bottom--md;
        }
    }

    .as__h2 {
        font-size: @h2__font-size--md;
        line-height: @h2__line-height--md;
        margin-bottom: @h2__margin-bottom--md;
    }

    .as__h3 {
        font-size: @h3__font-size--md;
        line-height: @h3__line-height--md;
        margin-bottom: @h3__margin-bottom--md;
    }

    .as__h4 {
        font-size: @h4__font-size--md;
        line-height: @h4__line-height--md;
        margin-bottom: @h4__margin-bottom--md;
    }

    .as__h5 {
        font-size: @h5__font-size--md;
        line-height: @h5__line-height--md;
        margin-bottom: @h5__margin-bottom--md;
    }

    .as__h6 {
        font-size: @h6__font-size--md;
        line-height: @h6__line-height--md;
        margin-bottom: @h6__margin-bottom--md;
    }
}

@media @media__lg--min {
    .as__h1 {
        font-size: @h1__font-size--lg;
        line-height: @h1__line-height--lg;
        margin-bottom: @h1__margin-bottom--lg;
    }

    .as__h1--underline {
        &::after {
            margin-bottom: @h1--underline__border__margin-bottom--lg;
        }
    }

    .as__h2 {
        font-size: @h2__font-size--lg;
        line-height: @h2__line-height--lg;
        margin-bottom: @h2__margin-bottom--lg;
    }

    .as__h3 {
        font-size: @h3__font-size--lg;
        line-height: @h3__line-height--lg;
        margin-bottom: @h3__margin-bottom--lg;
    }

    .as__h4 {
        font-size: @h4__font-size--lg;
        line-height: @h4__line-height--lg;
        margin-bottom: @h4__margin-bottom--lg;
    }

    .as__h5 {
        font-size: @h5__font-size--lg;
        line-height: @h5__line-height--lg;
        margin-bottom: @h5__margin-bottom--lg;
    }

    .as__h6 {
        font-size: @h6__font-size--lg;
        line-height: @h6__line-height--lg;
        margin-bottom: @h6__margin-bottom--lg;
    }
}
