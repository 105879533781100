.dws__m42-picture {
    overflow: hidden;
}
.dws__m42-picture__img {
    height: 18.6rem;

    @media @media__sm--min {
        height: 27.3rem;
    }

    @media @media__md--min {
        height: 37.2rem;
    }

    @media @media__lg--min {
        height: 50rem;
    }
}