.as__file-input {
    position: relative;
    display: block;
    cursor: pointer;
    height: 4rem;
}

.as__file-input input {
    margin: 0;
    opacity: 0;
    width: 100%;
}

.as__file-input-custom {
    &:extend(.as__input);

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    user-select: none;

    .fieldState(@input__border-color--active, @input__border-color--focus);

    .as__file-input input:active ~ & {
        border-color: @input__border-color--focus;
    }

    .as__field-error ~ & {
        border-color: @input--error__border-color;
    }
}

.as__file-input-placeholder {
    color: @input__placeholder__color;
}

.as__file-input-custom::before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    height: 100%;
    content: "Durchsuchen";
    padding: @input__padding;
    padding-right: 1.5rem;
    line-height: @input__line-height;
    color: @color__brand-primary-red;
    background: #fff;
    border-radius: @input__border-radius;
}