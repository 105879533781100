.dws__m77__service-box {
    margin-top: @m77__margin-top;

    @media @media__md--min {
        margin-top: 0;
    }
}

.dws__m77__service-box__inner {
    background-color: @color__secondary--lighter;
    border-radius: 5px;

    @media @media__md--min {
        display: flex;
        align-items: center;
        background-color: transparent;
    }
}

.dws__m77__service-box__media {
    margin: 0 auto;
    height: 27.9rem;
    width: 24.7rem;

    picture {
        height: 100%;
        display: flex;
        justify-content: center;
    }

    img {
        object-fit: cover;
        height: 100%;
        width: auto;
    }
}

.dws__m77__service-box__media,
.dws__m77__service-box__content {
    transform: translateY(@m77__image__translate-y);

    @media @media__md--min {
        transform: none;
    }
}

.dws__m77__service-box__content {
    padding: 3rem 1.5rem 0 2.2rem;

    @media @media__md--min {
        padding-bottom: 3rem;
        background-color: @color__secondary--lighter;
        flex: 0 1 100%;
        border-radius: 5px;
    }

    @media @media__lg--min {
        padding-left: 10rem;
    }
}