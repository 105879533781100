// region checkbox / radio-buttons
.as__checkbox-group,
.as__radio-group {
    margin-bottom: @checkbox__group__margin-bottom;
}

.as__checkbox-group .as__label.as__checkbox__label,
.as__radio-group .as__label.as__radio__label {
    margin-bottom: @checkbox__margin-bottom--grouped;
}

.as__checkbox-group .as__checkbox:last-child .as__label.as__checkbox__label,
.as__radio-group .as__radio:last-child .as__label.as__radio__label {
    margin-bottom: 0;
}

.as__checkbox,
.as__radio {
    position: relative;
}

.as__checkbox__control,
.as__radio__control {
    opacity: 0;
    z-index: -1;
    position: absolute;
}

.as__label.as__checkbox__label,
.as__label.as__radio__label {
    font-size: @checkbox__label__font-size;
    font-weight: @checkbox__label__font-weight;
    line-height: @checkbox__label__line-height;
    color: @checkbox__label__color;
    padding-left: @checkbox__label__padding-left;
    margin-bottom: @checkbox__label__margin-bottom;
    box-sizing: content-box;
}

.as__checkbox__label,
.as__radio__label {
    &::before {
        content: "";
        display: inline-block;
        height: @checkbox__icon__height;
        width: @checkbox__icon__width;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: @checkbox__icon__top;
        border: @checkbox__label__border-width solid @checkbox__label__border-color;
        border-radius: @checkbox__label__border-radius;
    }
}

.as__checkbox__label {
    &::before {
        background-position: 3px center;
        background-size: @checkbox__background-size;
    }
}

.as__checkbox__control:checked + .as__checkbox__label::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6Izg5MjMzNzt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI5MiAtNDE2LjA2MykiPjxwYXRoIGNsYXNzPSJhIiBkPSJNMjk2LjEyNSw0MjUuMDYyYS45OS45OSwwLDAsMS0uNzA3LS4zbC0zLjEyNS0zLjE2OWExLjAyNCwxLjAyNCwwLDAsMSwwLTEuNDM1Ljk5MS45OTEsMCwwLDEsMS40MTQsMGwyLjQxOCwyLjQ1Miw2LjE2OC02LjI1NWEuOTkxLjk5MSwwLDAsMSwxLjQxNCwwLDEuMDI0LDEuMDI0LDAsMCwxLDAsMS40MzVsLTYuODc1LDYuOTcxQS45OS45OSwwLDAsMSwyOTYuMTI1LDQyNS4wNjJaIi8+PC9nPjwvc3ZnPg==");
    border-color: @checkbox__label__border-color--checked;
}

.as__radio__control[disabled] + .as__radio__label,
.as__checkbox__control[disabled] + .as__checkbox__label {
    color: #C6C7C8;
    cursor: not-allowed;
}

.as__radio__control[disabled] + .as__radio__label::before,
.as__checkbox__control[disabled] + .as__checkbox__label::before {
    border-color: currentColor;
    background-color: currentColor;
}


// endregion
