// region textarea
.as__textarea {
    display: block;
    color: @textarea__color;
    font-size: @textarea__font-size;
    line-height: @textarea__line-height;
    max-width: 100%;
    min-width: 100%;
    width: @textarea__width;
    height: @textarea__height--mobile;
    min-height: @textarea__line-height + @textarea__padding + @textarea__padding;
    padding: @textarea__padding;
    margin-bottom: @textarea__margin-bottom;
    border: @input__border-width solid @textarea__border-color;
    border-radius: @textarea__border-radius;
    .fieldState(@textarea__border-color--active, @textarea__border-color--focus);

    @media @media__md--min {
        .as__textarea {
            height: @textarea__height--desktop;
        }
    }
}

// endregion
