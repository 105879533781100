@import "config.less";

.as__popover {
    border: 2px solid @color__brand-primary-red;
    padding: @popover__padding;
    border-radius: @popover__border-radius;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ~'calc(100% + @{popover__margin-left})';
    max-width: @popover__width--xs;
    min-width: @popover__width--xs;
    display: none;
    background-color: @color__neutral;
    z-index: 1;

    &.as__popover--active {
        display: inline-block;
    }

    @media @media__sm--min {
        max-width: @popover__width;
        min-width: @popover__width;
    }
}

.as__popover__icon {
    display: block;
    color: @color__secondary--dark;

    &.as__popover__icon--active,
    &:hover,
    &:focus {
        color: @color__brand-primary-red;
    }

    .as__icon {
        display: block;
        width: @popover__icon__width;
        height: @popover__icon__width;
    }
}

.as__popover__content {
    font-size: @popover__content__font-size;
    line-height: @popover__content__line-height;
    font-weight: @popover__content__font-weight;
}

input[type="checkbox"][data-popover-trigger] {
    position: absolute;
    height: 0;
    visibility: hidden;
}

input[type="checkbox"][data-popover-trigger] + label {
    position: relative;
    display: inline-flex;
}

input[type="checkbox"][data-popover-trigger]:checked + label .as__popover.as__popover--noJs {
    display: inline-block;
}

.as__popover::after, .as__popover::before {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    z-index: 1;
}

.as__popover::after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: @color__neutral;
    border-width: @popover__after__border-width;
}
.as__popover::before {
    border-color: rgba(112, 112, 112, 0);
    border-right-color: @color__brand-primary-red;
    border-width: @popover__before__border-width;
}


.as__popover--left {
    left: auto;
    right: ~'calc(100% + @{popover__margin-left})';

    &::before,
    &::after {
        right: auto;
        left: 100%;
    }

    &::before {
        border-color: rgba(112, 112, 112, 0);
        border-left-color: @color__brand-primary-red;
    }

    &::after {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: @color__neutral;
    }
}
