@course-feedback__radio__left: 4.2rem;

.dws__course-feedback__form-rating,
.dws__course-feedback__form-question {
    .as__radio__label {

        &::before {
            width: 1.2rem;
            height: 1.2rem;
            z-index: 2;
        }

        &::after {
            content: '';
            position: absolute;
            width: 2rem;
            height: 2rem;
            border-radius: 100%;
            background-color: white;
            top: .3rem;
            left: 0;
            z-index: 1;

            @media @media__md--min {
                left: @course-feedback__radio__left;
            }
        }
    }
}

.dws__course-feedback__form-question {
    .as__radio__label {
        &::after {
            left: 0;
        }
    }
}


.dws__course-feedback__form-rating {
    .as__radio {
        .as__label.as__radio__label {
            margin-bottom: 3.5rem;
        }

        &:last-child {
            margin-bottom: 4rem;
        }
    }

    @media @media__md--min {
        .as__radio-group {
            display: flex;
            justify-content: space-between;
        }

        .as__radio {
            width: 10rem;
        }

        .as__radio__label::before {
            left: @course-feedback__radio__left;
        }

        .as__label-text {
            display: none;
        }
    }
}

.dws__course-feedback__tabel-header {
    width: 10rem;
    text-align: center;
}

.dws__course-feedback__form-question {
    .as__radio-group {
        display: flex;
    }

    .as__radio:first-child {
        margin-right: 4rem;
    }
}