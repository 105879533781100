.dws__m85-learn-control-quiz__true-false-question {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 2rem;
    border-bottom: 1px solid #cbcbcb;
    margin-bottom: 2rem;

    @media (min-width: @breakpoint--lg) {
        flex-wrap: nowrap;

        padding-bottom: 0;
        border-bottom: 0;
    }

    .dws__m85-learn-control-quiz__true-false-question-group &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
        margin-bottom: 0;
    }
}

.dws__m85-learn-control-quiz__true-false-question-label {
    display: block;
    width: 100%;
    margin-bottom: 2rem;

    @media (min-width: @breakpoint--lg) {
        width: auto;
        margin-bottom: 0;
    }
}

.dws__m85-learn-control-quiz__true-false-question .as__radio-group,
.dws__m85-learn-control-quiz__true-false-question-column-labels {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    width: 100%;

    @media (min-width: @breakpoint--lg) {
        flex-shrink: 0;
        flex-direction: row;

        width: 45%;
    }
}

.dws__m85-learn-control-quiz__true-false-question .as__label.as__radio__label {
    @media (min-width: @breakpoint--lg) {
        display: block;
        width: 2rem;
        height: 2rem;

        margin: 0;
        padding: 0;
    }
}

.dws__m85-learn-control-quiz__true-false-question-text {
    @media (min-width: @breakpoint--lg) {
        display: none;
    }
}

.dws__m85-learn-control-quiz__true-false-question .as__radio__label::before {
    top: 0;
    width: 1.2rem;
    height: 1.2rem;
}

.dws__m85-learn-control-quiz__true-false-question-column-labels {
    display: none;

    margin-left: auto;
    margin-bottom: 2rem;

    @media (min-width: @breakpoint--lg) {
        display: flex;
    }
}

.dws__m85-learn-control-quiz__true-false-question-column-label {
    font-weight: @font-weight__700;

    @media (min-width: @breakpoint--lg) {
        width: 100%;

        padding-left: 1rem;
        padding-right: 1rem;

        text-align: center;
    }
}

.dws__m85-learn-control-quiz__solution-modal {
    &:extend(.as__modal all);
}
