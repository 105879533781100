@import "config.less";

.as__carousel {
    position: relative;
}

.as__carousel__image-container {
    .imageContainer(3, 2);
    width: 100%;

    margin-bottom: @carousel__image-container__margin-bottom;
}

.as__carousel__image {
    max-width: 100%;

    transition: transform @transition__duration @transition__timing-function;
    transform-origin: top left;
}

.as__carousel__item:hover .as__carousel__image {
    transform: scale(1.05) translate(-50%,-50%);
}

.as__carousel__headline {
    transition: color @transition__duration @transition__timing-function;
}

.as__carousel__item:hover .as__carousel__headline {
    color: @carousel__headline__color--hover;
}

.as__carousel__arrow {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: @half-gutter-width solid @color__neutral;
    width: @carousel__arrow__width;
    background: @carousel__arrow__background;
    cursor: pointer;
    box-sizing: content-box;

    transition: background @transition__duration @transition__timing-function;

    @media @media__sm--min {
        width: @carousel__arrow__width--sm;
    }

    @media @media__md--min {
        width: @carousel__arrow__width--md;
    }

    @media @media__lg--min {
        width: @carousel__arrow__width--lg;
    }

    @media @media__xl--min {
        width: @carousel__arrow__width--xl;
    }


    &::before {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: @carousel__arrow-icon__left-right;
        border-left: @carousel__arrow-icon__border;
        border-bottom: @carousel__arrow-icon__border;
        width: @carousel__arrow-icon__width;
        height: @carousel__arrow-icon__width;
        transform: translateY(-50%) rotate(45deg);

        @media @media__sm--min {
            left: @carousel__arrow-icon__left-right--sm;
        }

        @media @media__md--min {
            left: @carousel__arrow-icon__left-right--md;
        }

        @media @media__lg--min {
            left: @carousel__arrow-icon__left-right--lg;
        }

        @media @media__xl--min {
            left: @carousel__arrow-icon__left-right--xl;
        }

    }

    &:hover {
        background: @carousel__arrow__background--hover;
    }

    &--next {
        left: auto;
        right: 0;

        border-left: 0;
        border-right: @half-gutter-width solid @color__neutral;

        &::before {
            left: auto;
            right: @carousel__arrow-icon__left-right;

            transform: translateY(-50%) rotate(-135deg);

            @media @media__sm--min {
                left: auto;
                right: @carousel__arrow-icon__left-right--sm;
            }

            @media @media__md--min {
                left: auto;
                right: @carousel__arrow-icon__left-right--md;
            }

            @media @media__lg--min {
                left: auto;
                right: @carousel__arrow-icon__left-right--lg;
            }

            @media @media__xl--min {
                left: auto;
                right: @carousel__arrow-icon__left-right--xl;
            }

        }
    }
}

.as__carousel__arrow--hidden {
    display: none;
}
