@m80-video__bottom--xs: -1rem;
@m80-video__bottom--md: -4rem;

.dws__m80-video {
    position: relative;
    margin-bottom: 5rem - @m80-video__bottom--xs;

    @media @media__md--min {
        margin-bottom: 5rem - @m80-video__bottom--md;
    }

    .dws__m80-video__heading {
        color: @color__gray--dark;
    }

    .dws__video {
        box-shadow: 3px 3px 6px #00000029, -3px -3px 6px #B4B4B429;
        z-index: @zindex--layer100;
    }

    .as__icon {
        width: 8rem;
        height: 8rem;

        @media @media__lg--min {
            width: 16rem;
            height: 16rem;
        }
    }

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 9rem;
        background-color: currentColor;
        opacity: 0.4;
        position: absolute;
        bottom: -1rem;
        left: 0;
        z-index: @zindex--layer50;

        @media @media__md--min {
            height: 16rem;
            bottom: ~"calc(@{m80-video__bottom--md} / 2)";
        }

        @media @media__lg--min {
            height: 25rem;
            bottom: @m80-video__bottom--md;
        }
    }

    .color-brand-primary-yellow &,
    &.color-brand-primary-yellow {
        color: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue &,
    &.color-brand-primary-blue {
        color: @color__brand-primary-blue;
    }

    .color-brand-primary-red &,
    &.color-brand-primary-red {
        &::before {
            background-color: @color__secondary--light;
            opacity: 0.4;
        }
    }
}

.dws__m80-video.as__module {
    margin-bottom: @module__margin-bottom--xs - @m80-video__bottom--xs;

    @media @media__sm--min {
        margin-bottom: @module__margin-bottom--sm - @m80-video__bottom--md;
    }

    @media @media__md--min {
        margin-bottom: @module__margin-bottom--md - @m80-video__bottom--md;
    }

    @media @media__lg--min {
        margin-bottom: @module__margin-bottom--lg - @m80-video__bottom--md;
    }

    @media @media__xl--min {
        margin-bottom: @module__margin-bottom--xl - @m80-video__bottom--md;
    }
}