.dws__m34__icontext {
    text-align: center;
    margin-bottom: @iconText__margin-bottom--xs;

    @media (min-width: @breakpoint--md) {
        margin-bottom: @iconText__margin-bottom--md;
    }
}

.dws__m34__icontext__icon {
    color: @color__success;
    margin-bottom: @iconText__icon__margin-bottom;

    .as__icon {
        width: @iconText__icon__width--xs;
        height: @iconText__icon__height;

        @media (min-width: @breakpoint--md) {
            width: @iconText__icon__width--md;
        }
    }
}

.dws__m34__icontext__headline {
    color: @iconText__headline__color;
    font-size: @iconText__headline__font-size;
    line-height: @iconText__headline__line-height;
    margin-bottom: @iconText__headline__margin-bottom;
}

.dws__m34__icontext__text {
    @media (min-width: @breakpoint--lg) {
        padding: 0 4rem;
    }

    @media (min-width: @breakpoint--xl) {
        padding: 0 12rem;
    }
}

