.dws__breadcrumb {
    margin-bottom: 4rem;

    @media @media__md--min {
        margin-bottom: 7rem;
    }
}

.dws__breadcrumb__items {
    position: relative;
    max-width: 100%;
    box-shadow: @breadcrumb__shadow;
    border-top-right-radius: @breadcrumb__border-radius;
    border-bottom-right-radius: @breadcrumb__border-radius;
    background-color: @color__neutral;
    margin-left: -@breadcrumb__item__padding-left-right;
    margin-right: -@breadcrumb__item__padding-left-right;

    &::before {
        content: '';

        position: absolute;
        top: 0;
        right: 100%;

        height: 100%;
        width: 9999px;

        background-color: inherit;

        box-shadow: @breadcrumb__shadow;
        z-index: 1;
    }

    &::after {
        content: '';

        position: absolute;
        top: 0;
        left: 0;

        height: 100%;
        width: 2rem;

        background-color: inherit;

        z-index: 2;
    }
}

.dws__breadcrumb__item {
    font-size: @breadcrumb__item__font-size;
    padding: @breadcrumb__item__padding;
    display: inline-block;
    position: relative;
    z-index: 3;
    white-space: nowrap;

    a {
        color: @color__brand-primary-red;

        &:hover,
        &:focus {
            color: @color__brand-primary-red--dark;
        }
    }

    &::after {
        content: '';
        display: inline-block;
        width: 0.4rem;
        height: 0.8rem;
        position: absolute;
        top: 1.2rem;
        left: ~"calc(100% - 0.2rem)";
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0LjE3NCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNC4xNzQgOCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6Izg3ODg4YTt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xMjguMTU1LDE4LjY1M2EuMTc0LjE3NCwwLDAsMS0uMTIzLS4zbDMuNy0zLjctMy43LTMuN2EuMTc0LjE3NCwwLDAsMSwuMjQ2LS4yNDZMMTMyLjEsMTQuNTNhLjE3NC4xNzQsMCwwLDEsMCwuMjQ2TDEyOC4yNzgsMTguNkEuMTc0LjE3NCwwLDAsMSwxMjguMTU1LDE4LjY1M1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjcuOTgxIC0xMC42NTMpIi8+PC9zdmc+");
        background-repeat: no-repeat;
    }

    &:last-child {
        padding-right: 2rem;
        &::after {
            content: none;
        }
    }
}

.dws__breadcrumb__item--current {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dws__breadcrumb--over-content {
    position: relative;
    margin-bottom: 0;
}

.dws__breadcrumb--over-content .dws__breadcrumb__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
