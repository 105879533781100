.dws__m50-certificates__item {
    margin-bottom: 3rem;
    padding: 2rem 1.5rem;
    border-radius: 5px;
    position: relative;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.16),
                -3px -3px 3px rgba(225,225,225,0.16);
    overflow: hidden;

    background: @color__neutral;

    @media @media__md--min {
        padding: 2rem 4rem 2rem 7rem;
        display: flex;
        justify-content: space-between;
    }

    &::before {
        content: '';
        display: inline-block;
        height: 100%;
        width: 0.5rem;
        position: absolute;
        top: 0;
        left: 0;
        background: rgb(186,151,59);
        background: linear-gradient(180deg, rgba(186,151,59,1) 0%, rgba(165,125,36,1) 5%, rgba(217,191,99,1) 50%, rgba(194,162,71,1) 70%, rgba(165,125,36,1) 100%);

        @media @media__md--min {
            width: 1rem;
        }
    }
}

.dws__m50-certificates__item__title {

}

.dws__m50-certificates__item__content {
  @media @media__md--min {
      padding-right: 2.5rem;
  }
}

.dws__m50-certificates__item__icon {
    color: rgb(186,151,59);
    position: absolute;
    top: 2rem;
    left: 2.5rem;

    .as__icon {
        color: #A67F25;
        fill: #A67F25;
        width: 2.7rem;
        height: 3.8rem;
    }
}

.dws__m50-certificates__item__link {
    font-size: 1.4rem;
    display: inline-block;
    margin-bottom: 0;
    padding-left: 1rem;
    margin-right: 3rem;

    & + .dws__m50-certificates__item__link {
        margin-right: 0;
    }

    .dws__m50-certificates__item__action & {
        padding-left: 1.3rem;
    }

    .as__icon {
        top: 5px;
        width: 0.6rem;
        height: 1rem;

        .dws__m50-certificates__item__action & {
            width: 1rem;
            height: 1.4rem;
            top: 4px;
        }
    }
}

.dws__m50-certificates__item__image-wrapper {
    width: 13rem;
    height: 18rem;
    flex: 0 0 13rem;
    overflow: hidden;
}

.dws__m50-certificates__item__image {
    display: inline-block;
    object-fit: cover;
    object-position: 50%;
    width: 100%;
    height: auto;
}
