.dws__m23-subscription {
    display: block;
    padding: 1.5rem 1rem 0;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;

    &.color-brand-primary-red {
        border-color: @color__brand-primary-red;
    }

    &.color-brand-primary-blue {
        border-color: @color__brand-primary-blue;
    }

    &.color-brand-primary-yellow {
        border-color: @color__brand-primary-yellow;
    }
}

.dws__m23-subscription__icon {
    width: 2.7rem;
    height: 2.8rem;
    margin: 0 auto 1rem;

    @media @media__lg--min {
        width: 3.9rem;
        height: 4rem;
    }

    &.color-brand-primary-red {
        color: @color__brand-primary-red;
    }

    &.color-brand-primary-blue {
        color: @color__brand-primary-blue;
    }

    &.color-brand-primary-yellow {
        color: @color__brand-primary-yellow;
    }

    .as__icon {
        width: 100%;
        height: 100%;
    }
}

.dws__m23-subscription__headline {
    &.color-brand-primary-red {
        color: @color__brand-primary-red;
    }

    &.color-brand-primary-blue {
        color: @color__brand-primary-blue;
    }

    &.color-brand-primary-yellow {
        color: @color__brand-primary-yellow;
    }

    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: center;
}

.dws__m23-subscription__content {
    transform: translateY(10px);
}

.dws__m23-subscription__deco {
    height: 5rem;
    position: relative;
    padding-top: 30%;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        padding-bottom: 50%;
        width: 57.7%;
        z-index: -1;
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: rotate(-30deg) skewX(30deg);
        -ms-transform: rotate(-30deg) skewX(30deg);
        transform: rotate(-30deg) skewX(30deg);
    }

    &.color-brand-primary-red {
        &::before {
            background-color: @color__brand-primary-red;
        }
    }

    &.color-brand-primary-blue {
        &::before {
            background-color: @color__brand-primary-blue;
        }
    }

    &.color-brand-primary-yellow {
        &::before {
            background-color: @color__brand-primary-yellow;
        }
    }
}

.dws__m23-subscription__text {
    color: @color__neutral;
    text-align: center;
    position: relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    @media (min-width: 1024px) {
        font-size: @text-small__font-size--xs;
        line-height: @text-small__line-height--xs;
    }

    &.color-brand-primary-red {
        background-color: @color__brand-primary-red;
    }

    &.color-brand-primary-blue {
        background-color: @color__brand-primary-blue;
    }

    &.color-brand-primary-yellow {
        background-color: @color__brand-primary-yellow;
    }
}
