// Region carousel image
@carousel__image-container__margin-bottom: 2rem;
// Endregion carousel image

// Region carousel content
@carousel__headline__color--hover: @color__brand-primary-red;
// Endregion carousel content

// Region carousel arrow
@carousel__arrow__width: 3.5rem;
@carousel__arrow__width--sm: @carousel__arrow__width;
@carousel__arrow__width--md: 5rem;
@carousel__arrow__width--lg: @carousel__arrow__width--md;
@carousel__arrow__width--xl: @carousel__arrow__width--lg;
@carousel__arrow__width--xxl: @carousel__arrow__width--xl;
@carousel__arrow__background: fade(@color__gray--dark, 40%);
@carousel__arrow__background--hover: fade(@color__gray--dark, 70%);

@carousel__arrow-icon__width: 1.25rem;
@carousel__arrow-icon__left-right: 1.375rem;
@carousel__arrow-icon__left-right--sm: @carousel__arrow-icon__left-right;
@carousel__arrow-icon__left-right--md: 2.25rem;
@carousel__arrow-icon__left-right--lg: @carousel__arrow-icon__left-right--md;
@carousel__arrow-icon__left-right--xl: @carousel__arrow-icon__left-right--lg;
@carousel__arrow-icon__left-right--xxl: @carousel__arrow-icon__left-right--xl;
@carousel__arrow-icon__border: .3rem solid @color__neutral;
// Endregion carousel arrow

// Region slider nav
@slider-nav__wrapper__margin-top: 3rem - @slider-nav__item__padding-top-bottom;
@slider-nav__wrapper__margin-top--sm: @slider-nav__wrapper__margin-top;
@slider-nav__wrapper__margin-top--md: 5rem - @slider-nav__item__padding-top-bottom;
@slider-nav__wrapper__margin-top--lg: @slider-nav__wrapper__margin-top--md;
@slider-nav__wrapper__margin-top--xl: @slider-nav__wrapper__margin-top--md;
@slider-nav__wrapper__margin-top--xxl: @slider-nav__wrapper__margin-top--md;
@slider-nav__item__spacing: 2.5rem;
@slider-nav__item__spacing--sm: @slider-nav__item__spacing;
@slider-nav__item__spacing--md: @slider-nav__item__spacing--sm;
@slider-nav__item__spacing--lg: @slider-nav__item__spacing--md;
@slider-nav__item__spacing--xl: @slider-nav__item__spacing--lg;
@slider-nav__item__spacing--xxl: @slider-nav__item__spacing--xl;
@slider-nav__item__margin-left-right: @slider-nav__item__spacing / 2;
@slider-nav__item__margin-left-right--sm: @slider-nav__item__spacing--sm / 2;
@slider-nav__item__margin-left-right--md: @slider-nav__item__spacing--md / 2;
@slider-nav__item__margin-left-right--lg: @slider-nav__item__spacing--lg / 2;
@slider-nav__item__margin-left-right--xl: @slider-nav__item__spacing--xl / 2;
@slider-nav__item__margin-left-right--xxl: @slider-nav__item__spacing--xxl / 2;
@slider-nav__item__padding-top-bottom: 2rem;
@slider-nav__item__background: fade(@color__secondary--dark, 50%);
@slider-nav__item__background--active: @color__secondary--dark;
// Endregion slider nav