.as__loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.7);
}

.as__loading-indicator {
    display: block;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: @color__brand-primary-red;
    border-right-color: @color__brand-primary-red;
    animation: spin 2s linear infinite;
    z-index: 1002;
    width: 3rem;
    height: 3rem;
}

.as__loading-indicator.as__loading-indicator--double {
    &::before {
        content: "";
        position: absolute;
        border-radius: 50%;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        border: 2px solid transparent;
        border-top-color: @color__brand-primary-yellow;
        animation: spin 3s linear infinite;
    }
}

.as__loading-indicator.as__loading-indicator--triple {

    &::before, &::after {
        content: "";
        position: absolute;
        border-radius: 50%;
        border: 2px solid transparent;
    }

    &::before {
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        border-top-color: @color__brand-primary-yellow;
        animation: spin 3s linear infinite;
    }

    &::after {
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-top-color: @color__brand-primary-blue;
        animation: spin 1.5s linear infinite;
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}
@keyframes spin {
    from {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
