.dws__m25-headline-search-results__headline {
    font-weight: @font-weight__400;
}

.dws__m25-headline-search-results__search-term {
    word-break: break-word;

    @media @media__md--min {
        word-break: normal;
    }
}
