@import "config.less";

    //this classes are used in rte.less
.as__list {
    list-style-position: inside;
    font-weight: @list__font-weight;
    list-style: none;
    position: relative;
    margin-top:0;
    margin-bottom: @list__margin-bottom--xs;
    font-size: @list__font-size--xs;
    line-height: @list__line-height--xs;
    margin-left:0;
    padding-left:0;

    @media @media__sm--min {
        margin-bottom: @list__margin-bottom--sm;
        font-size: @list__font-size--sm;
        line-height: @list__line-height--sm;
    }

    @media @media__md--min {
        margin-bottom: @list__margin-bottom--md;
        font-size: @list__font-size--md;
        line-height: @list__line-height--md;
    }

    @media @media__lg--min {
        margin-bottom: @list__margin-bottom--lg;
        font-size: @list__font-size--lg;
        line-height: @list__line-height--lg;
    }
}

.as__list-item {
    position: relative;

    padding-left: @list__li__padding-left + @list__li__arrow__width;
    margin-bottom: @list__li__margin-bottom--xs;

    @media @media__sm--min {
        margin-bottom: @list__li__margin-bottom--sm;
    }

    @media @media__md--min {
        margin-bottom: @list__li__margin-bottom--md;
    }

    @media @media__lg--min {
        margin-bottom: @list__li__margin-bottom--lg;
    }

    &::before {
        position: absolute;
        top: (@list__line-height--xs - @list__li__arrow__height) / 2;
        left: 0;
        width: @list__li__arrow__width;
        height: @list__li__arrow__height;
        content: '';

        background-image: ~'url(@{icon__path}/list-item-arrow.svg)';

        @media @media__sm--min {
            top: (@list__line-height--sm - @list__li__arrow__height) / 2;
        }

        @media @media__md--min {
            top: (@list__line-height--md - @list__li__arrow__height) / 2;
        }

        @media @media__lg--min {
            top: (@list__line-height--lg - @list__li__arrow__height) / 2;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.as__list--ordered {
    counter-reset: item;
    margin-bottom: @list--ordered__margin-bottom--xs;
    font-size: @list--ordered__font-size--xs;
    line-height: @list--ordered__line-height--xs;

    @media @media__sm--min {
        margin-bottom: @list--ordered__margin-bottom--sm;
        font-size: @list--ordered__font-size--sm;
        line-height: @list--ordered__line-height--sm;
    }

    @media @media__md--min {
        margin-bottom: @list--ordered__margin-bottom--md;
        font-size: @list--ordered__font-size--md;
        line-height: @list--ordered__line-height--md;
    }

    @media @media__lg--min {
        margin-bottom: @list--ordered__margin-bottom--lg;
        font-size: @list--ordered__font-size--lg;
        line-height: @list--ordered__line-height--lg;
    }
}

.as__list--ordered .as__list-item {
    padding-left: @list--ordered__li__padding-left;
    margin-bottom: @list--ordered__li__margin-bottom--xs;

    @media @media__sm--min {
        margin-bottom: @list--ordered__li__margin-bottom--sm;
    }

    @media @media__md--min {
        margin-bottom: @list--ordered__li__margin-bottom--md;
    }

    @media @media__lg--min {
        margin-bottom: @list--ordered__li__margin-bottom--lg;
    }

    &::before {
        position: absolute;
        top: auto;
        left: 0;

        width: auto;
        height: auto;

        font-weight: @font-weight__700;

        content: counter(item) ".";
        counter-increment: item;

        background: transparent;
    }
}

.dws__list {
    &:extend(.as__list);
}

.dws__list-item {
    &:extend(.as__list-item);

    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: @font-weight__700;
    padding-left: 3rem;

    .as__icon {
        position: absolute;
        left: 0;
        top: 0.3rem;
        color: @color__brand-primary-red;
        width: 1.8rem;
        height: 1.8rem;
    }
}
