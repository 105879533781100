.dws__m79-testemonial.as__module:not(.dws__m79-testemonial--no-divider) {
    margin-bottom: 6rem;
}

.dws__m79-testemonial .dws__m79-testemonial__inner {
    &::after {
        content: '';
        display: block;

        margin-top: 6rem;
        border-top: 1px solid @color__gray--light;
    }
}

.dws__m79-testemonial__headline {
    font-weight: @font-weight__600;

    @media @media__lg--min {
        font-weight: @font-weight__500;
    }
}

.dws__m79-testemonial__image-wrapper.pimcore_editable_image img,
.dws__m79-testemonial__image {
    display: block;
    border-radius: 9999px;
}

.dws__m79-testemonial--no-divider .dws__m79-testemonial__inner {
    &::after {
        display: none;
    }
}
