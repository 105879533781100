.dws__info-error-container .as__info-error {
    margin-bottom: 0;
}

.as__input-validate {
    .as__form-validate--scroll-offset-20 & {
        scroll-margin-top: 2rem;
    }

    .as__form-validate--scroll-offset-50 & {
        scroll-margin-top: 5rem;
    }

    .as__form-validate--scroll-offset-100 & {
        scroll-margin-top: 10rem;
    }

    .as__form-validate--scroll-offset-200 & {
        scroll-margin-top: 20rem;
    }
}
