.dws__m32-registration-form__wrapper {
    background-color: @color__neutral;
    margin: 0 -2.6rem;
    box-shadow: 3px 3px 6px #00000029, -3px -3px 6px #CFCFCF29;
    border-radius: 5px;
    padding: 2.5rem 2.6rem;
    margin-bottom: 6rem;

    @media @media__md--min {
        margin: 0;
    }
}

.dws__m32-registration-form__wrapper .as__h2 {
    font-weight: 500;
}

.dws__m32-registration-form__info {
    font-size: 1.3rem;
    color: @color__secondary--regular;
}

.dws__m32-registration-form .as__textarea {
    height: auto;
}