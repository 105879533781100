.dws__switch-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.dws__switch {
    margin-right: 2.5rem;
    margin-bottom: 3rem;

    &:last-child {
        margin-right: 0;
    }
}

.dws__switch__content {
    font-weight: @font-weight__700;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    min-height: 5.4rem;
    max-width: 20rem;
    padding: 0.3rem 1.6rem;
    border: 2px solid @color__brand-primary-red;
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgba(128,128,128,0.16);
    flex-direction: column;
    cursor: pointer;

    &.dws__switch--blue {
        border-color: @color__brand-primary-blue;
    }

    &.dws__switch--yellow {
        border-color: @color__brand-primary-yellow;
    }
}

.dws__switch input[type="checkbox"]:checked + label .dws__switch__content,
.dws__switch--inactive .dws__switch__content {
    color: @color__secondary--regular;
    font-weight: @font-weight__400;
    padding: 0.3rem 1.7rem;

    background-color: @color__secondary--lighter;
    border-color: @color__secondary--light;
    border-width: 1px;
    box-shadow: none;
}

.dws__switch__checkbox {
    height: 1px;
    position: absolute;
    visibility: hidden;
}