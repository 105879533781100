.dws__footer--reduced {
    background-color: @color__neutral;
    padding: 3rem 0 6rem;
    border-top: 1px solid @color__brand-primary-red;

    @media (min-width: @breakpoint--md) {
        padding: 3.5rem 0;
    }

    .dws__footer__navigation {
        list-style: none;
        margin: 0;
        padding: 0;

        @media (min-width: @breakpoint--md) {
            display: flex;

            & li:first-child > .dws__footer__navigation__link {
                padding-left: 0;
            }

            & li:last-child > .dws__footer__navigation__link {
                padding-right: 0;
            }
        }

        li {
            display: block;

            @media (min-width: @breakpoint--md) {
                display: unset;
            }

            &:last-child > .dws__footer__navigation__link {
                border-bottom: 1px solid @color__secondary--light;
            }
        }
    }

    .dws__footer__action {
        width: 100%;
        margin-bottom: 6rem;

        @media (min-width: @breakpoint--md) {
            margin-bottom: 0;
            padding: 1rem 1.5rem;
        }

        @media (min-width: 825px) {
            padding: 1rem 2rem;
        }
    }

    .dws__footer__navigation__link {
        color: @color__brand-primary-red;
        display: block;
        padding: 1.5rem;
        border-top: 1px solid @color__secondary--light;
        text-align: center;

        @media (min-width: @breakpoint--md) {
            border: none !important;
            padding: 0 1.2rem;
        }

        @media (min-width: @breakpoint--lg) {
            padding: 0 2.8rem;
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.dws__footer--reduced__rte {
    strong {
        font-weight: @font-weight__500;
    }
}

.dws__footer__contact {
    display: flex;
    justify-content: center;
    padding: 6rem 0;

    @media (min-width: @breakpoint--md) {
        padding: 0;
    }
}

.dws__footer__contact__info {
    position: relative;

    .as__icon {
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }
}
