.dws__m57-tile-wrapper {
    position: relative;
    margin-bottom: -2rem;
}

.dws__m57-tile {
    font-family: @font-family__secondary;
    font-weight: @font-weight__500;
    margin-bottom: 2rem;
}

.dws__m57-tile__headline {
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: @font-weight__600;
    margin-top: 0;
    margin-bottom: 1rem;

    @media @media__lg--min {
        font-size: 2.1rem;
        line-height: 2.6rem
    }
}

.dws__m57-tile__textlink {
    position: absolute;
    top: 0;
    right: 0;

    font-size: 1.3rem;
    text-decoration: none;

    margin-bottom: 1rem;

    z-index: 1;
}

.dws__m57-tile__content {
    display: block;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.16);
    color: @color__neutral;

    .dws__m57-tile--small &,
    .dws__m57-tile--bg-only & {
        background-color: @color__brand-primary-blue;
        transition: background-color @transition__duration @transition__timing-function;

        .color-brand-primary-yellow & {
            background-color: @color__brand-primary-yellow;
        }

        .color-brand-primary-red & {
            background-color: @color__brand-primary-red;
        }

        .color-brand-primary-blue & {
            background-color: @color__brand-primary-blue;
        }
    }

    .dws__m57-tile--small-inverse & {
        box-shadow: none;
        color: @color__brand-primary-blue;
        border: 1px solid @color__gray--light;
        background-color: @color__neutral;
        transition: background-color @transition__duration @transition__timing-function,
                    border-color @transition__duration @transition__timing-function,
                    color @transition__duration @transition__timing-function;

        .color-brand-primary-yellow & {
            background-color: @color__neutral;
            color: @color__brand-primary-yellow;
        }

        .color-brand-primary-red & {
            background-color: @color__neutral;
            color: @color__brand-primary-red;
        }

        .color-brand-primary-blue & {
            background-color: @color__neutral;
            color: @color__brand-primary-blue;
        }
    }
}

.dws__m57-tile__content:hover ,
.dws__m57-tile__content:focus {
    .dws__m57-tile--small &,
    .dws__m57-tile--bg-only & {
        background-color: @color__secondary--dark;
    }

    .dws__m57-tile--small-inverse & {
        color: @color__neutral;
        border-color: @color__brand-primary-blue;
        background-color: @color__brand-primary-blue;

        .color-brand-primary-yellow & {
            border-color: @color__brand-primary-yellow;
            background-color: @color__brand-primary-yellow;
        }

        .color-brand-primary-red & {
            border-color: @color__brand-primary-red;
            background-color: @color__brand-primary-red;
        }

        .color-brand-primary-blue & {
            border-color: @color__brand-primary-blue;
            background-color: @color__brand-primary-blue;
        }
    }
}

.dws__m57-tile__media {
    position: relative;
    padding-bottom: 43.7788%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #41545E;
        opacity: 0.5;
        z-index: 2;
        transition: opacity @transition__duration @transition__timing-function,
                    background-color @transition__duration @transition__timing-function;
    }

    .dws__m57-tile__content:hover &,
    .dws__m57-tile__content:focus & {
        &::before {
            opacity: 0.9;
            background-color: @color__secondary--dark;
        }
    }

    .dws__m57-tile--small & {
        @media (max-width: @breakpoint--sm - 1px) {
            padding-bottom: 4.3rem;
            margin-bottom: 2rem;
            border-radius: 0;
            box-shadow: none;
        }

        @media @media__lg--min {
            padding-bottom: 66.831%;
        }
    }

    .dws__m57-tile--small &,
    .dws__m57-tile--bg-only & {
        &::before {
            content: none;
        }
    }
}

.dws__m57-tile__icon {
    color: inherit;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    left: auto;

    .as__icon {
        width: 4.2rem;
        height: 2.8rem;
    }
}

.dws__m57-tile__icon--bottom-left {
    position: static;
    margin-bottom: 1rem;
}

.dws__m57-tile__icon--small {
    .as__icon {
        width: 2.9rem;
        height: 2.3rem;
    }
}

picture.dws__m57-tile__image-wrapper {
    display: flex;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.dws__m57-tile__image {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
}

.dws__m57-tile__text {
    color: inherit;
    font-size: 2.1rem;
    line-height: 2.6rem;
    position: absolute;
    top: auto;
    bottom: 1.5rem;
    right: 1.5rem;
    left: 1.5rem;
    text-shadow: 3px 3px 3px rgba(0,0,0,0.16);
    z-index: 3;

    @media @media__md--min {
        font-size: 2.8rem;
        line-height: 3.2rem;

        left: 2rem;
        right: 2rem;
    }

    .dws__m57-tile--no-image & {
        text-shadow: none;
    }

    .dws__m57-tile--small & {
        @media @media__md--min {
            font-size: 1.8rem;
            line-height: 2.4rem;
        }
    }

    .dws__m57-tile--small & {
        @media (max-width: @breakpoint--sm - 1px) {
            position: static;
            padding: 0 1.5rem 2rem;
        }

        @media @media__md--min {
            left: 1.5rem;
            right: 1.5rem;
        }
    }
}

.dws__m57-tile__text--small {
    font-size: @text__font-size--xs;
    line-height: @text__line-height--xs;
}

.dws__m57-tile-wrapper--margin-big {
    margin-bottom: -3rem;
}

.dws__m57-tile-wrapper--margin-big .dws__m57-tile {
    margin-bottom: 3rem;
}
