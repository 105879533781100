.dws__m7__newsletter {
    max-width: @m7newsletter__max-width;
}

.dws__m7__newsletter__register {
    position: relative;
    z-index: 3;
    box-shadow: 3px 3px 6px rgba(0,0,0,16%);

    @media (min-width: @m7newsletter__xxl__breakpoint) {
        box-shadow: 2px 3px 7px rgba(0,0,0,16%);
    }
}

.dws__m7__newsletter__register__content {
    padding: 6rem @m7newsletter__register__padding-left--xs 4rem;
    max-width: @m7newsletter__register__max-width;

    @media (min-width: @breakpoint--lg) {
        padding-left: @m7newsletter__register__padding-left--lg;
        padding-right: @m7newsletter__register__padding-left--lg;
    }

    @media (min-width: @breakpoint--xl) {
        padding-left: @m7newsletter__register__padding-left--xl;
        padding-right: @m7newsletter__register__padding-right--xl;
    }
}

.dws__m7__newsletter__subheading {
    color: @color__brand-primary-red;
    font-family: @font-family__secondary;
    text-transform: uppercase;
}

.dws__m7__newsletter__heading {
   @media (min-width: @media__md--min) {
       font-size: 3.4rem;
   }
}

.dws__m7__newsletter__form {
    .as__label {
        color: @color__secondary--dark;
        font-size: 1.2rem;
        line-height: 1.6rem;
    }

    .as__input {
        margin-bottom: 0;
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
    }
}

.dws__m7__newsletter__form__action {
    display: flex;

    .as__btn {
        margin-bottom: 0;
        width: 100%;

        @media (min-width: @breakpoint--md) {
            width: auto;
        }
    }
}

.dws__m7__newsletter__info {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 1;
}

.dws__m7__newsletter__info::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(to bottom, rgba(245,245,245,0.91), rgba(228,226,222,0.91));
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(245,245,245,0.91)), to(rgba(228,226,222,0.91)));

    pointer-events: none;

    z-index: 2;
}

.dws__m7__newsletter__info__content {
    position: relative;

    padding: 7rem @m7newsletter__info__padding-left--xs 7rem;
    max-width: @m7newsletter__info__max-width;

    z-index: 3;

    @media (min-width: @breakpoint--lg) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 10rem;
    }

    .dws__m7__newsletter__info__item {
        font-weight: @font-weight__600;
        margin-bottom: 5.5rem;
        padding-left: 5.7rem;
        position: relative;

        @media (min-width: @breakpoint--lg) {
            font-weight: @font-weight__500;
            max-width: 44.5rem;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .as__icon {
            color: @color__brand-primary-red;
            width: 3.3rem;
            height: 3.1rem;
            position: absolute;
            left: 0;
            top: 0;
        }
    }


}
