// z-index 4 to fix M2a overlapping
.dws__m1-hero {
    position: relative;
    z-index: 4;
}

.dws__m1-hero__media {
    //max-width: 1920px;
    margin: 0 auto;
    position: relative;
    z-index: 0;

    &::before {
        @media @media__lg--min {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: block;
            z-index: 1;
            background-image: url("/static/img/m1-wave.png");
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: 100%;
        }
    }
}

.dws__m1-hero__image-wrapper {
    height: 18.6rem;
    display: flex;

    @media @media__sm--min {
        height: 28.5rem;
    }

    @media @media__lg--min {
        height: auto;
        min-height: 59rem;
        max-height: 78.1rem;
    }
}

.dws__m1-hero__image {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.dws__m1-hero__content {
    position: absolute;
    z-index: 1;
    bottom: -4rem;
    width: 100%;

    @media @media__sm--min {
        //top: auto;
        //bottom: -4rem;
    }

    @media @media__lg--min {
        top: auto;
        bottom: -5rem;
    }
}

.dws__m1-hero__headline {
    color: @color__neutral;
    font-size: @m1__hero__headline__font-size--xs;
    font-family: @font-family__secondary;
    font-weight: @font-weight__900;
    line-height: 1;
    text-transform: uppercase;
    text-shadow: 3px 3px 6px rgba(0,0,0,0.5);

    @media @media__lg--min {
        font-size: @m1__hero__headline__font-size--lg;
    }
}


.dws__m1-hero__search__tabs {
    display: flex;
    overflow-x: auto;
    margin-left: -@outer-margin--xs;
    margin-right: -@outer-margin--xs;
    padding: 0 @outer-margin--xs;
    align-items: flex-end;

    @media @media__lg--min {
        overflow-x: hidden;
        margin-left: 0;
        margin-right: 0;
    }

    .dws__tab {
        margin-left: 1rem;
        flex: 0 1 25%;
        white-space: nowrap;
        //width: 25%;
        //pointer-events: none;

        @media @media__lg--min {
            margin-left: 0;
            display: inline-block;
            white-space: normal;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            //margin-right: 2.6rem;
            @media @media__lg--min {
                margin-right: 0;
            }
        }
    }
}

.dws__m1-hero__search__tabs::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dws__m1-hero__search__tabs {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    @media @media__lg--min {
        padding: @m1__hero__tabs__padding--lg;
    }
}

.dws__m1-hero__search__form-wrapper {
    color: @color__neutral;
    padding: @m1__hero__search__form__padding--xs;
    border: @m1__hero__search__form__border-width--xs solid @color__brand-primary-red--dark;
    border-radius: 8px;
    background-color: @color__brand-primary-red;
    transition: background-color @transition__duration @transition__timing-function,
                border @transition__duration @transition__timing-function;

    [data-search-theme="blue"] &,
    .color-brand-primary-blue & {
        background-color: @color__brand-primary-blue;
        border-color: @color__brand-primary-blue--dark;
    }

    [data-search-theme="yellow"] &,
    .color-brand-primary-yellow & {
        background-color: @color__brand-primary-yellow;
        border-color: @color__brand-primary-yellow--dark;
    }

    [data-search-theme="gray"] &,
    .color-brand-primary-gray & {
        background-color: @color__secondary--light;
        border-color: @color__secondary--regular;
    }

    @media @media__lg--min {
        padding: @m1__hero__search__form__padding--lg;
        border-width: @m1__hero__search__form__border-width--lg;
    }
}

.dws__m1-hero__search__form {
    position: relative;

    .as__input {
        box-shadow: inset 3px 3px 6px rgba(0,0,0,0.16);
    }

    .as__btn span {
        pointer-events: none;
    }

    .as__btn {
        box-shadow: unset;
    }

    @media (max-width: @breakpoint--lg - 1px) {

        .as__input {
            padding-right: 4.5rem;
        }

        .as__btn {
            position: absolute;
            top: -4px;
            right: 0;
            padding: 1.3rem 1rem;
            background-color: transparent;
            border: 0;
        }

        .as__icon {
            color: @color__brand-primary-red;
            width: 2.4rem;
            height: 2.4rem;

            [data-search-theme="blue"] & {
                color: @color__brand-primary-blue;
            }

            [data-search-theme="yellow"] & {
                color: @color__brand-primary-yellow;
            }

            [data-search-theme="gray"] &,
            .color-brand-primary-gray & {
                color: @color__secondary--light;
            }
        }
    }

    @media @media__lg--min {
        display: flex;

        .as__input {
            height: 5rem;
        }

        .as__btn {
            color: @color__secondary--dark;
            background-color: @color__neutral;
            border-color: @color__neutral;
            flex-shrink: 0;
            margin-bottom: 0;
            box-shadow: 3px 3px 6px rgba(0,0,0,0.16);
            display: flex;
            align-items: center;
        }
    }
}

// M1 autosuggest
.dws__m1-hero__autosuggest-wrapper {
    position: relative;
}

.dws__m1-hero__search {
    .dws__search-header__result {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: none;
        height: auto;

        z-index: 1;
    }

    .dws__suc6__autosuggest__action {
        padding: 1rem 0;
        margin: 0 1rem;
    }

    .dws__suc6__autosuggest {
        position: static;
        display: none;

        max-height: none;
        height: auto;
        width: auto;

        background-color: @color__neutral;
        box-shadow: 3px 3px 3px rgba(0,0,0,0.16),
            -3px -3px 3px rgba(225,225,225,0.16);
    }

    .dws__suc6__autosuggest--active {
        display: block;
    }

    .dws__suc6__autosuggest__results {
        max-height: 20rem;
        height: auto;

        margin: 0;
        padding-left: 0;
        padding-right: 0;

        color: @text__color;
    }

    .dws__suc6__autosuggest__item,
    .dws__suc6__autosuggest__item-image {
        .color-brand-primary-blue &,
        [data-search-theme="blue"]& {
            background-image: ~'url(@{icon__path}/suc6-autosuggest-item-blue.svg)';
        }

        .color-brand-primary-yellow &,
        [data-search-theme="yellow"]& {
            background-image: ~'url(@{icon__path}/suc6-autosuggest-item-yellow.svg)';
        }

        &:hover,
        &:focus {
            .color-brand-primary-blue &,
            [data-search-theme="blue"]& {
                background-color: @color__brand-primary-blue;
            }

            .color-brand-primary-yellow &,
            [data-search-theme="yellow"]& {
                background-color: @color__brand-primary-yellow;
            }

            &,
            .color-brand-primary-blue &,
            [data-search-theme="blue"]&,
            .color-brand-primary-yellow &,
            [data-search-theme="yellow"]& {
                background-image: ~'url(@{icon__path}/suc6-autosuggest-item-active.svg)';
            }
        }
    }
}
