.as__header {
    position: relative;
    padding: @header__padding;

    background-color: @header__background;
}

.as__header--fixed {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;

    z-index: @header--fixed__z-index;
}

.as__header__logo {
    color: @color__brand-primary-red;
}

.as__header__logo,
.as__header__logo-image {
    display: block;
}

.as__header__logo-image {
    width: @header__logo-image__width;
    height: @header__logo-image__height;
    transition: width 0.3s ease, height 0.3s ease;

    .dws__header--minified & {
        @media (min-width: @breakpoint--lg) {
            width: @header--minified__logo-image__width;
            height: @header--minified__logo-image__height;
        }
    }
}

.as__header__icon-link {
    display: block;
    margin-right: @header__icon-link__margin-right;
    color: @header__color;
}

.as__header-spacer {
    height: @header-spacer__height;
    margin-bottom: 6rem;
}

.dws__header--no-margin .as__header-spacer {
    margin-bottom: 0;
}

html {
    scroll-padding-top: @header-spacer__height;
}

@media @media__md--min {
    .as__header__logo-image {
        width: @header__logo-image__width--md;
        height: @header__logo-image__height--md;
    }

    .as__header-spacer {
        height: @header-spacer__height--md;
    }

    .as__header__icon-link {
        margin-right: 0;
        margin-left: @half-gutter-width * 2;
    }

    html {
        scroll-padding-top: @header-spacer__height--md;
    }
}

@media @media__lg--min {
    .as__header__logo-image {
        width: @header__logo-image__width--lg;
        height: @header__logo-image__height--lg;
    }

    .as__header-spacer {
        height: @header-spacer__height--lg;
    }

    html {
        scroll-padding-top: @header-spacer__height--lg;
    }
}

.dws__header {
    box-shadow: 0 3px 6px #53535329;

    @media @media__lg--min {
        background: fade(@color__neutral, 95%);
        transition: 300ms ease transform;
    }
}

.dws__header--no-margin .dws__header {
    margin-bottom: 0;
}

.dws__header-top {
    position: relative;
    min-height: @header__top__height;

    background: @color__brand-primary-red--dark;

    @media @media__lg--min {
        opacity: 1;
        visibility: visible;
        transition: 300ms ease opacity, 300ms ease visibility;
    }
}

.dws__header-top__background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    width: 100%;

    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;

    box-shadow: 3px 0 6px #5315214d;

    background: @color__brand-primary-red;
    pointer-events: none;
}

.dws__header-top__content {
    position: relative;
    color: @color__neutral;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;

    z-index: 2;
}

.dws__header-top__link {
    display: block;
    line-height: 2rem;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.dws__header-top__link-icon {
    width: 1.8rem;
    height: 1.8rem;
}

.dws__header__border {
    display: none;

    .dws__header--minified & {
        display: block;
        border-top: 1px solid @color__brand-primary-red;
    }
}

.dws__header__shop {
    .dws__header--minified & {
        @media (min-width: @breakpoint--lg) {
            &.as__py-lg-4 {
                padding-top: 1.2rem !important;
                padding-bottom: 1.2rem !important;
            }
        }
    }
}

.dws__header-bottom {
    border-bottom: @header__bottom__height solid @color__brand-primary-red--dark;

    .color-brand-primary-yellow & {
        border-color: @color__brand-primary-yellow;
    }

    .color-brand-primary-blue & {
        border-color: @color__brand-primary-blue;
    }

    .color-brand-primary-red & {
        border-color: @color__brand-primary-red--dark;
    }
}

.dws__header--minified {
    @media @media__lg--min {
        transform: ~'translateY(-@{header__top__height--lg})';
    }
}

.dws__header--minified .dws__header-top {
    @media @media__lg--min {
        opacity: 0;
        visibility: hidden;
    }
}
