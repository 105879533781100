.dws__m73-info-tiles {
    padding-top: 17rem;
}

.dws__m73-info-tiles--has-wave:not(.dws__m73-info-tiles--has-bg-img) {
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;
    background-image: ~'url(@{image__path}/m73-info-tile-wave--blue.svg)';

    @media (min-width: 1920px) {
        background-size: 100%;
    }
}

.dws__m73-info-tiles__headline {
    @media @media__lg--min {
        font-size: 5rem;
        line-height: 1.2em;
    }
}

.dws__m73-info-tiles__tiles {
    margin-bottom: -3rem;
}

.dws__m73-info-tiles__tile {
    margin-bottom: 3rem;
}

.dws__m73-info-tiles__tile-inner {
    border-radius: 5px;
    box-shadow: 3px 3px 6px #00000029;
}

.dws__m73-info-tiles__tags {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: -1rem;
}

.dws__m73-info-tiles__tag {
    margin-bottom: 1rem;
}

.dws__m73-info-tiles__tile-rte {
    ul li {
        color: @text__color;
        padding-left: 2.9rem;

        &::before {
            width: 1.9rem;
            height: 1.3rem;

            background-image: ~'url(@{icon__path}/m73-info-tiles-list-item-arrow.svg)';
        }
    }

    & > *:last-child {
        margin-bottom: 0;
    }
}

.dws__m73-info-tiles .as__btn__container .as__btn__content {
    flex: 1;
}

.dws__m73-info-tiles__tile--premium {
    color: #A67F25;
}

.dws__m73-info-tiles__tile--premium.dws__m73-info-tiles__tile-inner {
    position: relative;
    border: 2px solid currentColor;
}

.dws__m73-info-tiles__premium-icon-wrapper {
    @media @media__lg--min {
        position: absolute;
        top: -.2rem;
        right: 2rem;

        width: 4.5rem;

        color: @color__neutral;

        padding-top: 6rem;
        padding-bottom: 1rem;

        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;

        background: rgb(186,151,59);
        background: linear-gradient(180deg, rgba(186,151,59,1) 0%, rgba(165,125,36,1) 5%, rgba(217,191,99,1) 50%, rgba(194,162,71,1) 70%, rgba(165,125,36,1) 100%);
        box-shadow: 0 3px 6px #00000029;

        z-index: 1;
    }
}

.dws__m73-info-tiles__premium-icon {
    display: block;
    margin-top: -.4rem;

    @media @media__lg--min {
        margin-top: 0;
    }
}

.dws__m73-info-tiles__tile--premium {
    .dws__m73-info-tiles__tile-rte {
        p + ul {
            margin-top: -2rem;
        }

        ul li {
            &::before {
                background-image: ~'url(@{icon__path}/m73-info-tiles-list-item-arrow--gold.svg)';
            }
        }
    }
}

.dws__m73-info-tiles__tile-premium-rte {
    p {
        color: inherit;
    }
}

.dws__m73-info-tiles--has-bg-img {
    position: relative;
    overflow: hidden;
}

.dws__m73-info-tiles--has-bg-img.as__module {
    margin-bottom: 0;
    padding-bottom: @module__margin-bottom--xs;

    @media @media__sm--min {
        padding-bottom: @module__margin-bottom--sm;
    }

    @media @media__md--min {
        padding-bottom: @module__margin-bottom--md;
    }

    @media @media__lg--min {
        padding-bottom: @module__margin-bottom--lg;
    }

    @media @media__xl--min {
        padding-bottom: @module__margin-bottom--xl;
    }
}

.dws__m73-info-tiles--has-bg-img .dws__m73-info-tiles__container {
    position: relative;
    z-index: 2;
}

.dws__m73-info-tiles__bg-img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-start;

    z-index: 1;

    &.pimcore_editable_image {
        position: absolute !important;
    }
}

.dws__m73-info-tiles__bg-img-wrapper::before {
    content: '';
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: @color__gray--lighter;

    z-index: 1;
    pointer-events: none;
}

.dws__m73-info-tiles__bg-img {
    position: relative;

    display: block;
    max-width: 100%;
    height: auto;

    z-index: 2;
}

.dws__m73-info-tiles__tile > .pimcore_block_entry {
    position: relative;
    height: 100%;
}

.dws__m73-info-tiles__tile > .pimcore_block_entry > .pimcore_block_buttons {
    position: absolute;
    left: 0;
    bottom: 100%;

    z-index: 1;
}
