.dws__header__shop-nav__wrapper {
    list-style: none;
}

.dws__header__shop-nav-menu-item {
    position: relative;
}

.dws__header__shop-nav-menu-item-label {
    cursor: pointer;
}

.dws__header__shop-nav-menu--submenu {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
    position: absolute;
    top: 5rem;
    left: -5.6rem;
    z-index: 3;

    @media @media__lg--min {
        input[type="checkbox"].as__header__nav-item-toggle:checked ~ & {
            visibility: visible;
            opacity: 1;
        }
    }

    @media screen and (max-width:1505px) {
        .dws__header__shop-nav-menu-item:last-child & {
            left: auto;
            right: 0;
        }
    }
}

.dws__header__shop-nav__link {
    font-size: 1.3rem;
    line-height: 1.8rem;

    &:hover,
    &:focus {
        color: @color__brand-primary-red;
    }
}

.dws__header__shop-nav__link .as__icon {
    display: block;

    width: 2.1rem;
    height: 2.1rem;

    @media @media__lg--min {
        width: 2.3rem;
        height: 2.3rem;
    }
}